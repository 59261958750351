import { REFRESH_COMPETITOR,REFRESH_COMPETITOR_FAILED,REFRESH_COMPETITOR_SUCCESSFULL,RESET_REFRESH_COMPETITOR } from "../constants/refreshCompetitor.constants";
  
  const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
  };
  
  const refreshCompetitorReducer = (state = initialState, action) => {
    switch (action.type) {
      case REFRESH_COMPETITOR:
        return {
          ...state,
          loading: true,
          success: false,
          data: null,
          error: null,
        };
  
      case REFRESH_COMPETITOR_SUCCESSFULL:
        return {
          ...state,
          loading: false,
          success: true,
          data: action.payload,
          error: null,
        };
  
      case REFRESH_COMPETITOR_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          data: null,
          error: action.payload,
        };
  
      case RESET_REFRESH_COMPETITOR:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default refreshCompetitorReducer;
  