import { FETCH_COUNTRIES, FETCH_COUNTRIES_FAILED, FETCH_COUNTRIES_SUCCESSFULL } from "../constants/countries.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const countriesReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case FETCH_COUNTRIES:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_COUNTRIES_SUCCESSFULL:
            const updatedData = updateCountryNames(action.payload);
            return {
                ...state,
                loading: false,
                data: updatedData,
                error: null
            };

        case FETCH_COUNTRIES_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        default:
            return state;
    }
};

const updateCountryNames = (data) => {
    const nameOccurrences = {};
    data.forEach(country => {
    if (nameOccurrences[country.name]) {
      country.name = `${country.name} (${country.iso3})`;
    }
    nameOccurrences[country.name] = (nameOccurrences[country.name] || 0) + 1;
    });
  return data;
  };

export default countriesReducer;