import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartJS from 'chart.js/auto';
import datalabels from 'chartjs-plugin-datalabels';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

ChartJS.register(datalabels);

const PricePositionChart = () => {

  const productStats = useSelector(state => state.dashboard.data?.productStats);
  const filteredData = [productStats.less, productStats.more, productStats.equal].filter(value => value !== 0);

  const data = {
    labels: ['Cheaper', 'Higher', 'Equal'],
    datasets: [
      {
        data: filteredData,
        backgroundColor: ['#3fcd7f', '#ff6767', '#dac934'],
        hoverBackgroundColor: ['#3fcd7f', '#ff6767', '#dac934'],
        borderWidth: 0
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    border: 0,
    borderWidth: 0,
    line: 0,
    stroke: 0,
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          display: false
        }
      }
    },

    plugins: {
      legend: {
        display: false,
      },
      aspectRatio: 4 / 3,
      datalabels: {
        color: 'white',
      },
    }
  }

  return (
    <>
      <div>
        <Typography sx={{ fontSize: '13px', marginBottom: '12px' }}>Price Position (All Market Place)</Typography>
      </div>
      {filteredData.length ? <div style={{ width: '80%', height: '70%', display: 'flex', justifyContent: 'center' }}>
        <Pie data={data} options={options} />
      </div>:
      <div style={{ height: '20%', marginTop: '20%', marginBottom: '20%'}}>
        <Typography sx={{ fontSize: '12px' }}>There is no data</Typography>
      </div>}
    </>
  );
}

export default PricePositionChart;
