import { DELETE_ALL_PRODUCTS, DELETE_ALL_PRODUCTS_FAILED, DELETE_ALL_PRODUCTS_SUCCESSFULL, RESET_DELETE_ALL_PRODUCTS } from "../constants/deleteAllProducts.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const deleteAllProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_ALL_PRODUCTS:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case DELETE_ALL_PRODUCTS_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case DELETE_ALL_PRODUCTS_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_DELETE_ALL_PRODUCTS:
            return {
                ...initialState
            }

        default:
            return state;
    }
};

export default deleteAllProductsReducer;