import React, { useEffect, useState } from 'react';
import { Avatar, Box, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import MoneyIcon from '@mui/icons-material/Money';
import EditIcon from '@mui/icons-material/Edit';
import Navbar from '../../components/Navbar'
import './clients.css'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router';
import { fetchClientsData } from '../../redux/actions/clients.actions';
import Loading from '../../components/Loading';
import moment from 'moment';
import { fetchClientsMarkets } from '../../redux/actions/clientsMarkets.actions';

const Clients = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const clients = useSelector(state => state.clients);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [radioValue, setRadioValue] = useState('active');
  const [searchInput, setSearchInput] = useState('');

  const filteredClientsData =
  clients.data?.length > 0 &&
  clients.data?.filter(obj => {
    if (radioValue === 'active') {
      return (
        obj.account_type !== 'expired' &&
        obj.account_type !== 'cancelled'
      );
    } else if (radioValue === 'inactive') {
      return (
        obj.account_type === 'expired' ||
        obj.account_type === 'cancelled'
      );
    }
    return true;
  })
  .filter((obj) => {
    const lowercaseSearchInput = searchInput.toLowerCase();
    const lowercaseCompanyName = obj.company_name?.toLowerCase();
    return(lowercaseCompanyName?.includes(lowercaseSearchInput))
  });

  const handleCreateNewClient = () => {
    navigate(`/clients/new`)
  }

  useEffect(() => {
    if(token){
      dispatch(fetchClientsData(token));
      //dispatch(fetchClientsMarkets(token));
    }
  }, [token]) //eslint-disable-line

  return (
    <>
      <Navbar selected='clients' />
      <Grid container className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        {clients.data ? <Grid sx={{ width: '100%' }}>
          <Paper sx={{ p: '14px', maxHeight: '75px', borderRadius: '0', background: themeMode === 'dark' ? '#232D3B' : '#fff', color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none', marginTop: '15px', marginBottom: '12px' }}>
            <Grid xs={12} item container>
              <Grid xs={6} item>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', '& > div': { display: 'flex', alignItems: 'center' }}}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', padding: '10px' }} >
                    <TextField
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      sx={{
                        width: '100%',
                        borderRadius: 3,
                        height: '38px',
                        outline: themeMode === 'dark' ? '2px solid #E4E3E3' : '2px solid #5A5A5A',
                        color: themeMode === 'dark' ? '#e4e3e3' : '5A5A5A',
                        '& input': {
                          color: themeMode === 'dark' ? '#e4e3e3' : '5A5A5A',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: themeMode === 'dark' ? '2px solid white' : '2px solid black',
                          },
                        },
                      }}
                      id='outlined-size-normal'
                      placeholder='Search'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon style={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
                          </InputAdornment>
                        ),
                        sx: { borderRadius: 3, height: '38px' },
                      }}
                    />
                    <Tooltip title='Add New Client' placement='top'>
                      <PersonAddAlt1Icon onClick={handleCreateNewClient} fontSize='medium' sx={{ marginLeft: '1em', color: '#559eff', cursor: 'pointer' }} />
                    </Tooltip>
                  </div>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', padding: '10px' }} >
                    <FormControl>
                      <RadioGroup
                        value={radioValue}
                        onChange={(e) => setRadioValue(e.target.value)}
                        sx={{ justifyContent: 'center', alignItems: 'center' }}
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                      >
                        <FormControlLabel
                          value='active'
                          control={<Radio size='small' color='success' sx={{ color: themeMode === 'dark' ? 'white' : 'black', '&.Mui-checked': { color: 'success' } }} />}
                          label={<span style={{ fontSize: '12px' }}>Active Clients</span>}
                        />
                        <FormControlLabel
                          value='inactive'
                          control={<Radio size='small' color='error' sx={{ color: themeMode === 'dark' ? 'white' : 'black', '&.Mui-checked': { color: 'error' } }} />}
                          label={<span style={{ fontSize: '12px' }}>Inactive Clients</span>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          {filteredClientsData.length === 0 ? <Typography sx={{ fontWeight: 'bold', mx: '38%', mt: '25vh' }}>No clients are there with selected filter</Typography>:
          filteredClientsData.map(client => {
            const createdDates = client.users?.flatMap(user => user?.user?.roles?.filter(role => role?.role?.role_id === 3)?.flatMap(role => role?.role?.role_permission?.map(permission => permission?.permission_details?.created_date)));
            return (
              <Paper key={client.company_id} style={{ width: '100%', display: 'flex', maxHeight: '150px', flexDirection: 'column', marginTop: '8px', marginBottom: '15px', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', boxShadow: 'none' }}>
                <Grid item container xs={12} style={{ height: '100%', padding: '12px' }}>
                  <Grid item container xs={3} >
                    <Grid item xs={5} alignItems='center' display='flex' justifyContent='center'>
                      <div style={{ borderRadius: '50%', padding: '5px', display: 'inline-block' }} >
                        {client.company_logo ? <img src={client.company_logo} width='90' height='90' alt='logo' style={{objectFit:"contain"}} />
                        :<Avatar sx={{ width: 90, height: 90 }} alt='Remy Sharp' />}
                      </div>
                    </Grid>
                    <Grid item xs={7} >
                      <div>
                        <Typography onClick={() => {
                          navigate(`/clients/${client.company_id}`)
                        }} sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginTop: '10px' }} >{client.company_name}</Typography>
                        <Typography sx={{ fontSize: '12px' }} >Account Type : <span style={{ textTransform: 'capitalize', color: '#5398d0', fontWeight: 'bold' }} >{client.account_type}</span></Typography>
                        <Typography sx={{ fontSize: '12px' }} >Reference No : <span style={{ textTransform: 'capitalize', color: '#5398d0', fontWeight: 'bold' }} >{client.company_id}</span></Typography>
                      </div>
                      <div style={{ display: 'flex', marginTop: '3px' }} >
                        <LocationOnIcon style={{ fontSize: '18px', }} />
                        <Typography sx={{ overflowWrap: 'break-word', width: '150px', fontSize: '11px' }} variant='body1'>{client.company_address}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} container justifyContent='center' alignItems='center'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{ backgroundColor: 'grey', height: '80%', marginTop: '15px' }}
                    />
                  </Grid>
                  <Grid item xs={2} container alignItems='center' justifyContent='center'>
                    <Grid item xs={12}>
                      <div>
                        <ul style={{ display: 'flex', flexDirection: 'column', listStyle: 'none', padding: 0 }}>
                          <li style={{ display: 'flex', alignItems: 'normal', margin: '2px' }}>
                            <EmailIcon fontSize='medium' style={{ color: '#5398d0', fontSize: '19px' }} />
                            <Typography sx={{ fontSize: '13px' }} ml={1}>
                              {client.users?.filter(item => item?.user?.roles?.[0]?.role?.role_id === 3).map(item => item?.user?.email).join(', ')}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <PhoneIphoneIcon fontSize='medium' style={{ color: '#5398d0', fontSize: '19px' }} />
                            <Typography sx={{ fontSize: '13px' }} ml={1}>
                              {client.users?.filter(item => item?.user?.roles?.[0]?.role?.role_id === 3).map((item) => item?.user?.phone_number).join(', ')}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <CalendarMonthOutlinedIcon fontSize='medium' style={{ color: '#5398d0', fontSize: '19px' }} />
                            <Typography sx={{ fontSize: '13px' }} ml={1}>
                              {createdDates && createdDates?.length > 0 && createdDates?.map((date, index) => (
                                <span key={index}>
                                  {moment(date).format('DD-MM-YYYY')}
                                  {index < createdDates?.length - 1 && ', '}
                                </span>
                              ))}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <MoneyIcon fontSize='medium' style={{ color: '#5398d0', fontSize: '19px' }} />
                            <Typography sx={{ fontSize: '13px' }} ml={1}>
                              {client.users?.filter(item => item?.user?.roles?.[0]?.role?.role_id === 3).map((item) => item?.user?.currency?.[0]?.currency).join(', ')}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <LanguageIcon fontSize='medium' style={{ color: '#5398d0', fontSize: '19px' }} />
                            <Typography sx={{ fontSize: '13px' }} ml={1}>
                              {client.company_website}
                            </Typography>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} container justifyContent='center' alignItems='center'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{ backgroundColor: 'grey', height: '80%', marginTop: '15px' }}
                    />
                  </Grid>
                  <Grid item xs={2} container alignItems='center' justifyContent='center'>
                    <Grid item xs={12}>
                      <div style={{ marginTop: '-18px' }} >
                        <ul style={{ display: 'flex', flexDirection: 'column', listStyle: 'none', padding: 0 }}>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>Subscription Status: </Typography>
                            <Typography sx={{ marginLeft: '3px', fontWeight: 'bold', color: '#5398d0', textTransform: 'capitalize', fontSize: '13px' }}>
                            {client.company_license[0]?.subscription_status ? client?.company_license[0]?.subscription_status.toString() : '-'}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'flex-start', }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px', marginLeft: '-22px' }} >Services: </Typography>
                            <Typography sx={{ marginLeft: '3px', fontWeight: 'bold', color: '#5398d0', textTransform: 'capitalize', fontSize: '13px' }}>
                            {client.company_license[0]?.services ? client?.company_license[0]?.services : '-'}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>Subscription Start Date: </Typography>
                            <Typography style={{ wordBreak: 'break-word', color: '#5398d0', fontWeight: 'bold', fontSize: '13px' }}>
                            {client.company_license[0]?.subscription_start_date ? moment(client.company_license[0].subscription_start_date).format('DD-MM-YYYY'): '-'}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>Subscription End Date: </Typography>
                            <Typography style={{ wordBreak: 'break-word', color: '#5398d0', fontWeight: 'bold', fontSize: '13px' }}>
                            {client.company_license[0]?.subscription_end_date ? moment(client.company_license[0].subscription_end_date).format('DD-MM-YYYY'): '-'}
                            </Typography>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} container justifyContent='center' alignItems='center'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{ backgroundColor: 'grey', height: '80%', marginTop: '15px' }}
                    />
                  </Grid>
                  <Grid container item xs={2} >
                    <Grid item xs={9}>
                      <div>
                        <ul style={{ display: 'flex', flexDirection: 'column', listStyle: 'none', padding: 0 }}>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>No of Users: </Typography>
                            <Typography sx={{ marginLeft: '3px', fontWeight: 'bold', color: '#5398d0', fontSize: '13px' }}>
                            {client.company_license[0]?.no_users_allowed ? client.company_license[0]?.no_users_allowed : '-' }
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>No of Products: </Typography>
                            <Typography style={{ wordBreak: 'break-word', marginLeft: '3px', fontWeight: 'bold', color: '#5398d0', fontSize: '13px' }}>
                            {client.company_license[0]?.no_of_produts_allowed ? client.company_license[0]?.no_of_produts_allowed : '-'}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>No of Market Place: </Typography>
                            <Typography style={{ wordBreak: 'break-word', marginLeft: '3px', fontWeight: 'bold', color: '#5398d0', fontSize: '13px' }}>
                              {client.company_license[0]?.no_of_mp_allowed ? client.company_license[0]?.no_of_mp_allowed : '-'}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>Industry: </Typography>
                            <Typography style={{ wordBreak: 'break-word', marginLeft: '3px', fontWeight: 'bold', color: '#5398d0', fontSize: '13px' }}>
                              {client.industry}
                            </Typography>
                          </li>
                          <li style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '13px' }} ml={-3}>TimeZone: </Typography>
                            <Typography style={{ wordBreak: 'break-word', marginLeft: '3px', fontWeight: 'bold', color: '#5398d0', fontSize: '13px' }}>
                            {client.users?.filter((item) => item?.user?.role?.[0]?.role?.role_id === 3).map((item) => item?.user?.time_zone).join(', ')}
                            </Typography>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid container item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <IconButton onClick={() => navigate(`/clients/${client.company_id}`)} >
                          <EditIcon sx={{ fontSize: '19px' }} color='primary' fontSize='medium' />
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            )
          })
          }</Grid> : <Loading height='80vh' />}
      </Grid>
      <Footer />
    </>
  )
}

export default Clients