import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, RESET_AUTH, RESTORE_AUTH } from '../constants/loginUser.constants';

const initialState = {
  user: null,
  loading: false,
  error: null,
  token: null,
  productsCreated: null,
  currency: null,
  role: null,
  id: null,
  UI_design: null,
  user_id: null,
};

const loginUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        productsCreated: action.payload.productsCreated,
        currency: action.payload.currency,
        role: action.payload.role,
        id: action.payload.id,
        UI_design: action.payload.UI_design,
        user_id: action.payload.user_id,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_AUTH:
      return {
        ...initialState,
      };

    case RESTORE_AUTH:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default loginUserReducer;