import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_RULE, ADD_RULE_FAILED, ADD_RULE_SUCCESSFULL, RESET_ADD_RULE } from "../constants/addRule.constants";

const addRule = () => ({
    type: ADD_RULE
});

const addRuleSuccess = (data) => ({
    type: ADD_RULE_SUCCESSFULL,
    payload: data
});

const addRuleFailure = (error) => ({
    type: ADD_RULE_FAILED,
    payload: error
});

export const resetAddRule = () => ({
    type: RESET_ADD_RULE
})

export const addNewRule = (token, data) => async (dispatch) => {
    try {
      dispatch(addRule());
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.modifyRules}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      if(response.data?.settings_id){
        dispatch(addRuleSuccess(response.data));
      }else{
        dispatch(addRuleFailure(response.data));
      }
    } catch (error) {
      dispatch(addRuleFailure(error));
    }
};