import axios from "axios";
import { FETCH_COUNTRIES, FETCH_COUNTRIES_FAILED, FETCH_COUNTRIES_SUCCESSFULL} from "../constants/countries.constants";

const getCountries = () => ({
    type: FETCH_COUNTRIES
});

const getCountriesSuccess = (data) => ({
    type: FETCH_COUNTRIES_SUCCESSFULL,
    payload: data
});

const getCountriesFailure = (error) => ({
    type: FETCH_COUNTRIES_FAILED,
    payload: error
});

export const fetchCountries = (token) => async (dispatch) => {
    try {
      dispatch(getCountries());
      const response = await axios.get(`${process.env.REACT_APP_COUNTRIES_API}`)
      if(response.data.error){
        dispatch(getCountriesFailure(response.data.msg));
      }else{
        dispatch(getCountriesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getCountriesFailure(error));
    }
};