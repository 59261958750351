export const paths = {
    login: '/login/',
    notifications: '/api/notifications/',
    productLocations: '/api/locations/get_prd_locations/',
    dashboard: '/api/dashboard/',
    addBulkProduct: '/api/myproducts/upload/',
    categories: '/api/category/?type=',
    departments: '/api/departments/?type=',
    locations: '/api/locations/',
    sellers: '/api/seller/',
    competitorsLocations: '/api/locations/get_competitor_locations',
    myProducts: '/api/myproducts/',
    clients: '/api/company/',
    clientsMarket: '/api/users/get_existing_count/',
    users: '/api/users/',
    setDefaultLocation: '/api/locations/set_default_loc/?loc_id=',
    changePassword: '/change_password/',
    groups: '/api/groups/',
    marketPlaces: '/api/market_place/',
    resetPasswordLink: '/password/reset_password_link?user_id=',
    groupRules: '/api/groups/group_settings/',
    modifyRules: '/api/settings/',
    deleteAllProducts: '/api/myproducts/delete_all/',
    brands: '/api/myproducts/brands/',
    keyValue: '/api/myproducts/set_is_key_value/?prd_id=',
    productDetails: '/api/product_list/',
    updateScore: '/api/competitors/update_prd_score/',
    competitors: '/api/competitors/',
    refreshCompetitors: '/api/competitors/refresh',
    enableNotification: '/api/myproducts/enable_notification/?prd_id=',
    settingsGroup: '/api/groups/',
    groupActions: '/api/groups/group_prd_action'
}