import { Box, Button, Checkbox, Chip, Dialog, DialogContent, Divider, Grid, InputBase, MenuItem, Select, Switch, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import PhoneInput from 'react-phone-input-2';
import { fetchMarketplaces } from '../../redux/actions/marketPlaces.actions';
import moment from 'moment';
import { addNewRule, resetAddRule } from '../../redux/actions/addRule.actions';
import { fetchRules } from '../../redux/actions/rules.actions';
import { editExistingRule, resetEditRule } from '../../redux/actions/editRule.actions';
import { fetchGroups } from '../../redux/actions/groups.actions';

const RuleActionsDialog = ({ dialogState, setDialogState }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const addRule = useSelector(state => state.addRule);
    const editRule = useSelector(state => state.editRule);
    const groups = useSelector(state => state.groups);
    const marketPlaces = useSelector(state => state.marketPlaces.data)
    const dispatch = useDispatch();
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const [ submitting, setSubmitting ] = useState(false);
    const [ serverMsg, setServerMsg ] = useState('');

    const binaryStringToWeekdays = (binaryString) => {
        const selectedDays = [];
        for (let i = 0; i < binaryString.length; i++) {
          if (binaryString[i] === '1') {
            selectedDays.push(weekDays[i]);
          }
        }
        return selectedDays;
      };

    const utcToLocalTime = (times) => {
        const utcTimeInArray = times.slice(1, -1).split(', ');
        return utcTimeInArray.map(time => moment.utc(time, 'HH:mm:ss').local().format('HH:mm'))
    }

    const initialValues = {
        group_id: dialogState.addNewRule ? '' : dialogState.data?.group?.group_id,
        rule_name: dialogState.data?.rule_name || '',
        cut_off_score: dialogState.data?.cuttoff_score || '',
        market_place_id: dialogState.data?.market_places ? dialogState.data?.market_places.map(mkt => mkt?.market_place_id) : [],
        rule_time: dialogState.data?.crawl_time ? utcToLocalTime(dialogState.data?.crawl_time) : [''],
        loc_id: dialogState.data?.locations ? dialogState.data?.locations.map(loc=> loc?.loc_id) : [],
        days: dialogState.data?.weekday ? binaryStringToWeekdays(dialogState.data?.weekday) : [],
        enable_notifications: false,
        price_threshold: '',
        emails: [''],
        phone_numbers: [''],
        sms_alerts: false,
    }


    const getTimeDifference = (time1, time2) => {
        const date1 = new Date(`2000-01-01T${time1}:00`);
        const date2 = new Date(`2000-01-01T${time2}:00`);
        return Math.abs(date2 - date1) / (60 * 1000);
    }

    const AddRuleSchema = Yup.object().shape({
        group_id: Yup.string().required('Group name is required'),
        rule_name: Yup.string().required('Rule name is required'),
        cut_off_score: Yup.number().typeError('Cut off score must be a number').required('Cut off score is required')
            .min(1, 'Cutoff Score must be at least 1').max(100, 'Cutoff Score must be at most 100'),
        market_place_id: Yup.array().min(1, 'Market place is required').required('Market place is required')
            .test('common-locations', 'No common location between all selected marketplaces', function(value) {
                const { loc_id, market_place_id } = this.parent;
                if (market_place_id.length > 1) {
                    const commonLocations = loc_id.filter(loc => 
                        market_place_id.every(marketId =>
                            marketPlaces.find(market => market.market_place_id === marketId)
                                .locations.some(location => location.loc_id === loc)
                        )
                    );
                    return commonLocations.length === loc_id.length;
                }
                return true;
            }),
        rule_time: Yup.array().of(
            Yup.string().required('Time is required')
        ).test('time-relationship', 'Times must have gap of atleast 8 hours', function (value) {
            if (!Array.isArray(value) || value.length < 1) {
                return false;
            }
            if (value.length === 2) {
                const timeDifference1 = getTimeDifference(value[0], value[1]);
                if (timeDifference1 < 480 || timeDifference1 > 1440) {
                  return false;
                }
              }
          
              if (value.length === 3) {
                const timeDifference1 = getTimeDifference(value[0], value[1]);
                const timeDifference2 = getTimeDifference(value[1], value[2]);
          
                if (timeDifference1 !== 480 || timeDifference2 !== 480) {
                  return false;
                }
              }
            return true;
        }),
        loc_id: Yup.array()
        .min(1, 'Location is required')
        .required('Location is required')
        .test('valid-locations', 'Selected locations must be common for the chosen marketplaces', function(value) {
            const { market_place_id } = this.parent;
            return value.every(locId =>
                market_place_id.every(marketId =>
                    marketPlaces.find(market => market.market_place_id === marketId)
                        .locations.some(location => location.loc_id === locId)
                )
            );
        }),
        days: Yup.array().min(1, 'Day is required'),
        enable_notifications: Yup.boolean(),
        price_threshold: Yup.number().when('enable_notifications', {
            is: (value) => value === true,
            then: () => Yup.number().typeError('Price threshold must be number').required('Price threshold is required'),
            otherwise: () => Yup.number().typeError('Price threshold must be number')
        }),
        emails: Yup.array().when('enable_notifications', {
            is: (value) => value === true,
            then: (value) => Yup.array()
                .of(Yup.string().email('Invalid email format').required('Email is required'))
                .min(1, 'At least one email is required')
                .max(3, 'Maximum of 3 emails allowed'),
            otherwise: (value) => Yup.array(),
        }),
        sms_alerts: Yup.boolean(),
        phone_numbers: Yup.array()
            .when('sms_alerts', {
                is: (value) => value === true,
                then: (value) => Yup.array()
                    .of(Yup.string().matches(/^\d+$/, 'Invalid phone number').required('Phone number is required').min(11, 'Invalid phone number'))
                    .min(1, 'At least one phone number is required')
                    .max(3, 'Maximum of 3 phone numbers allowed'),
                otherwise: (value) => Yup.array(),
            }),
    });

    const handleRemoveSelected = (allData, selectedId, setFieldValue, type) => {
        const tempState = allData.filter((val) => val !== selectedId)
        setFieldValue(type, tempState);
    }

    const addInput = (setFieldValue, values, type) => {
        values.push('');
        setFieldValue(`values.${type}`, values)
    }

    const removeInput = (setFieldValue, values, type, index) => {
        values.splice(index, 1)
        setFieldValue(`values.${type}`, values)
    }

    const handleChangeWeek = (values, day, setFieldValue) => {
        const check = values.indexOf(day);
        if (check > -1) {
            values.splice(check, 1)
        } else {
            values.push(day)
        }
        setFieldValue('days', values);
    }

    const handleChangeNotifications = (e, setFieldValue) => {
        if (e.target.checked) {
            setFieldValue('enable_notifications', e.target.checked)
        } else {
            setFieldValue('price_threshold', '')
            setFieldValue('emails', ['']);
            setFieldValue('sms_alerts', false)
            setFieldValue('phone_numbers', ['']);
            setFieldValue('enable_notifications', e.target.checked)
        }
    }

    const handleChangeSmsAlert = (e, setFieldValue) => {
        if (e.target.checked) {
            setFieldValue('sms_alerts', e.target.checked)
        } else {
            setFieldValue('phone_numbers', ['']);
            setFieldValue('sms_alerts', e.target.checked)
        }
    }

    const handleClose = () => {
        setDialogState({ ...dialogState, addNewRule: false, editRule: false, data: null })
        dispatch(resetAddRule());
        dispatch(resetEditRule());
    };

    const handleSubmit = (values) => {
        const payload = {
            crawl_time: values.rule_time.map(ruleTime => {
                const localTime = moment(ruleTime, 'HH:mm');
                const utcTime = localTime.utc();
                const utcTimeString = utcTime.format('HH:mm');
                return utcTimeString;
            }),
            cuttoff_score: values.cut_off_score,
            group_id: values.group_id,
            loc_id: values.loc_id,
            market_place_id: values.market_place_id,
            rule_name: values.rule_name,
            weekday: weekDays.map(day => (values.days.includes(day) ? '1' : '0')).join('')
        }
        if(dialogState.addNewRule){
            setSubmitting(true);
            dispatch(addNewRule(token, payload));
        } else if (dialogState.editRule){
            //payload.settings_id = dialogState.data?.settings_id;
            setSubmitting(true);
            dispatch(editExistingRule(token, payload, dialogState.data?.settings_id));
        }
    }

    useEffect(()=> {
        if(addRule.data){
            handleClose();
            setSubmitting(false);
            dispatch(resetAddRule());
            dispatch(fetchRules(token));
        }else if(addRule.error){
            setServerMsg('Failed to create rule');
            setSubmitting(false);
        }
    },[addRule]) //eslint-disable-line

    useEffect(()=> {
        if(editRule.data){
            handleClose();
            setSubmitting(false);
            dispatch(resetEditRule());
            dispatch(fetchRules(token));
        }else if(editRule.error){
            setServerMsg('Failed to edit rule');
            setSubmitting(false);
        }
    },[editRule]) //eslint-disable-line

    useEffect(()=> {
        dispatch(fetchMarketplaces(token));
        if(dialogState.addNewRule){
            dispatch(fetchGroups(token));
        }
    },[]) //eslint-disable-line

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            border: '1px solid #ced4da',
            fontSize: 11,
            width: '100%',
            padding: '9px 12px',
            textTransform: 'capitalize',
            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            transition: theme.transitions?.create([
                'border-color',
                'background-color',
            ]),
            fontFamily: 'Inter',
            '&:focus': {
                borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            },
        }
    }));

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
    const selectStyle = {
        width: '100%', mt: 1, border: '1px solid #ced4da',
        '& .MuiSelect-icon': fontColor
    }
    const chipStyle = { bgcolor: themeMode === 'light' ? '#FDF8E5' : '#1A222D', borderRadius: 1, m: 1 }
    
    const [isFocused, setIsFocused] = useState(false);

// Function to handle input focus
const handleFocus = () => {
  setIsFocused(true);
};

// Function to handle input blur
const handleBlur = () => {
  setIsFocused(false);
};
    
    return (
        <Dialog
            open={dialogState.addNewRule || dialogState.editRule}
            maxWidth='md'
            onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}
        >
            <DialogContent sx={fontColor} className={`custom-scrollbar-${themeMode}`}>
            {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold'}}>Submitting...</Typography>:
            <Formik enableReinitialize initialValues={initialValues}
                    validationSchema={AddRuleSchema} onSubmit={(values) => handleSubmit(values)}>
                    {({ errors, setFieldValue, values }) => (
                        <Form>
                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{dialogState.addNewRule ? 'Add rule' : 'Edit rule'}</Typography>
                                <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                            </Grid>
                            <Grid sx={{ display: 'flex', width: '100%', mt: 2 }}>
                                <Grid sx={{ width: '50%', pr: 2 }}>
                                    <Typography sx={{ fontSize: '12px' }}>Group name</Typography>
                                    {
                                         dialogState?.addNewRule ? 
                                         <Select size='small' variant='outlined' name='group_id' id='group_name'
                                         sx={{...selectStyle,fontSize:'12px'}} fullWidth
                                         MenuProps={{
                                             PaperProps: {
                                                 sx: {
                                                     ...fontColor,
                                                     bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
                                                 }
                                             }
                                         }}
                                         value={values.group_id}
                                         onChange={(e) => setFieldValue('group_id', e.target.value)}
                                         >
                                           {
                                             groups?.data?.map((grp)=>(
                                                 <MenuItem sx={{fontSize:'12px'}} key={grp?.group_id} value={grp?.group_id}>{grp?.group_name}</MenuItem>
                                             ))
                                           }
                                         </Select>
                                         : 
                                         <Field
                                         disabled
                                         component={BootstrapInput}
                                         sx={{ width: '100%', mt: 1 }}
                                         id='grp_id'
                                         value={dialogState?.data?.group?.group_name}
                                         inputProps={{
                                             style: { textTransform: 'none' }
                                         }}
                                     />
                                    }
                                    <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.group_id}</Typography>
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Rule name</Typography>
                                    <Field
                                        component={BootstrapInput}
                                        sx={{ width: '100%', mt: 1 }}
                                        id='rule_name'
                                        value={values.rule_name}
                                        onChange={(e) => setFieldValue('rule_name', e.target.value)}
                                        placeholder='Enter rule name'
                                        inputProps={{
                                            style: { textTransform: 'none' }
                                        }}
                                    />
                                    <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.rule_name}</Typography>
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Cut off score</Typography>
                                    <Field
                                        component={BootstrapInput}
                                        sx={{ width: '100%', mt: 1 }}
                                        id='cut_off_score'
                                        value={values.cut_off_score}
                                        onChange={(e) => setFieldValue('cut_off_score', e.target.value)}
                                        placeholder='Enter cut off score'
                                        inputProps={{
                                            style: { textTransform: 'none' }
                                        }}
                                    />
                                    <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.cut_off_score}</Typography>
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Market place</Typography>
                                    <Select
                                        size='small'
                                        variant='outlined'
                                        name='market_place_id'
                                        id='market_place_id'
                                        sx={selectStyle}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    ...fontColor,
                                                    bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
                                                }
                                            }
                                        }}
                                        fullWidth multiple
                                        value={values.market_place_id}
                                        onChange={(e) => setFieldValue('market_place_id', e.target.value)}
                                        renderValue={() => <Typography sx={{ color: 'gray', fontSize: '12px' }}>Select market place</Typography>} >
                                        {marketPlaces?.map((market) => {
                                            return (
                                                <MenuItem key={market.market_place_id} value={market.market_place_id} >
                                                    <Checkbox id={`market_place_${market.market_place_id}`} size='small'
                                                        checked={values.market_place_id.includes(market.market_place_id)}
                                                        sx={fontColor} />
                                                    <Typography sx={{ fontSize: '11px' }}>{market.market_place_name}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {marketPlaces?.filter((market) => values.market_place_id.includes(market.market_place_id)).map(mkt => (
                                        <Chip key={mkt.market_place_id} label={mkt.market_place_name} sx={{ ...fontColor, ...chipStyle }} deleteIcon={<CloseIcon style={fontColor} />}
                                            onDelete={() => handleRemoveSelected(values.market_place_id, mkt.market_place_id, setFieldValue, 'market_place_id')} />
                                    ))}
                                    <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.market_place_id}</Typography>
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Time</Typography>
                                    {values?.rule_time?.map((time, index) => {
                                        return (
                                            <div key={index}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Field
                                                        component={BootstrapInput}
                                                        sx={{ width: '100%' }}
                                                        id={`rule_time_${index}`}
                                                        type='time'
                                                        value={time}
                                                        onChange={(e) => setFieldValue(`rule_time[${index}]`, e.target.value)}
                                                        placeholder='Enter time' />
                                                    {values.rule_time.length < 2 && <Button onClick={() => addInput(setFieldValue, values.rule_time, 'rule_time')} variant='contained' sx={{ ml: 1, height: '35px' }} >+</Button>}
                                                    {values.rule_time.length > 1 && <Button onClick={() => removeInput(setFieldValue, values.rule_time, 'rule_time', index)} variant='contained' color='error' sx={{ ml: 1, height: '35px' }} >-</Button>}
                                                </Box>
                                                {errors.rule_time && typeof errors.rule_time === 'string' && <Typography sx={{ color: 'red', fontSize: '11px', marginLeft: '5px' }}>{errors.rule_time}</Typography>}
                                                {Array.isArray(errors.rule_time) && errors.rule_time[index] && <Typography sx={{ color: 'red', fontSize: '11px', marginLeft: '5px' }}>{errors.rule_time[index]}</Typography>}
                                            </div>)
                                    })}
                                    {/* <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.rule_time}</Typography> */}
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Location</Typography>
                                    <Select
                                        size='small'
                                        variant='outlined'
                                        name='loc_id'
                                        id='loc_id'
                                        sx={selectStyle}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    ...fontColor,
                                                    bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
                                                }
                                            }
                                        }}
                                        fullWidth multiple
                                        value={values.loc_id}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={(e) => setFieldValue('loc_id', e.target.value)}
                                        renderValue={() => <Typography sx={{ color: 'gray', fontSize: '12px' }}>Select location</Typography>} >
                                        {
                                            marketPlaces && values && [...new Set(marketPlaces
                                                .filter(market => values.market_place_id.includes(market.market_place_id))
                                                .flatMap(market => market.locations.map(location => location.loc_id)))
                                            ].filter(locationId =>
                                                values.market_place_id.length === 1 || // Check if only one marketplace is selected
                                                marketPlaces.every(market =>
                                                    market.locations.some(location => location.loc_id === locationId))
                                            ).map(locationId => {
                                                const location = marketPlaces
                                                    .find(market => market.locations.some(location => location.loc_id === locationId))
                                                    .locations.find(location => location.loc_id === locationId);
                                                return location;
                                            }).filter((value, index, self) => self.findIndex(loc => loc.loc_id === value.loc_id) === index)
                                            .map(location => (
                                                <MenuItem key={location.loc_id} value={location.loc_id}>
                                                    <Checkbox
                                                        id={`location_${location.loc_id}`}
                                                        size='small'
                                                        checked={values.loc_id.includes(location.loc_id)}
                                                        sx={fontColor}
                                                    />
                                                    <Typography sx={{ fontSize: '11px' }}>{location.loc_name}</Typography>
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                    {
                                        marketPlaces &&
                                        [...new Set(marketPlaces.flatMap((market) => market.locations.map((location) => location.loc_id)))]
                                            .map((locationId) => {
                                                const location = marketPlaces.flatMap((market) => market.locations).find((loc) => loc.loc_id === locationId);
                                                const isSelected = values.loc_id.includes(location.loc_id);
                                                const isValid = values?.market_place_id?.every((marketId) =>
                                                    marketPlaces?.find((market) => market?.market_place_id === marketId)
                                                        ?.locations?.some((loc) => loc?.loc_id === location?.loc_id)
                                                );
                                                const chipStyleDynamic = {
                                                    ...fontColor,...chipStyle,
                                                    ...(isValid ? null : { border: '1px solid red' })
                                                };                                                
                                                return (
                                                    isSelected && (
                                                        <Chip
                                                            key={location.loc_id}
                                                            label={location.loc_name}
                                                            sx={chipStyleDynamic}
                                                            deleteIcon={<CloseIcon style={fontColor} />}
                                                            onDelete={() => handleRemoveSelected(values.loc_id, location.loc_id, setFieldValue, 'loc_id')}
                                                        />
                                                    )
                                                );
                                            })
                                    }
                                    {
                                    isFocused && values.market_place_id?.length === 0 ?
                                    <Typography sx={{ color: 'red', fontSize: '11px' }} >Select Market Place first</Typography>
                                    :
                                    <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.loc_id}</Typography>
                                    }
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Repeat on</Typography>
                                    {weekDays.map((day, index) => {
                                        return (
                                            <Chip key={index} label={day.slice(0, 1)} onClick={() => handleChangeWeek(values.days, day, setFieldValue)}
                                                sx={values?.days?.includes(day)
                                                    ? { color: '#fff', bgcolor: '#1976D2', borderRadius: 1, m: 1 }
                                                    : { ...fontColor, ...chipStyle }} />
                                        )
                                    })}
                                    <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.days}</Typography>
                                </Grid>
                                <Divider orientation='vertical' flexItem color={themeMode === 'light' ? '' : 'gray'} />
                                <Grid sx={{ width: '50%', ml: 2 }}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Enable notifications</Typography>
                                        <Switch id='enable_notifications' checked={values.enable_notifications}
                                            onChange={(e) => handleChangeNotifications(e, setFieldValue)} />
                                    </Grid>
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Price threshold</Typography>
                                    <Field
                                        disabled={!values.enable_notifications}
                                        component={BootstrapInput}
                                        sx={{ width: '100%', mt: 1 }}
                                        id='price_threshold'
                                        value={values.price_threshold}
                                        onChange={(e) => setFieldValue('price_threshold', e.target.value)}
                                        placeholder='Select price threshold'
                                        inputProps={{
                                            style: { textTransform: 'none' }
                                        }}
                                    />
                                    <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.price_threshold}</Typography>
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Email</Typography>
                                    {values?.emails?.map((email, index) => {
                                        return (
                                            <div key={index}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Field
                                                        disabled={!values.enable_notifications}
                                                        component={BootstrapInput}
                                                        sx={{ width: '100%' }}
                                                        id={`emails_${index}`}
                                                        value={email}
                                                        onChange={(e) => setFieldValue(`emails[${index}]`, e.target.value)}
                                                        placeholder='Enter email'
                                                        inputProps={{
                                                            style: { textTransform: 'none' }
                                                        }} />
                                                    {values.emails.length < 3 && <Button disabled={!values.enable_notifications} onClick={() => addInput(setFieldValue, values.emails, 'emails')} variant='contained' sx={{ ml: 1, height: '35px' }} >+</Button>}
                                                    {values.emails.length > 1 && <Button disabled={!values.enable_notifications} onClick={() => removeInput(setFieldValue, values.emails, 'emails', index)} variant='contained' color='error' sx={{ ml: 1, height: '35px' }} >-</Button>}
                                                </Box>
                                                <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors?.emails && errors.emails[index]}</Typography>
                                            </div>)
                                    })}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            disabled={!values.enable_notifications}
                                            id='sms_alerts'
                                            value={values.sms_alerts}
                                            onChange={(e) => handleChangeSmsAlert(e, setFieldValue)}
                                            size='small' sx={{ ...fontColor, ml: '-10px' }} />
                                        <Typography sx={{ fontSize: '12px' }} >Get SMS alerts</Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: '12px', mt: 2 }}>Phone number</Typography>
                                    {values?.phone_numbers?.map((phone, index) => {
                                        return (
                                            <div key={index}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <PhoneInput
                                                        disabled={!values.sms_alerts}
                                                        country={'in'}
                                                        style={{ width: '100%' }}
                                                        dropdownClass={`custom-scrollbar-${themeMode}`}
                                                        inputStyle={{ width: '100%', ...fontColor, backgroundColor: 'transparent' }}
                                                        dropdownStyle={{ backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D' }}
                                                        id={`phone_numbers_${index}`}
                                                        value={phone}
                                                        onChange={(value) => {
                                                            const updatedPhoneNumbers = [...values.phone_numbers];
                                                            updatedPhoneNumbers[index] = value;
                                                            setFieldValue('phone_numbers', updatedPhoneNumbers);
                                                        }}
                                                        placeholder='Enter phone number'
                                                    />
                                                    {values.phone_numbers.length < 3 &&
                                                        <Button disabled={!values.sms_alerts}
                                                            onClick={() => addInput(setFieldValue, values.phone_numbers, 'phone_numbers')}
                                                            variant='contained' sx={{ ml: 1, height: '35px' }} >+</Button>}
                                                    {values.phone_numbers.length > 1 &&
                                                        <Button disabled={!values.sms_alerts}
                                                            onClick={() => removeInput(setFieldValue, values.phone_numbers, 'phone_numbers', index)}
                                                            variant='contained' color='error' sx={{ ml: 1, height: '35px' }} >-</Button>}
                                                </Box>
                                                <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors?.phone_numbers && errors.phone_numbers[index]}</Typography>
                                            </div>)
                                    })}
                                </Grid>
                            </Grid>
                            <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                            <Button disabled={Boolean(Object.values(errors).find((value) => value !== '' && value !== null))}
                                type='submit' variant='contained' sx={{ width: '50%', mt: 2, ml: '25%', textTransform: 'none' }}>{dialogState.addNewRule ? 'Add rule' : 'Edit rule'}</Button>
                        </Form>)}
                </Formik>}
            </DialogContent>
        </Dialog >
    )
}

export default RuleActionsDialog