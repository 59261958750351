import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_DEPARTMENTS, FETCH_DEPARTMENTS_FAILED, FETCH_DEPARTMENTS_SUCCESSFULL } from "../constants/departments.constants";

const getDepartments = () => ({
    type: FETCH_DEPARTMENTS
});

const getDepartmentsSuccess = (data) => ({
    type: FETCH_DEPARTMENTS_SUCCESSFULL,
    payload: data
});

const getDepartmentsFailure = (error) => ({
    type: FETCH_DEPARTMENTS_FAILED,
    payload: error
});

export const fetchDepartments = (token, keyword) => async (dispatch) => {
    try {
      dispatch(getDepartments());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.departments}${keyword}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getDepartmentsSuccess(response.data));
    } catch (error) {
      dispatch(getDepartmentsFailure(error));
    }
};