import React, { useState } from 'react'
import { Button, Checkbox, Chip, Dialog, DialogContent, Divider, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { clearFilters, detailsPageApplyFilters } from '../../redux/actions/detailsPageFilters.actions';

const ProductDetailsFilterDialog = ({ dialogState,setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const marketPlaces = useSelector(state => state.marketPlaces.data);
  const filters = useSelector(state => state.detailsPageFilters);
  const dispatch = useDispatch();

  const percentageValues = Array.from({ length: 100 }, (_, i) => i + 1);
  const feedbackRange = ['4 - 5 Star', '3 - 4 Star', '2 - 3 Star', '1 - 2 Star', '0 - 1 Star'];

  const [selectedMatchRate, setSelectedMatchRate] = useState(filters.applied ? filters.matchRate : '');
  const [selectedFeedbackRange, setSelectedFedbackrange] = useState(filters.applied ? filters.feedbackRange : '');
  const [selectedMarketChips, setSelectedMarketChips] = useState(filters.applied ? filters.marketPlaces : []);
  const displayedItems = 10;

  const checkMatchRate = (comp) => {
    if(comp.comp_prd_score === selectedMatchRate || !selectedMatchRate) return true
    else return false
  }

  const checkFeedback = (comp) => {
    if (!selectedFeedbackRange) return true;
    else if (comp.comp_prd_rating >= 4 && selectedFeedbackRange === '4 - 5 Star') return true;
    else if (comp.comp_prd_rating >= 3 && comp.comp_prd_rating <= 4 && selectedFeedbackRange === '3 - 4 Star') return true;
    else if (comp.comp_prd_rating >= 2 && comp.comp_prd_rating <= 3 && selectedFeedbackRange === '2 - 3 Star') return true;
    else if (comp.comp_prd_rating >= 1 && comp.comp_prd_rating <= 2 && selectedFeedbackRange === '1 - 2 Star') return true;
    else if (comp.comp_prd_rating >= 0 && comp.comp_prd_rating <= 1 && selectedFeedbackRange === '0 - 1 Star') return true;
    else return false;
  };

  const marketSortedComps = marketPlaces?.reduce((result, mkt) => {
    const competitorsInMarket = dialogState.openPdtFilter.data?.filter(comp => {
      return (comp.market_places.market_place_id === mkt.market_place_id && checkMatchRate(comp) && checkFeedback(comp))
    });
    result.push({
      market_place_id: mkt.market_place_id,
      market_place_name: mkt.market_place_name,
      competitors: competitorsInMarket,
    });
    return result;
  },[]);

  const [expandedMarketplaces, setExpandedMarketplaces] = useState([]);
  const [ selectedComps, setSelectedComps ] = useState(filters.applied ? filters.competitors : []);

  const handleClosePdtFilterDialog = () => {
    setDialogState(prevState => ({
      ...prevState,
      openPdtFilter: { show: false, data: null }
    }));
  };
  

  const handleChangeMatchrate = (event) => {
    setSelectedMatchRate(event.target.value);
    setSelectedComps([]);
    setExpandedMarketplaces([]);
  }
  const handleChangeFeedbackRange = (event) => {
    setSelectedFedbackrange(event.target.value);
    setSelectedComps([]);
    setExpandedMarketplaces([]);
  }

  const handleMarketChipClick = (mkt_id) => {
    const currentIndex = selectedMarketChips.indexOf(mkt_id);
    const newSelectedChips = [...selectedMarketChips];

    if (currentIndex === -1) {
      newSelectedChips.push(mkt_id);
    } else {
      newSelectedChips.splice(currentIndex, 1);
    }

    setSelectedMarketChips(newSelectedChips);
    setExpandedMarketplaces([]);
  };

  const handleDelete = (mkt_id) => {
    const newSelectedMarketChips = selectedMarketChips.filter(item => item !== mkt_id);
    setSelectedMarketChips(newSelectedMarketChips);
  };

  const handleCheckbox = (comp_id) => {
    let tempState = []
    if(selectedComps.includes(comp_id)){
      tempState = selectedComps.filter(comp => comp !== comp_id)
    } else {
      tempState = [...selectedComps, comp_id]
    }
    setSelectedComps(tempState);
  }

  const handleSeeMoreClick = (index) => {
    setExpandedMarketplaces((prev) => [...prev, index]);
  };

  const handleSeeLessClick = (index) => {
    setExpandedMarketplaces((prev) => prev.filter((expandedIndex) => expandedIndex !== index));
  };

  const clearFilter = () => {
    setSelectedMatchRate('');
    setSelectedFedbackrange('');
    setSelectedMarketChips([]);
    setSelectedComps([]);
    setExpandedMarketplaces([]);
    dispatch(clearFilters());
    sessionStorage.removeItem('detailPageFilters');
  }

  const applyFilter = () => {
    const payload = {
      matchRate: selectedMatchRate,
      feedbackRange: selectedFeedbackRange,
      marketPlaces: selectedMarketChips,
      competitors: selectedComps
    }
    sessionStorage.setItem('detailPageFilters', JSON.stringify(payload));
    dispatch(detailsPageApplyFilters(payload));
    handleClosePdtFilterDialog();
  }

  const buttonStyle = { textTransform: 'none', boxShadow: 'none', borderRadius: '15px', px: '25px', fontWeight: 'bold' };

  return (
    <Dialog
      open={dialogState.openPdtFilter.show} onClose={handleClosePdtFilterDialog}
      PaperProps={{ sx: { width: '90%', maxWidth: '90%', m: 0, background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } }}
    >
      <DialogContent sx={{ m: 0, p: 0 }} className={`custom-scrollbar-${themeMode}`} >
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '18px', fontWeight: 'bold' }} >
          <Typography sx={{ fontWeight: 'bold' }}>Configure Listing</Typography>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '50px' }} >
            <Button color='warning' sx={{ textTransform: 'none' }} onClick={clearFilter} >Clear filter</Button>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClosePdtFilterDialog} />
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
        <Grid item xs={12} sx={{ p: '18px', pb: '30px' }}>
          <Typography sx={{ fontSize: '14px' }} >General Settings</Typography>
          <Grid sx={{ pt: '30px', display: 'inline-block' }} >
            <Typography mb={1} sx={{ fontSize: '12px' }} >Match Rate</Typography>
            <FormControl fullWidth>
              <Select
                sx={{
                  width: '100px', border: themeMode === 'dark' ? '1px solid #868685' : '1px solid #5A5A5A',
                  '& .MuiOutlinedInput-input': { p: '8px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '14px' },
                  '& .MuiSelect-icon': {
                    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                  },
                  borderRadius: '10px',
                }}
                id='matchRate' label='select match rate'
                value={selectedMatchRate}
                onChange={handleChangeMatchrate}
                inputProps={{ name: 'matchRate' }}
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      backgroundColor: themeMode === 'dark' ? '#232d3b' : 'white',
                      color: themeMode === 'dark' ? '#e4e3e3' : 'black',
                      pt: '0px',
                      pb: '0px',
                    },
                  },
                }}
              >
                {percentageValues?.map((value, index) => (
                  <MenuItem sx={{ fontSize: '14px', }} id={`menu-item-${index}`} key={value} value={value}>{value}%</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ pt: '25px', display: 'inline-block', ml: '40px' }}>
            <Typography mb={1} sx={{ fontSize: '12px' }} >Feedback Range</Typography>
            <FormControl fullWidth>
              <Select
                sx={{
                  width: '150px', border: themeMode === 'dark' ? '1px solid #868685' : '1px solid #5A5A5A',
                  '& .MuiOutlinedInput-input': { p: '8px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '14px' },
                  '& .MuiSelect-icon': {
                    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                  },
                  borderRadius: '10px',
                }}
                id='feedback' label='select feedback'
                value={selectedFeedbackRange}
                onChange={handleChangeFeedbackRange}
                inputProps={{ name: 'feedbackrange' }}
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      backgroundColor: themeMode === 'dark' ? '#232d3b' : 'white',
                      color: themeMode === 'dark' ? '#e4e3e3' : 'black',
                      pt: '0px',
                      pb: '0px',
                    },
                  },
                }}
              >
                {feedbackRange?.map((value, index) => (
                  <MenuItem sx={{ fontSize: '14px' }} id={`menu-item-${index}`} key={value} value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
        <Grid item xs={12} sx={{ p: '18px' }} >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid xs={6} item >
              <Typography sx={{ fontSize: '14px' }} >Marketplace's Sourced</Typography>
            </Grid>
            {/* <Grid xs={6} item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pr: '18px' }} >
              <ErrorOutlineOutlinedIcon style={{ transform: 'rotate(180deg)', color: '#07bc0c', fontSize: '20px', mr: '18px' }} /> <span style={{ fontSize: '11px', fontStyle: 'italic' }} >Shows Lowest Price within the marketplace</span>
            </Grid> */}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: '12px', pt: '20px', pb: '20px' }} >
            {marketPlaces?.map(mkt => (
              <Chip
                sx={{
                  color: selectedMarketChips.includes(mkt.market_place_id) ? themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                  backgroundColor: selectedMarketChips.includes(mkt.market_place_id) ? '#f4d35e' : 'transparent',
                  outline: selectedMarketChips.includes(mkt.market_place_id) ? 'none' : `1px solid ${themeMode === 'dark' ? '#E4E3E3' : 'lightGrey'}`,
                  '&:hover': {
                    backgroundColor: selectedMarketChips.includes(mkt.market_place_id) ? '#f4d35e' : 'transparent',
                  },
                }}
                key={mkt.market_place_id} label={mkt.market_place_name} onClick={() => handleMarketChipClick(mkt.market_place_id)}
                onDelete={selectedMarketChips.includes(mkt.market_place_id) ? () => handleDelete(mkt.market_place_id) : null}
                deleteIcon={selectedMarketChips.includes(mkt.market_place_id) ? <CloseIcon style={{ color: 'black', fontSize: '15px' }} /> : null}
              />
            ))}
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
        <Grid item xs={12} container rowSpacing={3} sx={{ p: 3 }}>
          {selectedMarketChips.length > 0 ?
          marketSortedComps?.map((mkt, index) => (selectedMarketChips.includes(mkt.market_place_id) ?
            (<Grid key={mkt.market_place_id} item xs={6} sx={{ pl: 2 }}>
              <Grid sx={{ border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1',
                    width: '95%', borderRadius: '11px 11px 0px 0px' }}>
                <Grid sx={{ background: themeMode === 'dark' ? '#E4E3E3' : '#f8f8f8', borderRadius: '9px 9px 0px 0px',
                      p: '10px', outline: 'none !important', color: themeMode === 'dark' ? '#5A5A5A' : '#757574',
                      borderBottom: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1' }}>
                  <Typography sx={{ textTransform: 'uppercase',fontSize: '14px' }} >{mkt.market_place_name}</Typography>
                </Grid>
                {mkt.competitors.length === 0 ? <Typography sx={{ m: 1,fontSize: '13px' }}>No competitors for this filter options</Typography>:
                mkt.competitors.slice(0, expandedMarketplaces.includes(index) ? mkt.competitors.length : displayedItems).map(comp => {
                  return (
                    <Grid key={comp.comp_prd_id} item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                    <Grid item xs={1}>
                      <Checkbox sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}
                        id={`checkbox_${comp.comp_prd_id}`} size='small' checked={selectedComps.includes(comp.comp_prd_id)}
                        onChange={()=> handleCheckbox(comp.comp_prd_id)}/>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography sx={{ fontSize: '14px', color: themeMode === 'dark' ? 'white' : '#5A5A5A' }}>{comp.comp_prd_name}</Typography>
                      <Typography sx={{ fontSize: '11px', lineHeight: '1.4' }}>{comp.seller?.seller_name}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: '13px', textAlign: 'end' }}>{comp.comp_prd_score}</Typography>
                    </Grid>
                    </Grid>)
                })}
                {mkt.competitors.length > displayedItems && (
                  <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {expandedMarketplaces.includes(index) ? (
                      <Typography onClick={() => handleSeeLessClick(index)} sx={{ p: '8px', fontSize: '14px',textDecoration:'underline',color:'#4a89dd',cursor:'pointer' }}>
                        See Less
                      </Typography>
                    ) : (
                      <Typography onClick={() => handleSeeMoreClick(index)} sx={{ p: '8px', fontSize: '14px',textDecoration:'underline',color:'#4a89dd',cursor:'pointer' }}>
                        See More
                      </Typography>
                    )}
                    <Typography sx={{ p: '8px', fontSize: '14px' }}>
                      Showing {expandedMarketplaces.includes(index) ? mkt.competitors.length : displayedItems} results
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>) : null)):
            <Typography sx={{ m: 'auto', mt: 2 }}>Select any one of the marketplaces to see the competitors</Typography>}
        </Grid>
        <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }} >
            <Button sx={{ ...buttonStyle,
              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
               background: 'none' }} onClick={handleClosePdtFilterDialog} >Cancel</Button>
            <Button disabled={selectedComps.length === 0} variant='contained' onClick={applyFilter}
            sx={{...buttonStyle, color: 'black', background: '#f4d35e',
              '&:hover': { backgroundColor: '#f4d35e', boxShadow: 'none', },
              '&:active': { backgroundColor: '#f4d35e', boxShadow: 'none', },
            }} >Apply Filter</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ProductDetailsFilterDialog