import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_SELLERS, FETCH_SELLERS_FAILED, FETCH_SELLERS_SUCCESSFULL } from "../constants/sellers.constants";

const getSellers = () => ({
    type: FETCH_SELLERS
});

const getSellersSuccess = (data) => ({
    type: FETCH_SELLERS_SUCCESSFULL,
    payload: data
});

const getSellersFailure = (error) => ({
    type: FETCH_SELLERS_FAILED,
    payload: error
});

export const fetchSellers = (token) => async (dispatch) => {
    try {
      dispatch(getSellers());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.sellers}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getSellersSuccess(response.data));
    } catch (error) {
      dispatch(getSellersFailure(error));
    }
};