import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_LOCATION, ADD_LOCATION_FAILED, ADD_LOCATION_SUCCESSFULL, RESET_ADD_LOCATION } from "../constants/addLocation.constants";

const addLocation = (data) => ({
    type: ADD_LOCATION,
    payload: data
});

const addLocationSuccess = (data) => ({
    type: ADD_LOCATION_SUCCESSFULL,
    payload: data
});

const addLocationFailure = (error) => ({
    type: ADD_LOCATION_FAILED,
    payload: error
});

export const resetAddLocation = () => ({
    type: RESET_ADD_LOCATION
})

export const addNewLocation = (data, token) => async (dispatch) => {
    try {
      dispatch(addLocation(data));
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.locations}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(addLocationSuccess(response.data));
    } catch (error) {
      dispatch(addLocationFailure(error));
    }
};