import axios from "axios";
import { paths } from "../../Api/paths";
import { EDIT_PRODUCT, EDIT_PRODUCT_FAILED, EDIT_PRODUCT_SUCCESSFULL, RESET_EDIT_PRODUCT } from "../constants/editProduct.constants";

const handleEditProduct = () => ({
    type: EDIT_PRODUCT
});

const editProductSuccessful = (data) => ({
    type: EDIT_PRODUCT_SUCCESSFULL,
    payload: data
});

const editProductFailed = (error) => ({
    type: EDIT_PRODUCT_FAILED,
    payload: error
});

export const resetEditProduct = () => ({
    type: RESET_EDIT_PRODUCT
});

export const editProductDetails = (prdId, data, token) => async (dispatch) => {
    try{
        dispatch(handleEditProduct());
        const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.myProducts}${prdId}`,
        data, { headers: { Authorization: `bearer ${token}` } })
        if(response.data?.status === 'success'){
            dispatch(editProductSuccessful(response.data));
        }else{
            dispatch(editProductFailed(response.data));
        }
    }catch(error){
        dispatch(editProductFailed(error));
    }
}