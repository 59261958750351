import axios from "axios";
import { paths } from '../../Api/paths';
import { EDIT_GROUP_SETTINGS,EDIT_GROUP_SETTINGS_FAILED,EDIT_GROUP_SETTINGS_SUCCESSFULL,RESET_EDIT_GROUP_SETTINGS } from "../constants/editGroupSettings.constants";

const editGroupSettings = (data) => ({
    type: EDIT_GROUP_SETTINGS,
    payload: data
});

const editGroupSettingsSuccess = (data) => ({
    type: EDIT_GROUP_SETTINGS_SUCCESSFULL,
    payload: data
});

const editGroupSettingsFailure = (error) => ({
    type: EDIT_GROUP_SETTINGS_FAILED,
    payload: error
});

export const reseteditGroupSettings = () => ({
    type: RESET_EDIT_GROUP_SETTINGS
})

export const handleEditGroupSettings = (token,data,group_id) => async (dispatch) => {
    try {
      dispatch(editGroupSettings(data));
      const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.settingsGroup}/${group_id}/`,
      data, { headers: { Authorization: `bearer ${token}`}})
      if(response.data?.Message){
        dispatch(editGroupSettingsFailure(response.data.Message));
      }else{
          dispatch(editGroupSettingsSuccess(response.data));
      }
    } catch (error) {
      dispatch(editGroupSettingsFailure(error));
    }
};