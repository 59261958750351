import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_LOCATIONS, FETCH_LOCATIONS_FAILED, FETCH_LOCATIONS_SUCCESSFULL } from "../constants/locations.constants";

const getLocations = () => ({
    type: FETCH_LOCATIONS
});

const getLocationsSuccess = (data) => ({
    type: FETCH_LOCATIONS_SUCCESSFULL,
    payload: data
});

const getLocationsFailure = (error) => ({
    type: FETCH_LOCATIONS_FAILED,
    payload: error
});

export const fetchLocations = (token) => async (dispatch) => {
    try {
      dispatch(getLocations());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.locations}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getLocationsSuccess(response.data));
    } catch (error) {
      dispatch(getLocationsFailure(error));
    }
};