import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_USER, ADD_USER_FAILED, ADD_USER_SUCCESSFULL, RESET_ADD_USER } from "../constants/addUser.constants";

const addUser = () => ({
    type: ADD_USER
});

const addUserSuccess = (data) => ({
    type: ADD_USER_SUCCESSFULL,
    payload: data
});

const addUserFailure = (error) => ({
    type: ADD_USER_FAILED,
    payload: error
});

export const resetAddUser = () => ({
    type: RESET_ADD_USER
})

export const addNewUser = (token, data) => async (dispatch) => {
    try {
      dispatch(addUser());
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.users}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(addUserSuccess(response.data));
    } catch (error) {
      dispatch(addUserFailure(error));
    }
};