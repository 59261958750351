import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_CLIENT, ADD_CLIENT_FAILED, ADD_CLIENT_SUCCESSFULL, RESET_ADD_CLIENT } from "../constants/addClient.constants";

const addClient = (data) => ({
    type: ADD_CLIENT,
    payload: data
});

const addClientSuccess = (data) => ({
    type: ADD_CLIENT_SUCCESSFULL,
    payload: data
});

const addClientFailure = (error) => ({
    type: ADD_CLIENT_FAILED,
    payload: error
});

export const resetAddClient = () => ({
    type: RESET_ADD_CLIENT
})

export const addNewClient = (data, token) => async (dispatch) => {
    try {
      dispatch(addClient(data));
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.clients}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      if(response.data?.Message){
        dispatch(addClientFailure(response.data.Message));
      }else{
          dispatch(addClientSuccess(response.data));
      }
    } catch (error) {
      dispatch(addClientFailure(error));
    }
};