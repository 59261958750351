import axios from "axios";
import { paths } from '../../Api/paths';
import { RESET_SEND_PASSWORD_LINK, SEND_PASSWORD_LINK, SEND_PASSWORD_LINK_FAILED, SEND_PASSWORD_LINK_SUCCESSFULL } from "../constants/sendPasswordLink.constants";

const sendLink = () => ({
    type: SEND_PASSWORD_LINK
});

const sendLinkSuccess = (data) => ({
    type: SEND_PASSWORD_LINK_SUCCESSFULL,
    payload: data
});

const sendLinkFailure = (error) => ({
    type: SEND_PASSWORD_LINK_FAILED,
    payload: error
});

export const resetSendLink = () => ({
    type: RESET_SEND_PASSWORD_LINK
})

export const sendPasswordResetLink = (token, userId) => async (dispatch) => {
    try {
      dispatch(sendLink());
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.resetPasswordLink}${userId}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(sendLinkSuccess(response.data));
    } catch (error) {
      dispatch(sendLinkFailure(error));
    }
};