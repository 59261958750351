import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ResetPassword = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const SignInSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters').matches(/[0-9]/, 'Password must contain at least one number'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password is required'),
    })
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: '#E4E3E3',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          fontFamily: 'Inter',
        },
    }));

    const handleFormSubmit = (values)=>{
        console.log('values',values.password,values.confirmPassword)
    }

  return (
    <Grid container className='loginBackground'>
      <Grid item xs={12} sx={{ display: 'flex' }}>
        <Grid item xs={12} sm={12} md={7} sx={{ display: 'flex' }}>
        </Grid>
        <Grid item xs={12} sm={12} md={5}
          sx={{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', minHeight: 'calc(100vh)', margin: '0px auto', padding: '0px 80px', display: 'flex', alignItems: 'center' }}>
          <Box>
            <Paper style={{ background: 'none', boxShadow: 'none' }}>
              <Grid item sx={{ display: 'flex', mb: 1.5, justifyContent: 'center' }}>
                <img src='/assets/Logo-removebg-preview.png' style={{ width: '100%', mb: 1, marginLeft: '-1em', height: '6em' }} alt='logo' />
              </Grid>
              <Typography variant='h4' component='h4' sx={{ color: '#E4E3E3', fontSize: '27px' }}> Reset Password</Typography>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={{ password: '', confirmPassword: '' }}
                  validationSchema={SignInSchema}>
                  {({ errors, setFieldValue, values }) => (
                    <Form className='loginform'>
                      <FormControl variant='standard' sx={{ mt: 1, width: '100%' }}>
                        <InputLabel shrink htmlFor='password' sx={{ color: '#E4E3E3', fontSize:'13px' }}>Password</InputLabel>
                        <BootstrapInput 
                        autoComplete='off' 
                        type={showPassword ? 'text' : 'password'}
                        value={values.password} 
                          placeholder='Enter your password' id='password'
                          onChange={e => setFieldValue('password', e.target.value)}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                              sx={{marginLeft:'-50px'}}
                                onClick={() => setShowPassword(prev => !prev)}
                                edge='end'
                              >
                                {showPassword ? <VisibilityOff sx={{color:"white",fontSize:"20px"}} /> : <Visibility sx={{color:"white",fontSize:"20px"}} />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <Typography variant='subtitle' style={{ fontSize:'11px',color:'red'}}>{errors.password}</Typography>
                      </FormControl>
                      <FormControl variant='standard' sx={{ mt: 2, width: '100%' }} >
                        <InputLabel shrink htmlFor='confirmPassword' sx={{ color: '#E4E3E3', fontSize:'13px' }}>Confirm Password</InputLabel>
                        <BootstrapInput
                        autoComplete='off'
                          type={showConfirmPassword ? 'text' : 'password'} value={values.confirmPassword} 
                          placeholder='Please confirm your new password' id='confirmPassword'
                          onChange={e => setFieldValue('confirmPassword', e.target.value)}
                          endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={() => setShowConfirmPassword(prev => !prev)}
                                  edge='end' sx={{marginLeft:'-50px'}}>
                                  {showConfirmPassword ? <VisibilityOff sx={{color:"white",fontSize:"20px"}} /> : <Visibility sx={{color:"white",fontSize:"20px"}} />}
                                </IconButton>
                              </InputAdornment>
                            }
                        />
                        <Typography variant='subtitle' sx={{ fontSize:'11px',color:"red" }}>{errors.confirmPassword}</Typography>
                      </FormControl>
                     
                      <FormControl sx={{ m: 0, mt: 3, mb: 2,width:"100%" }}>
                        <Button sx={{
                          backgroundColor: '#559EFF',borderColor: '#1C3B6E',color: '#000000',marginRight:"9px",
                          '&:hover': {backgroundColor: '#559EFF',borderColor: '#1C3B6E',color: '#000000'}
                            }} type='submit' >
                        Reset
                        </Button>
                      </FormControl>
                    </Form>)}
                </Formik>
              </Grid>
            </Paper>
          </Box>
          <Grid item xs={12} sx={{ display: 'flex', width: '40%', bottom: 20, pt: 1, pb: 1, position: 'fixed', justifyContent: 'center', ml: 0 }}>
            <img src='/assets/orbioLogo.png' alt='orbio logo' style={{ width: 30, marginRight: 8 }} />
            <Typography sx={{ color: '#E4E3E3', fontSize:'13px' }}> Powered By <a href='https://www.orbiosolutions.com/' target='_blank' rel='noreferrer' style={{ color: 'inherit' }}>Orbio Solutions</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResetPassword