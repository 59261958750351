import { Button, Container, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, Popover, Switch, Tooltip, Typography } from '@mui/material'
import Badge from '@mui/material/Badge';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DnsIcon from '@mui/icons-material/Dns';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '../../redux/actions/toggleTheme.actions';
import { resetAuth } from '../../redux/actions/loginUser.actions';
import React, { useEffect, useState } from 'react'
import './navbar.css'
import { Link, useNavigate } from 'react-router-dom'
import { getLatestNotifications } from '../../redux/actions/notifications.actions';
import { fetchDashboardData } from '../../redux/actions/dashboard.actions';

const Navbar = ({ selected }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [unread, setUnread] = useState(false);
    const open = Boolean(anchorEl);
    const id = open ? 'notifications' : undefined;
    const navigate = useNavigate();
    const themeMode = useSelector(state => state.theme.mode);
    const userRole = useSelector(state => state.auth.role);
    const token = useSelector(state => state.auth.token);
    const notifications = useSelector(state => state.notifications);
    const productsCreated = useSelector(state => state.auth.productsCreated);
    const logo = useSelector(state => state.dashboard.data?.summary?.logo);
    const dispatch = useDispatch();
    let fontColor = null;
    if (themeMode === 'light') {
        fontColor = { color: '#5A5A5A' }
    } else {
        fontColor = { color: '#E4E3E3' }
    }

    const handlePopClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(resetAuth());
        navigate('/login')
    }

    useEffect(() => {
        sessionStorage.setItem('userTheme', themeMode)
    }, [themeMode])

    useEffect(() => {
        const checkUnread = notifications?.data?.filter(not => !not.is_notification_read);
        if (checkUnread?.length > 0) setUnread(true);
    }, [notifications])

    useEffect(() => {
        if (token) {
            if(userRole !== 1){
                //dispatch(getLatestNotifications(token));
                dispatch(fetchDashboardData(token));
            }
        }
    }, [token]) //eslint-disable-line

    return (
        <Container component='header' className={`navbar ${themeMode === 'light' ? 'lightTheme' : 'darkTheme'}`}>
            <nav className='nav'>
                <Button component={Link} to={userRole === 1 ? '/clients' : '/dashboard'} sx={{ width: '25%' }} >
                    {userRole === 3 && logo ? <img src={logo} alt='OttixHow' height='25px' /> :
                        <img src='/assets/logo.png' alt='OttixHow' className='logo' />}
                </Button>
                <Grid container className='navContainer'>
                    {userRole === 1 ? <Link
                        className={`navLink ${selected === 'clients' ? 'selected' : ''}`}
                        style={selected !== 'clients' ? fontColor : null}
                        to='/clients'>
                        <PeopleAltIcon />
                        <span className='navTitle'>Clients</span>
                    </Link> : <>
                        {productsCreated ? <Link
                            className={`navLink ${selected === 'dashboard' ? 'selected' : ''}`}
                            style={selected !== 'dashboard' ? fontColor : null}
                            to={productsCreated && '/dashboard'}>
                            <DashboardIcon />
                            <span className='navTitle'>Dashboard</span>
                        </Link> :
                            <Tooltip title='If no products have been added yet or if added products are pending approval, there is currently nothing to display'>
                                <span className='navLink'>
                                    <DashboardIcon />
                                    <span className='navTitle'>Dashboard</span>
                                </span>
                            </Tooltip>}
                        {productsCreated ? <Link
                            className={`navLink ${selected === 'myProducts' ? 'selected' : ''}`}
                            style={selected !== 'myProducts' ? fontColor : null}
                            to={productsCreated && '/myProducts'}>
                            <DnsIcon />
                            <span className='navTitle'>My Products</span>
                        </Link> :
                            <Tooltip title='If no products have been added yet or if added products are pending approval, there is currently nothing to display'>
                                <span className='navLink'>
                                    <DnsIcon />
                                    <span className='navTitle'>My Products</span>
                                </span>
                            </Tooltip>}
                        <Link
                            className={`navLink ${selected === 'addProducts' ? 'selected' : ''}`}
                            style={selected !== 'addProducts' ? fontColor : null}
                            to='/addProducts'>
                            <ControlPointIcon />
                            <span className='navTitle'>Add Products</span>
                        </Link>
                        <Link
                            className={`navLink ${selected === 'report' ? 'selected' : ''}`}
                            style={selected !== 'report' ? fontColor : null}
                            to='/report'>
                            <SummarizeIcon />
                            <span className='navTitle'>Report</span>
                        </Link>
                    </>}
                </Grid>
                <div className='rightNav'>
                    <IconButton onClick={() => dispatch(toggleTheme())}>
                        {themeMode === 'light' ? <DarkModeIcon style={{ color: '#5A5A5A' }} /> :
                            <LightModeIcon style={{ color: '#E4E3E3' }} />}
                    </IconButton>
                    {userRole !== 1 && <IconButton component={Link} to='/settings'>
                        <SettingsIcon style={fontColor} />
                    </IconButton>}
                    {userRole !== 1 && <IconButton onClick={handlePopClick}>
                        {unread ? <Badge color='error' variant='dot'>
                            <NotificationsIcon style={fontColor} />
                        </Badge> :
                            <NotificationsIcon style={fontColor} />}
                    </IconButton>}
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        sx={{
                            width: '600px', height: '400px', mt: '10px',
                            '& .MuiPopover-paper': {
                                backgroundColor: themeMode === 'light' ? 'white' : '#232D3B',
                                color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3'
                            }
                        }}
                    >
                        <Grid item sx={{
                            px: 2, py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <Typography sx={{ fontSize: '17px' }}>Notification</Typography>
                            <FormControlLabel
                                control={<Switch />}
                                label={<Typography variant='body2' sx={{ fontSize: '11px' }}>Do not disturb</Typography>}
                                labelPlacement='start'
                            />
                        </Grid>
                        <Divider />
                        <List className='custom-scrollbar' sx={{
                            overflowY: 'auto', overflowX: 'hidden', pb: 0, pt: 0, width: '500px', maxHeight: '200px', height: 'auto', fontSize: '11px'
                        }}>
                            {
                                notifications?.loading ? (
                                    <ListItem sx={{ marginLeft: '24%' }}>
                                        <ListItemText primary='Loading...' />
                                    </ListItem>
                                ) : notifications?.data?.length > 0 ? (
                                    notifications?.data?.slice(0, 7).map((obj) => (
                                        <ListItem key={obj?.notification_id} sx={{ p: 1, px: 3, '& .css-10hburv-MuiTypography-root': { fontSize: '12px' } }}>
                                            <ListItemText primary={obj?.notification} />
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem sx={{ p: 1, px: 1, fontSize: '12px', marginLeft: '24%' }}>
                                        <ListItemText primary='No notifications' />
                                    </ListItem>
                                )
                            }
                        </List>
                        <Divider />
                        <Grid sx={{ backgroundColor: themeMode === 'light' ? 'white' : '#232D3B' }}>
                            <Button sx={{ fontSize: '11px', m: 2, textTransform: 'none' }}>
                                View More
                            </Button>
                        </Grid>
                    </Popover>
                    <Button onClick={handleLogout} className='navLink' style={fontColor}>Logout</Button>
                </div>
            </nav>
        </Container>
    )
}

export default Navbar