import React, { useEffect, useState } from 'react';
import { FormControl, Typography, InputLabel, InputBase, Button, Dialog, DialogContent, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { deleteExistingRule, resetDeleteRule } from '../../redux/actions/deleteRule.actions';
import { fetchRules } from '../../redux/actions/rules.actions';
import { deleteExistingProduct, resetDeleteSingleProduct } from '../../redux/actions/deleteSingleProduct.actions';

const DeleteDialog = ({ dialogState, setDialogState, dialogFor }) => {
console.log("dialogState in delete",dialogState)
  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const deleteSingleProduct = useSelector(state => state.deleteSingleProduct);
  const deleteRule = useSelector(state => state.deleteRule);
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false)
  const [serverMsg, setServerMsg] = useState('')

  const confirmSchema = Yup.object().shape({
    confirm: Yup.string().required('Required')
      .test('is-delete-confirmed', 'Please enter DELETE to confirm deletion', value => value === 'DELETE'),
  })

  const handleClose = () => {
    setDialogState({ ...dialogState, [dialogFor]: false, data: null })
  };

  const handleSubmit = () => {
    if (dialogFor === 'deleteSingleProduct') {
      setDeleting(true);
      dispatch(deleteExistingProduct(token, dialogState.data?.prd_id))
    } else if (dialogFor === 'deleteRule') {
      setDeleting(true)
      dispatch(deleteExistingRule(token, dialogState.data));
    }
  }

  useEffect(() => {
    if (deleteSingleProduct.data) {
      handleClose();
      setDeleting(false)
      dispatch(resetDeleteSingleProduct());
    } else if (deleteSingleProduct.error) {
      setServerMsg('Failed to delete product')
      setDeleting(false)
    }
  }, [deleteSingleProduct]) //eslint-disable-line

  useEffect(() => {
    if (deleteRule.data) {
      handleClose();
      setDeleting(false)
      dispatch(resetDeleteRule());
      dispatch(fetchRules(token));
    } else if (deleteRule.error) {
      setServerMsg('Failed to delete rule')
      setDeleting(false)
    }
  }, [deleteRule]) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      ...fontColor,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    },
  }));

  const ipLabel = {
    ...fontColor,
    fontSize: '15px', fontWeight: 'bold', mt: 2
  }

  const warningMsg = { color: 'gray', fontSize: '11px', mt: 2 }

  return (
    <>
      <Dialog
        open={dialogState.deleteNotification || dialogState.deleteSingleProduct || dialogState.deleteRule}
        onClose={handleClose}
        PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '400px' } }}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {deleting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Deleting, please wait...</Typography> : <>
            <Avatar sx={{ bgcolor: '#FEE4E2' }}>
              <ErrorOutlineIcon color='error' />
            </Avatar>
            {dialogFor === 'deleteNotification' && <Typography sx={ipLabel}>Delete notification</Typography>}
            {dialogFor === 'deleteSingleProduct' && <Typography sx={ipLabel}>Delete the Product</Typography>}
            {dialogFor === 'deleteRule' && <Typography sx={ipLabel}>Delete Rule</Typography>}
            {dialogFor === 'deleteNotification' && <Typography sx={warningMsg}>
              Are you sure that you want to stop sending notifications to {dialogState.data?.email} ? Enter DELETE to continue.</Typography>}
            {dialogFor === 'deleteSingleProduct' && <Typography sx={warningMsg}>
              Are you sure that you want to delete the product <b>{dialogState.data?.prd_name}</b> ? This cannot be undone. Enter DELETE to continue.</Typography>}
            {dialogFor === 'deleteRule' && <Typography sx={warningMsg}>
              Are you sure that you want to delete <b>{dialogState.data?.rule_name}</b> rule? This cannot be undone. Enter DELETE to continue.</Typography>}
            <Formik
              initialValues={{ confirm: '' }}
              validationSchema={confirmSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, setFieldValue, values }) => (
                <Form className='loginform'>
                  <FormControl variant='standard' sx={{ width: '100%' }} >
                    <InputLabel shrink htmlFor='confirm' sx={{
                      ...fontColor, fontSize: '11px',
                      '&.Mui-focused': fontColor
                    }}>Confirm
                    </InputLabel>
                    <BootstrapInput
                      type='text'
                      value={values.confirm}
                      placeholder='Enter DELETE'
                      id='confirm'
                      name='confirm'
                      onChange={e => setFieldValue('confirm', e.target.value)}
                    />
                    <Typography sx={{ fontSize: '11px', mt: 1 }} color='red' variant='subtitle'>{errors.confirm}</Typography>
                  </FormControl>
                  <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                  <FormControl sx={{ m: 0, mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between', flexFlow: 'row', gap: '17px' }}>
                    <Button onClick={() => handleClose()} variant='outlined' sx={{ width: 140, textTransform: 'none', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>Cancel</Button>
                    <Button disabled={errors.confirm || !values.confirm} variant='contained' color='error' sx={{ width: 140, textTransform: 'none' }} type='submit' >Confirm</Button>
                  </FormControl>
                </Form>
              )}
            </Formik></>}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeleteDialog;
