import { ENABLE_NOTIFICATION,ENABLE_NOTIFICATION_FAILED,ENABLE_NOTIFICATION_SUCCESSFULL,RESET_ENABLE_NOTIFICATION } from "../constants/enableNotification.constants";

  const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
  };
  
  const enableNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case ENABLE_NOTIFICATION:
        return {
          ...state,
          loading: true,
          success: false,
          data: null,
          error: null,
        };
  
      case ENABLE_NOTIFICATION_SUCCESSFULL:
        return {
          ...state,
          loading: false,
          success: true,
          data: action.payload,
          error: null,
        };
  
      case ENABLE_NOTIFICATION_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          data: null,
          error: action.payload,
        };
  
      case RESET_ENABLE_NOTIFICATION:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default enableNotificationReducer;
  