import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar';
import { Grid, Paper, Avatar, IconButton, Typography, Button, TextField, MenuItem, OutlinedInput, Checkbox, ListItemText, FormControl, Select, Autocomplete } from '@mui/material'
import moment from 'moment';
import * as Yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CompanyUsers } from '../../components/CompanyUsers';
import Footer from '../../components/Footer';
import "./editClient.css"
import { useNavigate, useParams } from 'react-router-dom';
import { fetchClientsData } from '../../redux/actions/clients.actions';
import { fetchTimeZones } from '../../redux/actions/timeZones.actions';
import { fetchCurrencies } from '../../redux/actions/currencies.actions';
import { addNewClient, resetAddClient } from '../../redux/actions/addClient.actions';
import { editExistingClient, resetEditClient } from '../../redux/actions/editClient.actions';

const EditClient = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const clients = useSelector(state => state.clients.data);
  const currencies = useSelector(state => state.currencies.data);
  const timeZones = useSelector(state => state.timeZones.data);
  const addClient = useSelector(state => state.addClient);
  const editClient = useSelector(state => state.editClient);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [serverMsg, setServerMsg] = useState({ submitting: '', error: ''});
  const { clientId } = useParams();
  const current_client = clients?.find(obj => obj.company_id === Number(clientId));
  const current_client_admin = current_client?.users?.find(obj => obj.user.roles[0].role.role_name === 'client super admin');
  const sub_start = current_client?.company_license[0].subscription_start_date;
  const sub_end = current_client?.company_license[0].subscription_end_date;

  const getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const initialState = {
    company_logo: current_client?.company_logo || '',
    profile_picture: '',
    company_name: current_client?.company_name || '',
    account_created_on: current_client?.created_date || moment().format('DD/MM/YYYY'),
    refNo: clientId === 'new' ? '' : clientId,
    company_website: current_client?.company_website || '',
    company_address: current_client?.company_address || '',
    industry: current_client?.industry || '',
    first_name: current_client_admin?.user.first_name || '',
    last_name: current_client_admin?.user.last_name || '',
    phone_number: current_client_admin?.user.phone_number || '',
    email: current_client_admin?.user.email || '',
    remarks: '',
    subscription_status: current_client?.company_license[0].subscription_status || 'trial',
    subscription_start_date: moment(sub_start).format('YYYY-MM-DD') || new Date().toISOString().split('T')[0],
    subscription_end_date: moment(sub_end).format('YYYY-MM-DD') || new Date().toISOString().split('T')[0],
    services: current_client?.company_license[0].services || '',
    no_users_allowed: current_client?.company_license[0].no_users_allowed || '',
    no_of_produts_allowed: current_client?.company_license[0].no_of_produts_allowed || '',
    no_of_mp_allowed: current_client?.company_license[0].no_of_mp_allowed || '',
    db_connection_string: current_client?.db_connection_string || '',
    mongo_connection_string: current_client?.mongo_connection_string || '',
    time_zone: current_client_admin?.user.time_zone || '',
    currency: current_client_admin?.user?.currency?.[0]?.currency || '',
    currency_symbol: current_client_admin?.user?.currency?.[0]?.currency_symbol || '',
    ui_design: current_client_admin?.user.ui_design || 'UI_1',
    db_name: current_client?.db_name || '',
    license_key: '',
    account_type: '',
    status: true,
    flag: true,
  }

  const allServiceLists = [
    'competitor analysis',
    'sales analysis',
  ];

  const validationSchema = Yup.object().shape({
    company_logo: Yup.string().required('Logo is required'),
    company_name: Yup.string().required('Company Name is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    company_website: Yup.string().required('Company website is required').matches(/\..+$/, {
      message: 'Company website must valid',
    }),
    phone_number: Yup.string().required('Phone number is required').matches(/^[0-9]+$/, 'Phone number must contain only numbers'),
    currency: Yup.string().required('Currency is required'),
    currency_symbol: Yup.string().required('currency_symbol is required'),
    time_zone: Yup.string().required('Time zone is required'),
    company_address: Yup.string().required('Address is required'),
    no_users_allowed: Yup.number().min(0, 'No of users allowed must be a positive number').required('No of users allowed is required'),
    no_of_produts_allowed: Yup.number().min(0, 'No of products allowed must be a non-negative number').required('No of products allowed is required'),
    no_of_mp_allowed: Yup.number().min(0, 'No of Marketplace allowed must be a non-negative number').required('No of Marketplace allowed is required'),
    industry: Yup.string().required('Industry is required'),
    subscription_start_date: Yup.string().required('Subscription start date is required'),
    subscription_end_date: Yup.string().required('Subscription end date is required'),
    db_connection_string: Yup.string().required('Db connection string is required'),
    mongo_connection_string: Yup.string().required('Mongo connection string is required'),
    services: Yup.string().required('Please select at least one service'),
  });

  const handleFileChange = (event, setFieldValue) => {
    setInputFocused(true);
    const file = event.target.files[0];

    event.target.value = '';

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFieldValue('company_logo', e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async(values) => {
    if (clientId === 'new') {
      setServerMsg({ submitting: 'Submitting...', error: ''});
      values.db_name = values.company_name;
      values.company_logo = await getBase64FromUrl(values.company_logo);
      values.subscription_start_date = moment(values.subscription_start_date, 'YYYY-MM-DD').toDate();
      values.subscription_end_date = moment(values.subscription_end_date, 'YYYY-MM-DD').toDate();
      dispatch(addNewClient(values, token));
    } else {
      setServerMsg({ submitting: 'Submitting...', error: ''});
      console.log(values.company_logo)
      values.company_logo = await getBase64FromUrl(values.company_logo);
      console.log(values.company_logo)
      values.subscription_start_date = moment(values.subscription_start_date, 'YYYY-MM-DD').toDate();
      values.subscription_end_date = moment(values.subscription_end_date, 'YYYY-MM-DD').toDate();
      dispatch(editExistingClient(values, token));
    }
  }

  useEffect(() => {
    if (clientId === 'new') {
      if (addClient.success) {
        dispatch(resetAddClient());
        navigate('/clients');
        setServerMsg({ submitting: '', error: ''});
      } else if (addClient.error) {
        setServerMsg({ submitting: '', error: 'Failed to create new client'});
      }
    } else {
      if (editClient.success){
        dispatch(resetEditClient());
        navigate('/clients');
        setServerMsg({ submitting: '', error: ''});
      } else if (editClient.error) {
        setServerMsg({ submitting: '', error: 'Failed to edit client data'});
      }
    }
  }, [addClient, editClient]) //eslint-disable-line

  useEffect(() => {
    if (token) {
      dispatch(fetchClientsData(token));
      dispatch(fetchTimeZones());
      dispatch(fetchCurrencies());
      //dispatch(fetchClientsMarkets(token));
    }
  }, [token]) //eslint-disable-line

  const inputBoxStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
        border: '1px solid #BDBDBD !important',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.8rem',
      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
      , '&.Mui-focused': {
        color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '0.8rem',
      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
    },
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
        backgroundColor: themeMode === 'dark' ? '#1A222D' : '#fff'
      },
    },
  };

  return (
    <>
      <Navbar selected='clients' />
      <Grid container className={` ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Paper sx={{
          marginTop: '12px', width: '100%', borderRadius: '0px',
          color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', background: themeMode === 'dark' ? '#232D3B' : 'white'
        }} >
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, handleChange, handleBlur, resetForm, setFieldValue }) => (
              <Form>
                <Grid xs={12} item container mt={3} >
                  <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                      <div
                        style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                        <Avatar sx={{
                          width: 110, height: 110,
                          '& > img': {
                            objectFit: 'contain',
                          },
                        }} alt='Remy Sharp' src={values.company_logo} />
                        <label htmlFor='avatar-upload'>
                          {hovered && (
                            <IconButton
                              style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                              aria-label='Edit avatar' component='span' >
                              <EditIcon />
                            </IconButton>
                          )}
                        </label>
                      </div>
                      <input id='avatar-upload' name='avatar-upload' type='file' accept='image/*' style={{ display: 'none' }} onChange={(event) => handleFileChange(event, setFieldValue)} />
                      <ErrorMessage name='company_logo' component='p' style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} />
                    </div>
                  </Grid>
                  <Grid item xs={8} >
                    {clientId === 'new' ? <>
                      <Typography variant='h6' sx={{ fontWeight: 'bold', fontSize: '17px' }} >Add new client</Typography>
                      <Typography sx={{ fontSize: '13px' }} variant='subtitle1' >Choose a logo for client</Typography></> : <>
                      <Typography variant='h6' sx={{ fontWeight: 'bold', fontSize: '17px' }} >{values.company_name}</Typography>
                      <Typography sx={{ fontSize: '13px' }} variant='subtitle1' >Account added on: {moment(values.account_created_on).format('DD/MM/YYYY')}</Typography>
                      <Typography variant='subtitle1' sx={{ fontSize: '13px' }} >Ref No: {values?.refNo}</Typography></>}
                  </Grid>
                  <Grid item xs={2} >
                    <div>
                      {isInputFocused && (
                        <>
                          <Button sx={{
                            marginRight: '15px', marginTop: '5px', fontSize: '10px',
                            border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                            '&:hover': {
                              borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            },
                            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                          }} variant='outlined'
                            onClick={() => {
                              setInputFocused(false)
                              resetForm();
                              setServerMsg({ submitting: '', error: ''});
                            }}
                          >Cancel</Button>
                          <Button type='submit' sx={{
                            marginRight: '15px', marginTop: '5px', fontSize: '10px',
                            border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                            '&:hover': {
                              borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            },
                            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                          }} variant='outlined' >Save</Button>
                        </>
                      )}
                      <Typography sx={{ marginTop: '10px', fontSize: '12px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>{serverMsg.submitting}</Typography>
                      <Typography sx={{ marginTop: '10px', fontSize: '12px', color: 'red' }}>{serverMsg.error}</Typography>
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} padding={3}>
                  <Grid item xs={12} mb={2}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }} variant='h6' >Company Information:</Typography>
                  </Grid>
                  <Grid container item xs={12} p={1}>
                    <Grid item xs={3} >

                      <Field as={TextField} sx={inputBoxStyle} type='text' name='company_name' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input company_name' label='Company Name' value={values.company_name} onChange={handleChange} />
                      <ErrorMessage name='company_name' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='text' name='company_website' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input company_website' label='Company Website' value={values.company_website} onChange={handleChange} />
                      <ErrorMessage name='company_website' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='text' name='company_address' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input company_address' label='Company Address' value={values.company_address} onChange={handleChange} />
                      <ErrorMessage name='company_address' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='text' name='industry' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input industry' label='Industry' value={values.industry} onChange={handleChange} />
                      <ErrorMessage name='industry' component='p' className='errorMessage-addclient' />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} p={1}>
                    <Grid item xs={3} >
                      <Field autoComplete='name' as={TextField} sx={inputBoxStyle} type='text' name='first_name' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input first_name' label='First Name' value={values.first_name} onChange={handleChange} />
                      <ErrorMessage name='first_name' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <Field as={TextField} sx={inputBoxStyle} type='text' name='last_name' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input last_name' label='Last Name' value={values.last_name} onChange={handleChange} />
                      <ErrorMessage name='last_name' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <Field as={TextField} sx={inputBoxStyle} type='text' name='phone_number' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input phone_number' label='Phone Number' value={values.phone_number} onChange={handleChange} />
                      <ErrorMessage name='phone_number' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <Field autoComplete='email' as={TextField} sx={inputBoxStyle} type='text' name='email' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input email' label='Email' value={values.email} onChange={handleChange} />
                      <ErrorMessage name='email' component='p' className='errorMessage-addclient' />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} p={1} >
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='text' name='remarks' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input remarks' label='Remarks' value={values.remarks} onChange={handleChange} />
                      <ErrorMessage name='remarks' component='p' className='errorMessage-addclient' />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12} padding={3} style={{ marginTop: '-20px' }} >
                  <Grid item xs={12} mb={2}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }} variant='h6' >License Details:</Typography>
                  </Grid>
                  <Grid container item xs={12} p={1}>
                    <Grid item xs={3} >
                      <FormControl>
                        <Typography sx={{ position: 'absolute', top: '-10px', fontSize: '10px', left: '14px' }} >Subscription Status</Typography>
                        <Select
                          labelId='subscription-status-label'
                          id='subscription-status'
                          value={values.subscription_status}
                          name='subscription_status'
                          label='Subscription Status'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={() => setInputFocused(true)}
                          size='small'
                          sx={{
                            ...inputBoxStyle, width: '200px',
                            '& .MuiSelect-icon': {
                              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            },
                            '& fieldset': {
                              borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              border: '1px solid #BDBDBD !important',
                            },
                          }}
                          MenuProps={{ PaperProps: { style: { marginTop: '0px', width: '200px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', backgroundColor: themeMode === 'dark' ? '#1A222D' : '#fff' } } }} >
                          <MenuItem sx={{ fontSize: '14px' }} value={'trial'}>Trial</MenuItem>
                          <MenuItem sx={{ fontSize: '14px' }} value={'paid'}>Paid</MenuItem>
                          <MenuItem sx={{ fontSize: '14px' }} value={'expired'}>Expired</MenuItem>
                          <MenuItem sx={{ fontSize: '14px' }} value={'cancelled'}>Cancelled</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} >
                      <TextField sx={{ ...inputBoxStyle, width: '200px' }}
                      type='date' name='subscription_start_date'
                      className={`subscriptionStartDate ${themeMode === 'dark' ? 'darkdateicon' : null}`}
                      size='small' id='subscription_start_date' label='Subscription Start Date'
                      value={values.subscription_start_date} onChange={handleChange}/>
                    </Grid>

                    <Grid item xs={3} >
                      <TextField sx={{ ...inputBoxStyle, width: '200px' }}
                      type='date' name='subscription_end_date'
                      className={`subscriptionEndDate ${themeMode === 'dark' ? 'darkdateicon' : null}`}
                      onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small'
                      id='subscription_end_date' label='Subscription End Date'
                      value={values.subscription_end_date} onChange={handleChange} />
                      <ErrorMessage name='subscription_end_date' className='errorMessage-addclient' component='p' />
                    </Grid>

                    <Grid item xs={3} >
                      <FormControl sx={inputBoxStyle}>
                        <Typography sx={{ position: 'absolute', top: '-10px', fontSize: '10px', left: '14px' }} >Services</Typography>
                        <Select
                          onFocus={() => setInputFocused(true)}
                          onBlur={handleBlur}
                          multiple
                          size='small'
                          name='demo-multiple-checkbox'
                          sx={{
                            ...inputBoxStyle, width: '200px',
                            '& .MuiSelect-icon': {
                              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            },
                            '& fieldset': {
                              borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              border: '1px solid #BDBDBD !important',
                            },
                          }}
                          value={values.services.split(',').map(service => service.trim())} // Trim values before comparing
                          onChange={(event) => {
                            const { value } = event.target;
                            const selectedServices = value.filter(Boolean).join(', '); // Remove empty strings and join values
                            setFieldValue('services', selectedServices);
                          }}
                          input={<OutlinedInput label='Services' />}
                          renderValue={(selected) => (selected && selected.length > 0 ? selected.join(', ') : '')}
                          MenuProps={MenuProps}
                        >
                          {allServiceLists.map((name, index) => (
                            <MenuItem key={index} value={name}>
                              <Checkbox sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} id={`servicecheckbox${index}`} size='small' checked={values.services.split(',').map(service => service.trim()).includes(name)} />
                              <ListItemText
                                primary={<Typography variant='body2' sx={{ fontSize: '12px' }}>{name}</Typography>}
                              />
                            </MenuItem>
                          ))}
                        </Select>

                      </FormControl>
                      <ErrorMessage name='services' component='p' className='errorMessage-addclient' />
                    </Grid>



                  </Grid>
                  <Grid container item xs={12} p={1} >
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='number' name='no_users_allowed' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input number' label='No of Users Allowed' value={values.no_users_allowed} onChange={handleChange} />
                      <ErrorMessage name='no_users_allowed' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='number' name='no_of_produts_allowed' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input no_of_produts_allowed' label='No of Products' value={values.no_of_produts_allowed} onChange={handleChange} />
                      <ErrorMessage name='no_of_produts_allowed' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='number' name='no_of_mp_allowed' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input no_of_mp_allowed' label='No of Marketplaces' value={values.no_of_mp_allowed} onChange={handleChange} />
                      <ErrorMessage name='no_of_mp_allowed' component='p' className='errorMessage-addclient' />
                    </Grid>

                  </Grid>


                </Grid>
                <Grid container item xs={12} padding={3} style={{ marginTop: '-20px' }} >
                  <Grid item xs={12} mb={2}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }} variant='h6' >Technical Details:</Typography>
                  </Grid>
                  <Grid container item xs={12} p={1}>
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='text' name='db_connection_string' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input db_connection_string' label='DB Connection String' value={values.db_connection_string} onChange={handleChange} />
                      <ErrorMessage name='db_connection_string' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField sx={inputBoxStyle} type='text' name='mongo_connection_string' onFocus={() => setInputFocused(true)} onBlur={handleBlur} size='small' id='outlined-input mongo_connection_string' label='Mongo DB Connection String' value={values.mongo_connection_string} onChange={handleChange} />
                      <ErrorMessage name='mongo_connection_string' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <Autocomplete
                        id='time_zone'
                        options={timeZones ? timeZones : []}
                        getOptionLabel={(option) => option}
                        sx={inputBoxStyle}
                        value={values.time_zone ? values.time_zone : null}
                        onBlur={handleBlur}
                        size='small'
                        label='Time Zone'
                        PaperComponent={({ children }) => (
                          <Paper sx={{
                            fontSize: '14px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D',
                            '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                          }}>{children}</Paper>
                        )}
                        onChange={(event, newValue) => setFieldValue('time_zone', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params} name='time_zone' label='Time Zone' size='small' onFocus={() => setInputFocused(true)}
                            sx={{ width: 200, '& .MuiAutocomplete-clearIndicator': { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } }} />
                        )}
                      />
                      <ErrorMessage name='time_zone' component='p' className='errorMessage-addclient' />
                    </Grid>
                    <Grid item xs={3} >
                      <Autocomplete
                        id='currency'
                        options={currencies ? currencies : []}
                        getOptionLabel={(option) => `${option.currency_name} (${option.currency_symbol})`}
                        sx={inputBoxStyle}
                        value={values.currency ? { currency_name: values.currency, currency_symbol: values.currency_symbol } : null}
                        onBlur={handleBlur}
                        size='small'
                        label='Currency'
                        PaperComponent={({ children }) => (
                          <Paper sx={{
                            fontSize: '14px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D',
                            '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                          }}>{children}</Paper>
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFieldValue('currency', newValue.currency_name);
                            setFieldValue('currency_symbol', newValue.currency_symbol);
                          } else {
                            setFieldValue('currency', '');
                            setFieldValue('currency_symbol', '');
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params} name='currency' label='Currency' size='small' onFocus={() => setInputFocused(true)}
                            sx={{ width: 200, '& .MuiAutocomplete-clearIndicator': { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } }} />
                        )}
                        isOptionEqualToValue={(option, value) => option.currency_name === value.currency_name}
                      />
                      <ErrorMessage name='currency' component='p' className='errorMessage-addclient' />
                    </Grid>
                  </Grid>

                </Grid>

              </Form>
            )}
          </Formik>
        </Paper>

        {clientId !== 'new' && <Paper sx={{
          marginTop: '12px', width: '100%', borderRadius: '0px',
          color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', background: themeMode === 'dark' ? '#232D3B' : 'white'
        }}>
          <CompanyUsers users={current_client?.users} />
        </Paper>}
        <Paper sx={{ marginTop: '12px', width: '100%' }} >
          <Footer />
        </Paper>
      </Grid>
    </>
  )
}

export default EditClient;
