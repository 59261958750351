import axios from "axios";
import { paths } from '../../Api/paths';
import { UPDATE_USER_STATUS, UPDATE_USER_STATUS_FAILED, UPDATE_USER_STATUS_SUCCESSFULL, RESET_UPDATE_USER_STATUS } from "../constants/updateUserStatus.constants";

const updateUserStatus = (data) => ({
    type: UPDATE_USER_STATUS,
    payload: data
});

const updateUserStatusSuccess = (data) => ({
    type: UPDATE_USER_STATUS_SUCCESSFULL,
    payload: data
});

const updateUserStatusFailure = (error) => ({
    type: UPDATE_USER_STATUS_FAILED,
    payload: error
});

export const resetupdateUserStatus = () => ({
    type: RESET_UPDATE_USER_STATUS
})

export const updateExistingUserStatus = (data, token) => async (dispatch) => {
    try {
        dispatch(updateUserStatus(data));
        const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.users}/${data.id}/`,
            data, { headers: { Authorization: `bearer ${token}` } })
        if (response.data.status === 'Success') {
            dispatch(updateUserStatusSuccess(response.data));
        } else {
            dispatch(updateUserStatusFailure(response.data.status));
        }
    } catch (error) {
        dispatch(updateUserStatusFailure(error));
    }
};