import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import { useDispatch, useSelector } from 'react-redux';
import { Divider, FormControl, FormControlLabel, Grid, Card, Button, IconButton, Paper, Popover, Radio, RadioGroup, Typography, AccordionSummary, Accordion, AccordionDetails, Select, MenuItem, Chip, Box, Switch } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import WestIcon from '@mui/icons-material/West';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriceComparisonDialog from '../../components/PriceComparisonDialog';
import ProductDetailsFilterDialog from '../../components/ProductDetailsFilterDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';
import Footer from '../../components/Footer';
import { resetUpdateScore, updateScore } from '../../redux/actions/updateScore.actions';
import { fetchMarketplaces } from '../../redux/actions/marketPlaces.actions';
import { fetchLocations } from '../../redux/actions/locations.actions';
import { clearFilters } from '../../redux/actions/detailsPageFilters.actions';
import EditProductDialog from '../../components/EditProductDialog';
import AddCompetitorDialog from '../../components/AddCompetitorDialog';
import RefreshCompetitorDialog from '../../components/RefreshCompetitorDialog';
import EnableMatchDialog from '../../components/EnableMatchDialog';
import { resetUpdateProduct, updateProduct } from '../../redux/actions/updateProduct.actions';
import { handleEnableNotification, resetEnableNotification } from '../../redux/actions/enableNotification.actions';

const ProductDetails = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const currSymb = useSelector(state => state.auth.currency[0].currency_symbol);
  const productDetails = useSelector(state => state.productDetails);
  const compScoreUpdate = useSelector(state => state.updateScore);
  const filters = useSelector(state => state.detailsPageFilters);
  const locations = useSelector(state => state.locations);
  const updateProductDetails = useSelector(state => state.updateProductDetails);
  const enableNotificationDetails = useSelector(state => state.enableNotificationDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product_id, location_id } = useParams();
  const competitorsData = productDetails.data?.competitors?.filter(comp => comp.loc.loc_id === Number(location_id));
  const priceDetails = productDetails.data?.locations[0];

  const [popUp, setPopup] = useState({ openLocationPopup: null, openSettingsPopup: null, openMatchIdPopup: null })

  const [activeTab, setActiveTab] = useState('marketdata');
  const [selectedMatchRate, setSelectedMatchRate] = useState('');
  const [compPrdId, setCompPrdId] = useState('');

  const [switchStatus, setSwitchStatus] = useState({
    monitor: { status: false, error: false },
    notification: { status: false, error: false },
  });

  const percentageValues = Array.from({ length: 100 }, (_, i) => i + 1);

  const handleLocation = (event) => {
    const tempLoc = event.target.value;
    dispatch(clearFilters());
    sessionStorage.removeItem('detailPageFilters');
    setPopup({ openLocationPopup: null, openSettingsPopup: null, openMatchIdPopup: null });
    navigate(`/myProduct/${product_id}/${tempLoc}`)
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handlePopupOpen = (popupType, event, comp) => {
    setPopup({ ...popUp, [popupType]: event.currentTarget });
    setCompPrdId(comp)
  };

  const handlePopupClose = () => {
    setPopup({
      openLocationPopup: null,
      openSettingsPopup: null,
      openMatchIdPopup: null,
    });
    setSwitchStatus({
      monitor: {
        status: false,
        error: false
      },
      notification: {
        status: false,
        error: false
      }
    });
    dispatch(resetUpdateProduct());
  };

  const locPopup = Boolean(popUp.openLocationPopup);
  const settingPopup = Boolean(popUp.openSettingsPopup);
  const matchIdPopup = Boolean(popUp.openMatchIdPopup);
  const LocId = locPopup ? 'location-popover' : undefined;
  const settingsId = settingPopup ? 'settings-popover' : undefined;
  const matchId = matchIdPopup ? 'matchId-popover' : undefined;

  const dummyData = [
    {
      id: '1',
      image: 'https://picsum.photos/200',
      title: 'Beats Studio3 Wireless Over - Ear Headphones Matt Black',
      description: 'Description for Lizard 1',
      price: 'AED 930.00',
      seller: 'MK Retail Shops Private Limited',
      ppu: 'AED 1306/ct',
      rating: '4.95/5 (1234)',
      positivefeedback: '48',
      negativefeedback: '12',
      marketplace: 'own product',
      positivefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
      negativefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
      promoDesc: 'Get additional 5% discount, Get extended waranty for free',
      pdtTag: 'Fast selling, In 200 + carts, Best Seller',
      stockAvailable: 'Available',
      wordCount: '60',
      keywords: '45',
      impKeywords: 'High, Performance, Wireless, Noice, Cancelling, Experience',
      keywordDificultyPer: '88%',
      keywordDificultyLabel: 'Very Hard',
      pdtDeliverytime: '3 Days',
      pdtDeliveryCharge: 'AED 4.8',
      pdtslaes: '1000+ previous month',
      pdtDesc: 'Get additional 5% discount, Get extended waranty for free',
      keywordDesc: 'High-Performance wireless Noise Cancelling Headphones Compatiable With IOS And Android Devices. Pure Adaptive Noise Cancelling (Pure ANC) Actively BLocks External Noise Real-Time Audio Calibration Preserves A Premium Listening Eperience.',

      competitors: [
        {
          id: '1',
          title: 'Beats Studio1 Wireless Over - Ear Headphones Matt Black',
          image: 'https://picsum.photos/200',
          seller: 'MK Retail Shops Private Limited',
          price: 'AED 1401.00',
          cpi: '1.18',
          ppu: 'AED 1306/ct',
          matchrate: '100%',
          lastupdatedon: '10-19-2024, 2.32 PM',
          rating: '4.1/5 (1)',
          positivefeedback: '48',
          marketplace: 'Amazon.ae',
          negativefeedback: '12',
          positivefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          negativefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          promoDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtTag: 'Fast selling, In 200 + carts, Best Seller',
          stockAvailable: 'Available',
          wordCount: '60',
          keywords: '45',
          impKeywords: 'High, Performance, Wireless, Noice, Cancelling, Experience',
          keywordDificultyPer: '88%',
          keywordDificultyLabel: 'Very Hard',
          pdtDeliverytime: '3 Days',
          pdtDeliveryCharge: 'AED 4.8',
          pdtslaes: '1000+ previous month',
          pdtDesc: 'Get additional 5% discount, Get extended waranty for free',
          keywordDesc: 'High-Performance wireless Noise Cancelling Headphones Compatiable With IOS And Android Devices. Pure Adaptive Noise Cancelling (Pure ANC) Actively BLocks External Noise Real-Time Audio Calibration Preserves A Premium Listening Eperience.',

        },
        {
          id: '2',
          title: 'Beats Studio2 Wireless Over - Ear Headphones Matt Black',
          image: 'https://picsum.photos/200',
          seller: 'MK Retail Shops Private Limited',
          price: 'AED 1401.00',
          cpi: '1.18',
          ppu: 'AED 1306/ct',
          matchrate: '100%',
          lastupdatedon: '10-19-2024, 2.32 PM',
          rating: '4.2/5 (2)',
          positivefeedback: '48',
          marketplace: 'Amazon.ae',
          negativefeedback: '12',
          positivefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          negativefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          promoDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtTag: 'Fast selling, In 200 + carts, Best Seller',
          stockAvailable: 'Available',
          pdtDeliverytime: '3 Days',
          wordCount: '60',
          keywords: '45',
          impKeywords: 'High, Performance, Wireless, Noice, Cancelling, Experience',
          keywordDificultyPer: '88%',
          keywordDificultyLabel: 'Very Hard',
          pdtDeliveryCharge: 'AED 4.8',
          pdtDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtslaes: '1000+ previous month',
          keywordDesc: 'High-Performance wireless Noise Cancelling Headphones Compatiable With IOS And Android Devices. Pure Adaptive Noise Cancelling (Pure ANC) Actively BLocks External Noise Real-Time Audio Calibration Preserves A Premium Listening Eperience.',

        },
        {
          id: '3',
          title: 'Beats Studio3 Wireless Over - Ear Headphones Matt Black',
          image: 'https://picsum.photos/200',
          seller: 'MK Retail Shops Private Limited',
          price: 'AED 1401.00',
          cpi: '1.18',
          ppu: 'AED 1306/ct',
          matchrate: '100%',
          lastupdatedon: '10-19-2024, 2.32 PM',
          rating: '4.3/5 (3)',
          positivefeedback: '48',
          marketplace: 'Amazon.ae',
          negativefeedback: '12',
          positivefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          negativefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          promoDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtTag: 'Fast selling, In 200 + carts, Best Seller',
          stockAvailable: 'Available',
          wordCount: '60',
          keywords: '45',
          impKeywords: 'High, Performance, Wireless, Noice, Cancelling, Experience',
          keywordDificultyPer: '88%',
          keywordDificultyLabel: 'Very Hard',
          pdtDeliverytime: '3 Days',
          pdtDeliveryCharge: 'AED 4.8',
          pdtDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtslaes: '1000+ previous month',
          keywordDesc: 'High-Performance wireless Noise Cancelling Headphones Compatiable With IOS And Android Devices. Pure Adaptive Noise Cancelling (Pure ANC) Actively BLocks External Noise Real-Time Audio Calibration Preserves A Premium Listening Eperience.',
        },
        {
          id: '4',
          title: 'Beats Studio4 Wireless Over - Ear Headphones Matt Black',
          image: 'https://picsum.photos/200',
          seller: 'MK Retail Shops Private Limited',
          price: 'AED 1401.00',
          cpi: '1.18',
          ppu: 'AED 1306/ct',
          matchrate: '100%',
          lastupdatedon: '10-19-2024, 2.32 PM',
          rating: '4.4/5 (4)',
          positivefeedback: '48',
          marketplace: 'Amazon.ae',
          negativefeedback: '12',
          positivefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          negativefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          promoDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtTag: 'Fast selling, In 200 + carts, Best Seller',
          stockAvailable: 'Available',
          wordCount: '60',
          keywords: '45',
          impKeywords: 'High, Performance, Wireless, Noice, Cancelling, Experience',
          keywordDificultyPer: '88%',
          keywordDificultyLabel: 'Very Hard',
          pdtDeliverytime: '3 Days',
          pdtDeliveryCharge: 'AED 4.8',
          pdtslaes: '1000+ previous month',
          pdtDesc: 'Get additional 5% discount, Get extended waranty for free',
          keywordDesc: 'High-Performance wireless Noise Cancelling Headphones Compatiable With IOS And Android Devices. Pure Adaptive Noise Cancelling (Pure ANC) Actively BLocks External Noise Real-Time Audio Calibration Preserves A Premium Listening Eperience.',

        },
        {
          id: '5',
          title: 'Beats Studio5 Wireless Over - Ear Headphones Matt Black',
          image: 'https://picsum.photos/200',
          seller: 'MK Retail Shops Private Limited',
          price: 'AED 1401.00',
          cpi: '1.18',
          ppu: 'AED 1306/ct',
          matchrate: '100%',
          lastupdatedon: '10-19-2024, 2.32 PM',
          rating: '4.5/5 (5)',
          positivefeedback: '48',
          marketplace: 'Amazon.ae',
          negativefeedback: '12',
          positivefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          negativefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          promoDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtTag: 'Fast selling, In 200 + carts, Best Seller',
          stockAvailable: 'Available',
          wordCount: '60',
          keywords: '45',
          impKeywords: 'High, Performance, Wireless, Noice, Cancelling, Experience',
          keywordDificultyPer: '88%',
          pdtDeliverytime: '3 Days',
          keywordDificultyLabel: 'Very Hard',
          keywordDesc: 'High-Performance wireless Noise Cancelling Headphones Compatiable With IOS And Android Devices. Pure Adaptive Noise Cancelling (Pure ANC) Actively BLocks External Noise Real-Time Audio Calibration Preserves A Premium Listening Eperience.',
          pdtDesc: 'Get additional 5% discount, Get extended waranty for free',
        },
        {
          id: '6',
          title: 'Beats Studio6 Wireless Over - Ear Headphones Matt Black',
          image: 'https://picsum.photos/200',
          seller: 'MK Retail Shops Private Limited',
          price: 'AED 1401.00',
          cpi: '1.18',
          ppu: 'AED 1306/ct',
          matchrate: '100%',
          lastupdatedon: '10-19-2024, 2.32 PM',
          rating: '4.6/5 (6)',
          positivefeedback: '48',
          marketplace: 'Amazon.ae',
          negativefeedback: '12',
          positivefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          negativefeedbackdesc: 'I love my Boat headphones for their exceptional sound quality and comfortable design. Boat headphones are often praised for their impressive sound quality, delivering rich and balanced audio across various music genres. The comfortable design ensures extended wear without causing discomfort. The combination of great sound and comfort makes them a popular choice among users seeking an enjoyable listening experience.',
          promoDesc: 'Get additional 5% discount, Get extended waranty for free',
          pdtTag: 'Fast selling, In 200 + carts, Best Seller',
          stockAvailable: 'Available',
          wordCount: '60',
          keywords: '45',
          impKeywords: 'High, Performance, Wireless, Noice, Cancelling, Experience',
          keywordDificultyPer: '88%',
          keywordDificultyLabel: 'Very Hard',
          pdtDeliverytime: '3 Days',
          pdtDeliveryCharge: 'AED 4.8',
          pdtslaes: '1000+ previous month',
          keywordDesc: 'High-Performance wireless Noise Cancelling Headphones Compatiable With IOS And Android Devices. Pure Adaptive Noise Cancelling (Pure ANC) Actively BLocks External Noise Real-Time Audio Calibration Preserves A Premium Listening Eperience.',
          pdtDesc: 'Get additional 5% discount, Get extended waranty for free',
        },
      ]
    }
  ];

  const chunkArray = (arr, chunkSize) => {
    const result = [];
    let filteredArr = [];
    if (filters.applied) {
      filteredArr = arr?.filter(comp => filters.competitors.includes(comp.comp_prd_id))
    } else {
      filteredArr = arr;
    }
    if (filteredArr) {
      for (let i = 0; i < filteredArr.length; i += chunkSize) {
        result.push(filteredArr.slice(i, i + chunkSize));
      }
    }
    return result;
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsPerPage = 3;
  const carasoulArray = chunkArray(competitorsData, cardsPerPage);
  const totalPages = carasoulArray?.length;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < totalPages - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const updateCompScore = () => {
    const queryString = `?comp_prd_id=${compPrdId}&comp_prd_score=${selectedMatchRate}`
    dispatch(updateScore(token, queryString));
    handlePopupClose();
  }

  const [accordionsExpanded, setAccordionsExpanded] = useState({
    rating: false,
    description: false,
    promotion: false,
    product: false,
  });

  const handleAccordionClick = (accordionKey) => {
    setAccordionsExpanded((prevExpanded) => ({
      ...prevExpanded,
      [accordionKey]: !prevExpanded[accordionKey],
    }));
  };

  const [rating, setRating] = useState(false);
  const [descSeeMore, setDescSeeMore] = useState(false);
  const [promotionSeeMore, setPromotionSeeMore] = useState(false);
  const [productSeeMore, setProductSeeMore] = useState(false);

  const [isSticky, setSticky] = useState(false);
  const [cardHeight, setCardHeight] = useState(300);

  const handleSeeMoreRating = (event) => {
    event.stopPropagation();
    setRating(!rating);
  };

  const handleSeeMoreDesc = (event) => {
    event.stopPropagation();
    setDescSeeMore(!descSeeMore);
  };

  const handleSeeMorePromotion = (event) => {
    event.stopPropagation();
    setPromotionSeeMore(!promotionSeeMore);
  };

  const handleSeeMoreProduct = (event) => {
    event.stopPropagation();
    setProductSeeMore(!productSeeMore);
  };

  const handleChangeMatchrate = (event) => {
    setSelectedMatchRate(event.target.value);
  }

  const [dialogState, setDialogState] = useState({
    openGrpah: false,
    openPdtFilter: { show: false, data: null },
    editProduct: false, data: null,
    addComp: false,
    refresh: false,
    enableMatch: false,
  })

  const openDialog = (type) => {
    const tempState = { ...dialogState, [type]: true }
    setDialogState(tempState)
    handlePopupClose()
  }

  useEffect(() => {
    if (compScoreUpdate.data) {
      dispatch(fetchProductDetails(token, product_id));
    }
  }, [compScoreUpdate]) //eslint-disable-line

  useEffect(() => {
    dispatch(fetchMarketplaces(token));
    dispatch(fetchLocations(token))
    dispatch(resetUpdateScore());
    dispatch(fetchProductDetails(token, product_id));
  }, []) //eslint-disable-line

  useEffect(() => {
    if (updateProductDetails.data) {
      dispatch(resetUpdateProduct());
      dispatch(fetchProductDetails(token, product_id));
      // handlePopupClose();
      setSwitchStatus(prevState => ({
        ...prevState,
        monitor: {
          status: false,
          error: false
        }
      }));
    } else if (updateProductDetails.error) {
      setSwitchStatus(prevState => ({
        ...prevState,
        monitor: {
          status: false,
          error: true
        }
      }));
    }
  }, [updateProductDetails]) //eslint-disable-line

  // notifications status
  useEffect(() => {
    if (enableNotificationDetails.data) {
      dispatch(resetEnableNotification());
      dispatch(fetchProductDetails(token, product_id));
      // handlePopupClose();
      setSwitchStatus(prevState => ({
        ...prevState,
        notification: {
          status: false,
          error: false
        }
      }));
    } else if (enableNotificationDetails.error) {
      setSwitchStatus(prevState => ({
        ...prevState,
        notification: {
          status: false,
          error: true
        }
      }));
    }
  }, [enableNotificationDetails]) //eslint-disable-line

  const handleMonitorChange = (event) => {
    const newCheckedValue = event.target.checked;
    setSwitchStatus(prevState => ({
      ...prevState,
      monitor: {
        status: true,
        error: false
      }
    }));
    let newValues = {
      prd_id: productDetails?.data?.prd_id,
      modified_date: productDetails?.data?.modified_date || '',
      loc_id: productDetails?.data?.locations?.[0].loc_id,
      prd_sales_price: productDetails?.data?.locations?.[0].prd_sales_price,
      prd_mrp: productDetails?.data?.locations?.[0].prd_mrp,
      prd_no_of_pieces: productDetails?.data?.prd_no_of_pieces,
      prd_offer: productDetails?.data?.locations?.[0]?.prd_offer || '',
      seller_id: productDetails?.data?.locations?.[0]?.seller_id,
      dept_id: productDetails?.data?.dept.dept_id,
      category_id: productDetails?.data?.category_id,
      prd_name: productDetails?.data?.prd_name || '',
      prd_desc: productDetails?.data?.prd_desc || '',
      prd_size: productDetails?.data?.prd_size,
      prd_uom: productDetails?.data?.prd_uom || '',
      prd_url: productDetails?.data?.prd_url || '',
      prd_upc: productDetails?.data?.prd_upc || '',
      prd_asin: productDetails?.data?.prd_asin || '',
      prd_brand: productDetails?.data?.prd_brand || '',
      prd_medium_image: productDetails?.data?.prd_medium_image || '',
      prd_small_image: productDetails?.data?.prd_small_image || '',
      prd_variants: productDetails?.data?.prd_variants || '',
      keyword: productDetails?.data?.keyword || '',
      prd_is_active: productDetails?.data?.prd_is_active,
      prd_spec: productDetails?.data?.prd_spec || '',
      prd_is_crawl_enabled: newCheckedValue,
      prd_rating: productDetails?.data?.prd_rating || 0,
      prd_tot_reviews: productDetails?.data?.prd_tot_reviews,
      prd_is_match_enabled: productDetails?.data?.prd_is_match_enabled,

    }
    dispatch(updateProduct(token, newValues));
  };

  const handleNotificationChange = (event) => {
    const notificationCheckedValue = event.target.checked;
    if (notificationCheckedValue) {

    }
    setSwitchStatus(prevState => ({
      ...prevState,
      notification: {
        status: true,
        error: false
      }
    }));
    dispatch(handleEnableNotification(token, { prd_id: product_id }))
  }

  const settingsOptionStyle = { display: 'flex', gap: '10px', pl: '12px', p: '10px', m: '6px', cursor: 'pointer', transition: 'background-color 0.3s ease', '&:hover': { backgroundColor: '#f4d35e' } }

  const iconStyle = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1' }

  const accStyle = { border: '0.5px solid #e5e1e1', boxShadow: 'none', borderRadius: '11px 11px 0px 0px', }

  const accMainGrid = { display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }
  const accPdtSubGrid = { width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }
  const accCompSubGrid = { width: '25%', verticalAlign: 'top', p: 1 }

  return (
    <>
      <Navbar selected='myProducts' />
      {dialogState.openGrpah && <PriceComparisonDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.openPdtFilter.show && <ProductDetailsFilterDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.editProduct && <EditProductDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.addComp && <AddCompetitorDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.refresh && <RefreshCompetitorDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.enableMatch && <EnableMatchDialog dialogState={dialogState} setDialogState={setDialogState} productData={productDetails?.data} setSwitchStatus={setSwitchStatus} />}

      <Grid container className={`${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Paper sx={{ my: 1, width: '100%', boxShadow: 'none', borderRadius: '0px', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >
          <Grid pt={3} item xs={12} container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '65px' }} >
            <Grid sx={{ pl: 5 }} item xs={8} >
              <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                <WestIcon onClick={() => navigate('/myProducts')} sx={{ fontSize: 20, cursor: 'pointer' }} />
                <Typography sx={{ fontSize: '12px', whiteSpace: 'nowrap' }} >My Products</Typography>
                <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                <Typography sx={{ fontSize: '12px' }} >{productDetails.data ? productDetails.data.prd_name : 'Loading, please wait'}</Typography>
              </Box>
            </Grid>
            <Grid sx={{ pr: 5, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '20px' }} item xs={4} >
              <Box onClick={(event) => handlePopupOpen('openLocationPopup', event)}
                sx={{
                  background: locPopup ? '#f4d35e' : 'transparent', cursor: 'pointer', display: 'flex',
                  gap: '12px', alignItems: 'center', borderRadius: '38px', p: '8px', width: '200px',
                  border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1', height: '22px'
                }} >
                <FmdGoodOutlinedIcon size='15px' />
                <Typography sx={{ fontWeight: 'bold', color: locPopup ? themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >
                  {locations.data?.find(loc => loc.loc_id === Number(location_id))?.loc_name || ''}
                </Typography>
              </Box>
              <Popover
                sx={{
                  mt: '15px', ml: '-50px',
                  '& .MuiPaper-root': {
                    border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A', borderRadius: '12px', boxShadow: 'none',
                  },
                }}
                id={LocId} open={locPopup} anchorEl={popUp.openLocationPopup}
                onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <FormControl sx={{ width: '220px', pt: '20px', pl: '40px', pb: '20px' }}>
                  <RadioGroup value={location_id} onChange={handleLocation} id='location' >
                    {locations.data?.map(loc => <FormControlLabel key={loc.loc_id} value={loc.loc_id}
                      control={<Radio size='small' />} label={loc.loc_name} />)}
                  </RadioGroup>
                </FormControl>
              </Popover>
              <IconButton sx={iconStyle}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
              <IconButton sx={{
                ...iconStyle,
                background: settingPopup ? '#f4d35e' : 'transparent'
              }}
                onClick={(event) => handlePopupOpen('openSettingsPopup', event)} >
                <SettingsOutlinedIcon />
              </IconButton>
              <Popover
                sx={{
                  mt: '15px', ml: '-50px',
                  '& .MuiPaper-root': {
                    border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A', borderRadius: '12px', boxShadow: 'none', width: '220px'
                  },
                }}
                id={settingsId} open={settingPopup} anchorEl={popUp.openSettingsPopup}
                onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Grid item container xs={12} >

                  <Grid
                    onClick={() => {
                      setDialogState(prevState => ({
                        ...prevState,
                        editProduct: true, data: productDetails?.data
                      }))
                      handlePopupClose();
                    }}
                    item xs={12} sx={settingsOptionStyle}>
                    <ModeOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '14px' }}>Edit</Typography>
                  </Grid>
                  <Grid item xs={12} sx={settingsOptionStyle} onClick={() => openDialog('addComp')}>
                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '14px' }}>Add Competitor</Typography>
                  </Grid>
                  <Grid item xs={12} sx={settingsOptionStyle} onClick={() => openDialog('refresh')}>
                    <RefreshOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '14px' }}>Refresh</Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '3px', mb: '3px' }}>
                    <Divider sx={{ width: '80%' }} />
                  </Grid>

                  <Grid item xs={12} sx={settingsOptionStyle} onClick={() => openDialog('enableMatch')}>
                    <BeenhereOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '14px' }} >Match</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ ...settingsOptionStyle, alignItems: 'center' }}>
                    <ComputerOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '14px' }}>Monitor</Typography>
                    <div style={{ textAlign: 'end', width: '100%' }} >
                      <Switch
                        size='small'
                        defaultChecked={productDetails?.data?.prd_is_crawl_enabled}
                        onChange={handleMonitorChange}
                        disabled={switchStatus.monitor.status}
                      />
                    </div>
                  </Grid>
                  <Grid sx={{ width: '100%' }} >
                    {switchStatus.monitor.error && <Typography sx={{ fontSize: '11px', color: 'red', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Failed to update</Typography>}
                    {switchStatus.monitor.status && <Typography sx={{ fontSize: '11px', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Updating...</Typography>}
                  </Grid>
                  <Grid item xs={12} sx={{ ...settingsOptionStyle, display: 'flex', alignItems: 'center' }}>
                    <NotificationsNoneOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '14px' }}>Notification</Typography>
                    <div style={{ textAlign: 'end', width: '100%' }} >
                      <Switch
                        size='small'
                        defaultChecked={productDetails?.data?.prd_notification_enabled}
                        onChange={handleNotificationChange}
                      />
                    </div>
                  </Grid>
                  <Grid sx={{ width: '100%' }} >
                    {switchStatus.notification.error && <Typography sx={{ fontSize: '11px', color: 'red', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Failed to update</Typography>}
                    {switchStatus.notification.status && <Typography sx={{ fontSize: '11px', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Updating...</Typography>}
                  </Grid>
                </Grid>
              </Popover>
              <IconButton sx={iconStyle}
                onClick={() => openDialog('openGrpah')} >
                <EqualizerOutlinedIcon />
              </IconButton>
              <IconButton sx={{ ...iconStyle, background: filters.applied ? '#f4d35e' : 'transparent' }}
                onClick={() => {
                  setDialogState(prevState => ({
                    ...prevState,
                    openPdtFilter: { show: true, data: competitorsData }
                  }));
                  handlePopupClose();
                }}
              >
                <TuneOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid pt={2} item xs={12} container >
            <Grid container item xs={12} sx={{ position: 'sticky', top: 0, zIndex: 9, pb: 1, background: '#fff' }}>
              <Grid item xs={3} sx={{ pl: 5 }}>
                <Card sx={{ backgroundColor: '#fdf8e5', height: '300px', borderRadius: '11px 11px 0 0', boxShadow: 'none' }}>
                  {productDetails.loading ?
                    <Grid sx={{ display: 'flex', height: '100%' }}><Typography sx={{ m: 'auto' }}>Loading... Please wait</Typography></Grid> :
                    productDetails.error || !productDetails.data ? <Grid sx={{ display: 'flex', height: '100%' }}>
                      <Typography sx={{ m: 'auto' }}>Failed to fetch products details</Typography></Grid> :
                      <Grid item container xs={12} sx={{ p: '15px' }} >
                        <Grid item xs={3} >
                          <img alt='img' src={productDetails.data?.prd_medium_image ? productDetails.data?.prd_medium_image : '/assets/no_image.png'} style={{ maxWidth: '70px', maxHeight: '70px', height: 'fit-content', width: '100%', objectFit: 'contain', borderRadius: '11px 11px 0px 0px' }} />
                        </Grid>
                        <Grid item xs={9} sx={{ pl: '20px' }} >
                          <Typography sx={{ fontWeight: 'bold', mb: 1 }} >{currSymb} {priceDetails?.prd_sales_price}</Typography>
                          <Chip label='OWN PRODUCT' color='primary' size='small' />
                        </Grid>
                        <Grid item xs={12} >
                          <Typography sx={{ fontWeight: 'bold', fontSize: '13px', mt: '15px' }}>{productDetails.data?.prd_name}</Typography>
                        </Grid>
                        <Grid item xs={12} >
                          <Typography sx={{ fontSize: '12px', color: '#5A5A5A', mt: '5px' }}>{priceDetails?.seller?.seller_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: '30px' }} >
                          <Chip size='small' sx={{ borderRadius: 1, p: 1 }}
                            label={`PPU: ${priceDetails?.prd_sales_price / productDetails.data?.prd_no_of_pieces || 0}`} />
                        </Grid>
                      </Grid>}
                </Card>
              </Grid>
              <Grid item container xs={9} pr={5} >
                <Grid ml={4} sx={{ width: '100%' }} >{productDetails.loading ?
                  <Grid sx={{ display: 'flex', height: '100%' }}><Typography sx={{ m: 'auto' }}>Loading... Please wait</Typography>
                  </Grid> : competitorsData?.length === 0 ? <Grid sx={{ display: 'flex', height: '100%' }}>
                    <Typography sx={{ m: 'auto' }}>There are no competitors for this product</Typography></Grid>
                    : <Carousel autoPlay={false} animation='slide' indicators={false} index={currentIndex}
                      onChange={(index) => setCurrentIndex(index)}
                      navButtonsProps={{ style: { display: 'none' } }}  >
                      {carasoulArray?.map((compArrs, index) => (
                        <Grid key={index} item xs={12} sx={{ display: 'flex', gap: 2 }} >
                          {compArrs?.map(competitor => (
                            <Grid key={competitor.comp_prd_id} item xs={4}>
                              <Card sx={{ border: '0.5px solid #e5e1e1', height: '300px', borderRadius: '11px 11px 0 0', boxShadow: 'none' }} >
                                <Grid item container xs={12} p={2} sx={{ display: 'flex' }} >
                                  <Grid item xs={4} style={{ height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <img alt='img' src={competitor.comp_prd_medium_image ? competitor.comp_prd_medium_image : '/assets/no_image.png'}
                                      style={{ maxWidth: '70px', maxHeight: '70px', height: 'fit-content', width: '100%', objectFit: 'contain', borderRadius: '11px 11px 0px 0px' }} />
                                  </Grid>
                                  <Grid item xs={6} pl={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                    <Typography sx={{ fontWeight: 'bold' }} >{currSymb} {competitor.comp_prd_sales_price}</Typography>
                                    <Chip size='small' sx={{ borderRadius: 1, p: 1 }} label={competitor.market_places?.market_place_name} />
                                  </Grid>
                                  <Grid item xs={2} sx={{ justifyContent: 'flex-end' }}>
                                    <IconButton style={{
                                      position: 'relative',
                                      top: '-13px',
                                      right: '-7px',
                                    }}
                                    >
                                      <CloseIcon sx={{ color: '#868685', fontSize: '20px' }} />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ height: '60px', overflowY: 'hidden' }} >
                                  <Typography sx={{ color: '#4685D8', fontWeight: 'bold', fontSize: '13px', mx: 1, mt: '15px' }}>
                                    {competitor.comp_prd_name}</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', gap: 2, my: 2, pl: 1, mt: 4 }} >
                                  <Box style={{ p: 1, background: '#ebebeb', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 13px' }}>
                                    <h6 style={{ fontWeight: 'normal', fontSize: '13px' }} >CPI: <span style={{ fontWeight: 'bold' }} >{competitor.comp_prd_index ? competitor.comp_prd_index.toFixed(2) : '-'}</span></h6>
                                  </Box>
                                  <Box style={{ p: 1, background: '#ebebeb', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 13px' }}>
                                    <h6 style={{ fontWeight: 'normal', fontSize: '13px' }} >PPU: <span style={{ fontWeight: 'bold' }} >{`${competitor.comp_prd_sales_price / competitor.comp_prd_no_of_pieces} / ${competitor.comp_prd_uom}`}</span></h6>
                                  </Box>

                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', gap: '10px', alignItems: 'center', pl: 1 }} >
                                  <Box style={{ p: 1, background: '#ebebeb', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 13px' }}>
                                    <h6 style={{ fontWeight: 'normal', fontSize: '13px' }} >Match Rate: <span style={{ fontWeight: 'bold' }} >{competitor.comp_prd_score ? Math.round(competitor.comp_prd_score) : '-'}</span></h6>
                                  </Box>
                                  <Typography onClick={(event) => handlePopupOpen('openMatchIdPopup', event, competitor.comp_prd_id)} sx={{ fontSize: '12px', color: '#4685d8', textDecoration: 'underline', cursor: 'pointer' }}>Change</Typography>
                                  <Popover
                                    sx={{
                                      mt: '15px', ml: '-116px',
                                      '& .MuiPaper-root': {
                                        p: '12px', border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A', borderRadius: '12px', boxShadow: 'none', width: '220px'
                                      },
                                    }}
                                    id={matchId} open={matchIdPopup} anchorEl={popUp.openMatchIdPopup}
                                    onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                                    <Grid item container xs={12} >
                                      <Typography mb={1} >Match Rate</Typography>
                                      <FormControl fullWidth>
                                        <Select
                                          sx={{ border: themeMode === 'dark' ? '1px solid #858584' : '1px solid #5A5A5A', '& .MuiOutlinedInput-input': { p: '8px' }, borderRadius: '10px' }}
                                          id='matchRate' label='matchRate' value={selectedMatchRate} onChange={handleChangeMatchrate}
                                          inputProps={{ name: 'matchRate' }}
                                        >
                                          {percentageValues?.map((value, index) => (
                                            <MenuItem id={`menu-item-${index}`} key={value} value={value}>{value}%</MenuItem>
                                          ))}
                                        </Select>
                                        <Button disableRipple onClick={updateCompScore} sx={{
                                          backgroundColor: '#f4d35e', textTransform: 'capitalize', color: 'black', mt: '12px', borderRadius: '10px', '&:hover': {
                                            backgroundColor: '#f4d35e',
                                          }
                                        }}
                                          variant='contained'>Update</Button>
                                      </FormControl>
                                    </Grid>
                                  </Popover>
                                </Grid>
                                <Grid item xs={12} >
                                  <Typography sx={{ fontSize: '10px', color: '#868685', m: 1, fontStyle: 'italic' }}>
                                    Last updated on: {moment(competitor.modified_date).format('MM-DD-YYYY, h:mm A')}</Typography>
                                </Grid>
                              </Card>
                            </Grid>))}
                        </Grid>))}</Carousel>
                }
                </Grid>
              </Grid>
            </Grid>
            <Grid mt={2} sx={{ display: 'flex', alignItems: 'center' }} container item xs={12} >
              <Grid item xs={3} sx={{ display: 'flex', gap: '15px' }} >
                <Typography onClick={() => handleTabClick('marketdata')} sx={{ fontSize: '15px', ml: '55px', cursor: 'pointer', color: activeTab === 'marketdata' ? '#eb7900' : '#868685', borderBottom: activeTab === 'marketdata' ? '1px solid #eb7900' : 'none' }} >Market Data</Typography>
                <Typography onClick={() => handleTabClick('insight')} sx={{ fontSize: '15px', cursor: 'pointer', color: activeTab === 'insight' ? '#eb7900' : '#868685', borderBottom: activeTab === 'insight' ? '1px solid #eb7900' : 'none' }} >Ottix Insight</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography sx={{ fontSize: '12px', pl: 4 }}>
                  {`Showing from ${(currentIndex * cardsPerPage) + 1} of ${filters.applied ? filters.competitors.length : competitorsData?.length} products`}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }}>
                <IconButton size='small' sx={{
                  border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                  opacity: currentIndex <= 0 ? 0.5 : undefined, mr: 3
                }}
                  disabled={currentIndex <= 0} onClick={handlePrev}>
                  <ArrowBackIosOutlinedIcon sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
                </IconButton>
                <IconButton size='small' sx={{
                  border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                  opacity: currentIndex >= totalPages - 1 ? 0.5 : undefined
                }}
                  disabled={currentIndex >= totalPages - 1}
                  onClick={handleNext}>
                  <ArrowForwardIosIcon sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
                </IconButton>
              </Grid>
            </Grid>
            {activeTab === 'marketdata' && (
              <>
                <Grid mt={2} px={5} item xs={12}>
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.rating} onClick={() => handleAccordionClick('rating')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header' sx={{ backgroundColor: '#f8f8f8', minHeight: '50px !important', height: '50px', borderRadius: '11px 11px 0px 0px' }} >
                      <Typography>Ratings & Feedback</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, minHeight: '250px', position: 'relative', maxHeight: rating ? 'none' : '100px', overflow: 'hidden' }} >

                      <Grid sx={accMainGrid} >
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Rating</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.prd_rating || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Rating</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.prd_rating || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >#TOTAL FEEDBACKS</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.prd_tot_reviews || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >#TOTAL FEEDBACKS</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.prd_tot_reviews || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >#NEGATIVE FEEDBACK</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].negativefeedback || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >#NEGATIVE FEEDBACK</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].negativefeedback || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <Typography sx={{ fontSize: '10px' }} >POSITIVE FEEDBACK</Typography>
                            <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >LATEST</Typography>
                          </div>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].positivefeedbackdesc || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                              <Typography sx={{ fontSize: '10px' }} >POSITIVE FEEDBACK</Typography>
                              <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >LATEST</Typography>
                            </div>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].positivefeedbackdesc || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <Typography sx={{ fontSize: '10px' }} >NEGATIVE FEEDBACK</Typography>
                            <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >LATEST</Typography>
                          </div>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].negativefeedbackdesc || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                              <Typography sx={{ fontSize: '10px' }} >NEGATIVE FEEDBACK</Typography>
                              <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >LATEST</Typography>
                            </div>
                            <Typography sx={{ fontSize: '13px', p: '4px' }} >{dummyData[0].negativefeedbackdesc || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>

                      {rating && (
                        <div sx={{ position: 'absolute', bottom: '10px', right: '10px', background: 'red' }}>
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMoreRating}>See Less</Typography>
                        </div>
                      )}
                      {!rating && dummyData[0].positivefeedbackdesc && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: 'white', }}>
                          <Typography sx={{ color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMoreRating}>See More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid mt={2} px={5} item xs={12}>
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.description} onClick={() => handleAccordionClick('description')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header' sx={{ backgroundColor: '#f8f8f8', minHeight: '50px !important', height: '50px', borderRadius: '11px 11px 0px 0px' }} >
                      <Typography>Descriptions & Keywords</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, minHeight: '300px', position: 'relative', maxHeight: descSeeMore ? 'none' : '100px', overflow: 'hidden' }} >

                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Descriptions</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >
                            {productDetails.data?.prd_desc ? productDetails.data?.prd_desc : 'No description is available'}
                          </Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Descriptions</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >
                              {productDetails.data?.prd_desc ? productDetails.data?.prd_desc : 'No description is available'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >WORD COUNT</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].wordCount || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >WORD COUNT</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].wordCount || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >KEYWORDS</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].keywords || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >KEYWORDS</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].keywords || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >IMPORTANT KEYWORDS</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].impKeywords || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >IMPORTANT KEYWORDS</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].impKeywords || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >KEYWORD DIFFICULTY</Typography>
                          <div style={{ display: 'flex' }}>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].keywordDificultyPer}</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px', ml: '12px', color: '#e63946', fontWeight: 'bold' }} >{dummyData[0].keywordDificultyLabel || '--'}</Typography>
                          </div>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >KEYWORD DIFFICULTY</Typography>
                            <div style={{ display: 'flex' }}>
                              <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].keywordDificultyPer}</Typography>
                              <Typography sx={{ fontSize: '13px', pt: '4px', ml: '12px', color: '#e63946', fontWeight: 'bold' }} >{dummyData[0].keywordDificultyLabel || '--'}</Typography>
                            </div>
                          </Grid>
                        ))}
                      </Grid>

                      {descSeeMore && (
                        <div sx={{ position: 'absolute', bottom: '10px', right: '10px', background: 'red' }}>
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMoreDesc}>See Less</Typography>
                        </div>
                      )}
                      {!descSeeMore && dummyData[0].positivefeedbackdesc && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: 'white' }}>
                          <Typography sx={{ color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMoreDesc}>See More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid mt={2} px={5} item xs={12}>
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.promotion} onClick={() => handleAccordionClick('promotion')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header' sx={{ backgroundColor: '#f8f8f8', minHeight: '50px !important', height: '50px', borderRadius: '11px 11px 0px 0px' }} >
                      <Typography>Promotions & Sales Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: '0px', minHeight: '270px', position: 'relative', maxHeight: promotionSeeMore ? 'none' : '100px', overflow: 'hidden' }} >

                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Descriptions</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].promoDesc || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Descriptions</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].promoDesc || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT TAGS</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtTag || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT TAGS</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtTag || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT STOCK AVAILABILITY</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].stockAvailable || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT STOCK AVAILABILITY</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].stockAvailable || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT DELIVERY TIME</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtDeliverytime || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT DELIVERY TIME</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtDeliverytime || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT AVERAGE DELIVERY CHARGES</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtDeliveryCharge || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT AVERAGE DELIVERY CHARGES</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtDeliveryCharge || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT SALES</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtslaes || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT SALES</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{dummyData[0].pdtslaes || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>



                      {promotionSeeMore && (
                        <div sx={{ position: 'absolute', bottom: '10px', right: '10px', background: 'red' }}>
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMorePromotion}>See Less</Typography>
                        </div>
                      )}
                      {!promotionSeeMore && dummyData[0].positivefeedbackdesc && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: 'white' }}>
                          <Typography sx={{ color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMorePromotion}>See More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid my={2} px={5} item xs={12} >
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.product} onClick={() => handleAccordionClick('product')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header' sx={{ backgroundColor: '#f8f8f8', minHeight: '50px !important', height: '50px', borderRadius: '11px 11px 0px 0px' }} >
                      <Typography>Product Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: '0px', minHeight: '270px', position: 'relative', maxHeight: productSeeMore ? 'none' : '100px', overflow: 'hidden' }} >


                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }}>DEPARTMENT</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }}>{productDetails.data?.dept.dept_name || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>DEPARTMENT</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }}>{competitor?.dept?.dept_name || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }}>CATEGORY</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.category.category_name || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>CATEGORY</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{competitor?.category?.category_name || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT BRAND</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.prd_brand || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT BRAND</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{competitor?.comp_prd_brand || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }}>PRODUCT SPECIFICATION</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }}>{productDetails.data?.prd_spec || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>PRODUCT SPECIFICATION</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }}>{competitor?.comp_prd_spec || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT SIZE</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.prd_size || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT SIZE</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{competitor?.comp_prd_size || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }} >PRODUCT ASIN</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px' }} >{productDetails.data?.prd_asin || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >PRODUCT ASIN</Typography>
                            <Typography sx={{ fontSize: '13px', pt: '4px' }} >{competitor?.comp_prd_asin || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>


                      {productSeeMore && (
                        <div sx={{ position: 'absolute', bottom: '10px', right: '10px', background: 'red' }}>
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMoreProduct}>See Less</Typography>
                        </div>
                      )}
                      {!productSeeMore && dummyData[0].positivefeedbackdesc && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: 'white' }}>
                          <Typography sx={{ color: '#559eff', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMoreProduct}>See More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            )}
            {activeTab === 'insight' && (
              <Typography sx={{ p: 7 }} >No data available.</Typography>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Footer />
    </>
  )
}

export default ProductDetails