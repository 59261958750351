import axios from "axios";
import { paths } from "../../Api/paths";
import { REFRESH_COMPETITOR,REFRESH_COMPETITOR_FAILED,REFRESH_COMPETITOR_SUCCESSFULL,RESET_REFRESH_COMPETITOR } from "../constants/refreshCompetitor.constants";

const refreshCompetitor = () => ({
  type: REFRESH_COMPETITOR,
});

const refreshCompetitorSuccess = (data) => ({
  type: REFRESH_COMPETITOR_SUCCESSFULL,
  payload: data,
});

const refreshCompetitorFailure = (error) => ({
  type: REFRESH_COMPETITOR_FAILED,
  payload: error,
});

export const resetRefreshCompetitor = () => ({
  type: RESET_REFRESH_COMPETITOR,
});

export const refreshSingleCompetitor = (token,queryString) => async (dispatch) => {
  try {
    dispatch(refreshCompetitor());
    const response = await axios.post(
      `${process.env.REACT_APP_API_LINK}${paths.refreshCompetitors}${queryString}`,
      {},
      { headers: { Authorization: `bearer ${token}` } }
    );
    if (response.data?.Message) {
      dispatch(refreshCompetitorFailure(response.data.Message));
    } else {
      dispatch(refreshCompetitorSuccess(response.data));
    }
  } catch (error) {
    dispatch(refreshCompetitorFailure(error));
  }
};
