import { Box, Button, Dialog, DialogContent, Grid, InputBase, MenuItem, Select, Switch, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { addNewUser, resetAddUser } from '../../redux/actions/addUser.actions';
import { fetchUsers } from '../../redux/actions/users.actions';
import { editSubUser, resetEditUser } from '../../redux/actions/editUser.actons';
import { resetSendLink, sendPasswordResetLink } from '../../redux/actions/sendPasswordLink.actions';

const UserActionsDialog = ({ dialogState, setDialogState }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const addUser = useSelector(state => state.addUser);
    const editUser = useSelector(state => state.editUser);
    const linkStatus = useSelector(state => state.sendPasswordLink);
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [serverMsg, setServerMsg] = useState('');

    const userRoles = [{ role_id: 4, role_name: 'client admin' },
    { role_id: 5, role_name: 'client user' }]

    const adminPermissions = ['View all products', 'Edit all products', 'Schedule all products', 'Add admins and users', 'Edit admins and users'];
    const userPermissions = ['View personal products', 'Edit personal products', 'Schedule personal products']

    const initialValues = {
        first_name: dialogState.data?.first_name || '',
        last_name: dialogState.data?.last_name || '',
        email: dialogState.data?.email || '',
        phone_number: dialogState.data?.phone_number || '',
        role_id: dialogState.data?.roles[0]?.role?.role_id || '',
        status: dialogState.data?.status || true,
        profile_picture: '',
        remarks: '',
        flag: true,
        time_zone: '',
        ui_design: 'UI_1',
    }

    const AddSubuserSchema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email address').required('Email address is required'),
        phone_number: Yup.string().min(11, 'Invalid phone number').required('Phone number is required'),
        role_id: Yup.number().typeError('Invalid role id').required('Role is required'),
        status: Yup.boolean(),
        remarks: Yup.string(),
        flag: Yup.boolean(),
        time_zone: Yup.string(),
        ui_design: Yup.string()
    })

    const sendLink = () => {
        dispatch(sendPasswordResetLink(token, dialogState.data?.user_id));
    }

    const handleClose = () => {
        setDialogState({ ...dialogState, addNewSubUser: false, editSubUser: false, data: null })
        dispatch(resetSendLink());
        dispatch(resetAddUser());
        dispatch(resetEditUser());
    };

    const handleSubmit = (values) => {
        if (dialogState.addNewSubUser) {
            setSubmitting(true);
            setServerMsg('')
            dispatch(addNewUser(token, values));
        } else if (dialogState.editSubUser) {
            setServerMsg('')
            setSubmitting(true);
            dispatch(editSubUser(token, values, dialogState.data?.user_id));
        }
    }

    useEffect(() => {
        if (addUser.data) {
            handleClose();
            setSubmitting(false);
            dispatch(resetAddUser());
            dispatch(fetchUsers(token));
        } else if (addUser.error?.Message?.includes('tbl_user_email_key')) {
            setServerMsg('Email already exists');
            setSubmitting(false);
        } else if (addUser.error?.Message === 'No of Users Limit Exceeded') {
            setServerMsg(addUser.error?.Message);
            setSubmitting(false);
        } else if (addUser.error) {
            setServerMsg('Failed to create user');
            setSubmitting(false);
        }
    }, [addUser]) //eslint-disable-line

    useEffect(() => {
        if (editUser.data) {
            handleClose();
            setSubmitting(false);
            dispatch(resetEditUser());
            dispatch(fetchUsers(token));
        } else if (editUser.error?.Message?.includes('tbl_user_email_key')) {
            setServerMsg('Email already exists');
            setSubmitting(false);
        } else if (editUser.error) {
            setServerMsg('Failed to edit user');
            setSubmitting(false);
        }
    }, [editUser]) //eslint-disable-line

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            border: '1px solid #ced4da',
            fontSize: 11,
            width: '100%',
            padding: '9px 12px',
            textTransform: 'capitalize',
            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            transition: theme.transitions?.create([
                'border-color',
                'background-color',
            ]),
            fontFamily: 'Inter',
            '&:focus': {
                borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            },
        }
    }));

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    const selectStyle = {
        width: '100%', mt: 1, border: '1px solid #ced4da',
        '& .MuiSelect-icon': fontColor
    }

    return (
        <Dialog
            open={dialogState.addNewSubUser || dialogState.editSubUser}
            onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}>
            <DialogContent sx={fontColor} className={`custom-scrollbar-${themeMode}`}>
                {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Submitting...</Typography> :
                    <Formik enableReinitialize initialValues={initialValues}
                        validationSchema={AddSubuserSchema} onSubmit={(values) => handleSubmit(values)}>
                        {({ errors, setFieldValue, values }) => (
                            <Form>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                        {dialogState.addNewSubUser ? 'Create new sub user' : 'Edit sub user'}</Typography>
                                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Box sx={{ width: '50%', mr: 1 }}>
                                        <Typography sx={{ fontSize: '12px', mt: 2 }}>First name</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='first_name'
                                            value={values.first_name}
                                            onChange={(e) => setFieldValue('first_name', e.target.value)}
                                            placeholder='Enter first name'
                                            autoComplete='off'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                        <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.first_name}</Typography>
                                    </Box>
                                    <Box sx={{ width: '50%', ml: 1 }}>
                                        <Typography sx={{ fontSize: '12px', mt: 2 }}>Last name</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='last_name'
                                            value={values.last_name}
                                            onChange={(e) => setFieldValue('last_name', e.target.value)}
                                            placeholder='Enter last name'
                                            autoComplete='off'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                        <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.last_name}</Typography>
                                    </Box>
                                </Grid>
                                <Typography sx={{ fontSize: '12px', mt: 2 }}>Email address</Typography>
                                <Field
                                    component={BootstrapInput}
                                    sx={{ width: '100%', mt: 1 }}
                                    id='email'
                                    value={values.email}
                                    onChange={(e) => setFieldValue('email', e.target.value)}
                                    placeholder='Enter email address'
                                    autoComplete='off'
                                    inputProps={{
                                        style: { textTransform: 'none' }
                                    }}
                                />
                                <Typography sx={{ color: 'red', fontSize: '12px' }}>{errors.email}</Typography>
                                <Typography sx={{ fontSize: '12px', mt: 2 }}>Phone number</Typography>
                                <PhoneInput
                                    country={'in'}
                                    style={{ width: '100%' }}
                                    dropdownClass={`custom-scrollbar-${themeMode}`}
                                    inputStyle={{ width: '100%', ...fontColor, backgroundColor: 'transparent' }}
                                    dropdownStyle={{ backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D' }}
                                    id='phone_number'
                                    value={values.phone_number}
                                    onChange={(value) => setFieldValue('phone_number', value)}
                                    placeholder='Enter phone number'
                                    inputProps={{ required: true }}
                                />
                                <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.phone_number}</Typography>
                                <Typography sx={{ fontSize: '12px', mt: 2 }}>Role</Typography>
                                <Select
                                    size='small'
                                    variant='outlined'
                                    name='role_id'
                                    id='role_id'
                                    sx={selectStyle}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                ...fontColor,
                                                bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
                                            }
                                        }
                                    }}
                                    fullWidth
                                    value={values.role_id}
                                    onChange={(e) => setFieldValue('role_id', e.target.value)}
                                    renderValue={() => <Typography sx={{ ...fontColor, fontSize: '12px' }}>{values.role_id === 4 ? 'client admin' : 'client user'}</Typography>} >
                                    {userRoles.map((role) => {
                                        return (
                                            <MenuItem key={role.role_id} value={role.role_id} >
                                                <Typography sx={{ fontSize: '11px' }}>{role.role_name}</Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.role_id}</Typography>
                                {values.role_id && <Grid sx={{ p: 1, mt: 1, borderRadius: 1, backgroundColor: themeMode === 'light' ? '#FDF8E5' : '#1A222D' }}>
                                    <Typography sx={{ fontSize: '12px', mb: 1 }}>{`${values.role_id === 4 ? 'client admin' : 'client user'} permissions`}</Typography>
                                    {values.role_id === 4 ? <Box>
                                        {adminPermissions.map((perm, index) => <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LabelImportantIcon color='primary' />
                                            <Typography sx={{ fontSize: '11px' }}>{perm}</Typography>
                                        </Box>)}
                                    </Box> :
                                        <Box>
                                            {userPermissions.map((perm, index) => <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <LabelImportantIcon color='primary' />
                                                <Typography sx={{ fontSize: '11px' }}>{perm}</Typography>
                                            </Box>)}
                                        </Box>}
                                </Grid>}
                                {dialogState.editSubUser && <>
                                    <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px' }}>Request reset password</Typography>
                                        <Button onClick={sendLink} sx={{ textTransform: 'none' }}>Send</Button>
                                    </Box>
                                    {linkStatus.loading ? <Typography sx={{ fontSize: '11px', m: 1, mt: 0 }}>Sending the link to reset password</Typography> :
                                        linkStatus.data ? <Typography sx={{ fontSize: '11px', color: 'green', m: 1, mt: 0 }}>Link has been sent to reset password</Typography> :
                                            linkStatus.error ? <Typography sx={{ fontSize: '11px', color: 'red', m: 1, mt: 0 }}>Failed to send reset password link</Typography> : null}
                                    <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px' }}>User status</Typography>
                                        <Switch id='status' checked={values.status}
                                            onChange={(e) => setFieldValue('status', e.target.checked)} />
                                    </Box></>}
                                <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                                <Button disabled={Boolean(Object.values(errors).find((value) => value !== '' && value !== null))}
                                    type='submit' variant='contained' sx={{ mt: 2, width: '100%' }}>Confirm</Button>
                            </Form>)}
                    </Formik>}
            </DialogContent>
        </Dialog>
    )
}

export default UserActionsDialog;