import { Autocomplete, Button, Dialog, DialogContent, Grid, InputBase, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { fetchDepartments } from '../../redux/actions/departments.actions';
import { fetchCategories } from '../../redux/actions/categories.actions';
import AddCategoryDialog from '../AddCategoryDialog';
import AddDepartmentDialog from '../AddDepartmentDialog';
import { editProductDetails, resetEditProduct } from '../../redux/actions/editProduct.actions';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';

const EditProductDialog = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const categories = useSelector(state => state.categories.data);
  const departments = useSelector(state => state.departments.data);
  const editProduct = useSelector(state => state.editProduct);
  const dispatch = useDispatch();
  const newCategory = { category_id: 'new', category_name: 'Add new category' };
  const newDepartment = { dept_id: 'new', dept_name: 'Add new department' };

  const [dialogIsOpen, setDialogIsOpen] = useState({ category: false, department: false });
  const [submitting, setSubmitting] = useState(false);
  const [serverMsg, setServerMsg] = useState('');

  const initialValues = {
    prd_name: dialogState.data?.prd_name || '',
    prd_desc: dialogState.data?.prd_desc || '',
    category_id: dialogState.data?.category?.category_id || '',
    dept_id: dialogState.data?.dept?.dept_id || '',
    prd_medium_image: dialogState.data?.prd_medium_image || '',
    prd_spec: dialogState.data?.prd_spec || '',
    prd_brand: dialogState.data?.prd_brand || '',
    prd_url: dialogState.data?.prd_url || '',
    prd_upc: dialogState.data?.prd_upc || '',
    prd_no_of_pieces: dialogState.data?.prd_no_of_pieces || '',
    prd_asin: dialogState.data?.prd_asin || '',
    loc_id: dialogState.data?.locations[0]?.loc_id || '',
    prd_uom: dialogState.data?.prd_uom || '',
    prd_mrp: dialogState.data?.locations[0]?.prd_mrp || '',
    prd_size: dialogState.data?.prd_size || '',
    prd_sales_price: dialogState.data?.locations[0]?.prd_sales_price || '',
    keyword: dialogState.data?.keyword || '',
    prd_is_active: dialogState.data?.prd_is_active || true,
    prd_is_crawl_enabled: dialogState.data?.prd_is_crawl_enabled || true,
    prd_is_match_enabled: dialogState.data?.prd_is_match_enabled || true,
    prd_matched: dialogState.data?.prd_matched || 0,
    prd_norm_price: dialogState.data?.locations[0]?.prd_norm_price || '',
    prd_notification_enabled: dialogState.data?.prd_notification_enabled || false,
    prd_offer: dialogState.data?.locations[0]?.prd_offer || '',
    prd_qa_approved: dialogState.data?.prd_qa_approved || false,
    prd_rating: dialogState.data?.prd_rating || 0,
    prd_small_image: dialogState.data?.prd_small_image || '',
    prd_tot_reviews: dialogState.data?.prd_tot_reviews || 0,
    prd_variants: dialogState.data?.prd_variants || '',
    seller_id: dialogState.data?.locations[0]?.seller_id || '',
    user_id: dialogState.data?.user_id || ''
  }

  const EditProductSchema = Yup.object().shape({
    prd_name: Yup.string().required('Product name is required'),
    prd_desc: Yup.string(),
    category_id: Yup.string(),
    dept_id: Yup.string(),
    prd_medium_image: Yup.string(),
    prd_spec: Yup.string(),
    prd_brand: Yup.string(),
    prd_url: Yup.string(),
    prd_upc: Yup.string(),
    prd_no_of_pieces: Yup.number()
      .typeError('Number of pieces must be a valid number')
      .required('Number of pieces is required')
      .positive('Number of pieces must be positive'),
    prd_asin: Yup.string(),
    loc_id: Yup.string().required('Location is required'),
    prd_uom: Yup.string(),
    prd_mrp: Yup.number().typeError('MRP must be a number'),
    prd_size: Yup.number().required('Size is required').typeError('Size must be a number'),
    prd_sales_price: Yup.number().when(['prd_url', 'prd_mrp'], {
      is: (prd_url, prd_mrp) => prd_url || prd_mrp,
      then: () => Yup.number().typeError('Sale Price must be a number'),
      otherwise: () => Yup.number().typeError('Sale Price must be a number').required('If you have not provided URL, either MRP or Sales price is required'),
    }),
  })

  const handleOpenDialog = (field) => {
    const dialogState = { category: false, department: false };
    dialogState[field] = true;
    setDialogIsOpen(dialogState);
  };

  const handleClose = () => {
    setDialogIsOpen({ category: false, department: false })
  }

  const handleCloseEditProduct = () => {
    setDialogState({ ...dialogState, editProduct: false, data: null })
  };

  const handleSubmit = (values) => {
    setSubmitting(true);
    dispatch(editProductDetails(dialogState.data?.prd_id, values, token))
  }

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories(token));
      dispatch(fetchDepartments(token));
      dispatch(getAllProductLocations(token));
    }
  }, [token]) //eslint-disable-line

  useEffect(() => {
    if (editProduct.data) {
      handleCloseEditProduct();
      setSubmitting(false);
      dispatch(resetEditProduct());
    } else if (editProduct.error) {
      setServerMsg('Failed to edit product');
      setSubmitting(false);
    }
  }, [editProduct]) //eslint-disable-line

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '9px 12px',
      textTransform: 'capitalize',
      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      transition: theme.transitions?.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    }
  }));

  const dropDownStyle = {
    '& .MuiAutocomplete-inputRoot': {
      fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3',
      border: `1px solid ${themeMode === 'light' ? '#ced4da' : '#E4E3E3'}`
    }, borderRadius: 1, '& .MuiAutocomplete-popupIndicator': { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' },
    '& .MuiAutocomplete-clearIndicator': { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  }

  return (
    <>
      {dialogIsOpen.category && <AddCategoryDialog cateDialog={dialogIsOpen.category} handleClose={handleClose} />}
      {dialogIsOpen.department && <AddDepartmentDialog deptDialog={dialogIsOpen.department} handleClose={handleClose} />}
      <Dialog
        open={dialogState.editProduct}
        onClose={handleCloseEditProduct}
        maxWidth='md'
        PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}
      >
        <DialogContent className={`custom-scrollbar-${themeMode}`}>
          {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Submitting...</Typography> :
            <Formik enableReinitialize initialValues={initialValues}
              validationSchema={EditProductSchema} onSubmit={(values) => handleSubmit(values)}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Grid sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Edit Product</Typography>
                    <CloseIcon onClick={handleCloseEditProduct} sx={{ cursor: 'pointer' }} />
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Product name</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_name'
                        value={values.prd_name}
                        onChange={(e) => setFieldValue('prd_name', e.target.value)}
                        placeholder='Enter Product Name'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                      <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                        {errors.prd_name}</Typography>
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Product description</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_desc'
                        value={values.prd_desc}
                        onChange={(e) => setFieldValue('prd_desc', e.target.value)}
                        placeholder='Enter Product Description'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '11px', mb: '5px' }}>Category</Typography>
                      <Autocomplete
                        id='category_id'
                        size='small'
                        defaultValue={dialogState.data?.category || null}
                        options={categories ? [newCategory, ...categories] : [newCategory]}
                        getOptionLabel={option => option.category_name}
                        onChange={(e, value) => {
                          if (value && value.category_id === 'new') {
                            handleOpenDialog('category');
                            setFieldValue('category_id', '');
                          } else {
                            setFieldValue('category_id', value ? value.category_id : '');
                          }
                        }}
                        PaperComponent={({ children }) => (
                          <Paper sx={{
                            fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D',
                            '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                          }}>{children}</Paper>
                        )}
                        sx={dropDownStyle}
                        renderInput={(params) =>
                          <TextField {...params} variant='outlined' placeholder='Select Category' />}
                        isOptionEqualToValue={(option, value) => option.category_id === value.category_id}
                      />
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '11px', mb: '5px' }}>Department</Typography>
                      <Autocomplete
                        id='dept_id'
                        size='small'
                        defaultValue={dialogState.data?.dept || ''}
                        options={departments ? [newDepartment, ...departments] : [newDepartment]}
                        getOptionLabel={option => option.dept_name}
                        onChange={(e, value) => {
                          if (value && value.dept_id === 'new') {
                            handleOpenDialog('department');
                            setFieldValue('dept_id', '');
                          } else {
                            setFieldValue('dept_id', value ? value.dept_id : '');
                          }
                        }}
                        PaperComponent={({ children }) => (
                          <Paper sx={{
                            fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D',
                            '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                          }}>{children}</Paper>
                        )}
                        sx={dropDownStyle}
                        renderInput={(params) =>
                          <TextField {...params} variant='outlined' placeholder='Select Department' />}
                        isOptionEqualToValue={(option, value) => option.dept_id === value.dept_id}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Product Image</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_medium_image'
                        value={values.prd_medium_image}
                        onChange={(e) => setFieldValue('prd_medium_image', e.target.value)}
                        placeholder='Enter Product Image'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>SPEC</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_spec'
                        value={values.prd_spec}
                        onChange={(e) => setFieldValue('prd_spec', e.target.value)}
                        placeholder='Enter SPEC'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Brand</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_brand'
                        value={values.prd_brand}
                        onChange={(e) => setFieldValue('prd_brand', e.target.value)}
                        placeholder='Enter Brand'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>URL</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_url'
                        value={values.prd_url}
                        onChange={(e) => setFieldValue('prd_url', e.target.value)}
                        placeholder='Enter URL'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>UPC</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_upc'
                        value={values.prd_upc}
                        onChange={(e) => setFieldValue('prd_upc', e.target.value)}
                        placeholder='Enter UPC'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Number of pieces</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_no_of_pieces'
                        value={values.prd_no_of_pieces}
                        onChange={(e) => setFieldValue('prd_no_of_pieces', e.target.value)}
                        placeholder='Enter Number of pieces'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                      <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                        {errors.prd_no_of_pieces}</Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>ASIN</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_asin'
                        value={values.prd_asin}
                        onChange={(e) => setFieldValue('prd_asin', e.target.value)}
                        placeholder='Enter ASIN'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Location</Typography>
                      <Field
                        disabled
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='loc_id'
                        value={values.loc_id || ''}
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Unit of Measurement</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_uom'
                        value={values.prd_uom}
                        onChange={(e) => setFieldValue('prd_uom', e.target.value)}
                        placeholder='Enter Unit of measurement'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>MRP</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_mrp'
                        value={values.prd_mrp}
                        onChange={(e) => setFieldValue('prd_mrp', e.target.value)}
                        placeholder='Enter MRP'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                      <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                        {errors.prd_mrp}</Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Size</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_size'
                        value={values.prd_size}
                        onChange={(e) => setFieldValue('prd_size', e.target.value)}
                        placeholder='Enter Size'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                      <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                        {errors.prd_size}</Typography>
                    </Grid>
                    <Grid sx={{ width: '50%', p: 1 }}>
                      <Typography sx={{ fontSize: '12px' }}>Sale Price</Typography>
                      <Field
                        component={BootstrapInput}
                        sx={{ width: '100%', mt: 1 }}
                        id='prd_sales_price'
                        value={values.prd_sales_price}
                        onChange={(e) => setFieldValue('prd_sales_price', e.target.value)}
                        placeholder='Enter Sale Price'
                        inputProps={{
                          style: { textTransform: 'none' }
                        }}
                      />
                      <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                        {errors.prd_sales_price}</Typography>
                    </Grid>
                  </Grid>
                  <Typography sx={{ ml: 'auto', fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                  <Button type='submit' variant='contained' sx={{ width: '50%', ml: '25%', mt: 1, textTransform: 'none' }}
                    disabled={Boolean(Object.values(errors).find((value) => value !== '' && value !== null))} >Update</Button>
                </Form>)}
            </Formik>}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditProductDialog