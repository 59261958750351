import axios from "axios";
import { RESET_ADD_BULK_PRODUCTS, ADD_BULK_PRODUCTS, ADD_BULK_PRODUCTS_SUCCESSFULL, ADD_BULK_PRODUCTS_FAILED } from "../constants/addBulkProducts.constants";
import { paths } from "../../Api/paths";

const handleBulkProduct = () => ({
    type: ADD_BULK_PRODUCTS
});

const bulkProductSuccessful = (data) => ({
    type: ADD_BULK_PRODUCTS_SUCCESSFULL,
    payload: data
});

const bulkProductFailed = (error) => ({
    type: ADD_BULK_PRODUCTS_FAILED,
    payload: error
});

export const resetAddBulkProducts = () => ({
    type: RESET_ADD_BULK_PRODUCTS
});

export const addBulkProducts = (values, token) => async (dispatch) => {
    try{
        let formData = new FormData();
        formData.append('file', values[0]);
        dispatch(handleBulkProduct());
        const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.addBulkProduct}`,
        formData, { headers: { Authorization: `bearer ${token}` } })
        dispatch(bulkProductSuccessful(response.data));
    }catch(error){
        dispatch(bulkProductFailed(error));
    }
}