import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_GROUPS, FETCH_GROUPS_FAILED, FETCH_GROUPS_SUCCESSFULL } from "../constants/groups.constants";

const getGroups = () => ({
    type: FETCH_GROUPS
});

const getGroupsSuccess = (data) => ({
    type: FETCH_GROUPS_SUCCESSFULL,
    payload: data
});

const getGroupsFailure = (error) => ({
    type: FETCH_GROUPS_FAILED,
    payload: error
});

export const fetchGroups = (token) => async (dispatch) => {
    try {
      dispatch(getGroups());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.groups}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getGroupsSuccess(response.data));
    } catch (error) {
      dispatch(getGroupsFailure(error));
    }
};