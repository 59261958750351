import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Grid, InputBase, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Formik,Form, Field } from 'formik';
import * as Yup from 'yup';
import { addNewSettingsGroup, resetAddSettingsGroup } from '../../redux/actions/addSettingsGroup.actions';
import { fetchSettingsGroup } from '../../redux/actions/getSettingsGroup.actions';
import { handleEditGroupSettings, reseteditGroupSettings } from '../../redux/actions/editGroupSettings.actions';

const GroupActionDialog = ({ dialogState, setDialogState }) => {
  
  const dispatch = useDispatch()
  const themeMode = useSelector((state) => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const addSettingsGroupDetails = useSelector(state => state.addSettingsGroupDetails);
  const editGroupSettingsDetails = useSelector(state => state.editGroupSettingsDetails);

  const [submitting, setSubmitting] = useState(false);
  const [serverMsg, setServerMsg] = useState('');
  
  const initialValues = {
    group_name: dialogState?.data?.group_name || '',
    group_desc: dialogState?.data?.group_desc || ''
  }
  const AddSubuserSchema = Yup.object().shape({
    group_name: Yup.string().required('Group name is required'),
    group_desc: Yup.string().required('Group desc is required'),
})

  const handleClose = () => {
    setDialogState((prev) => ({
      ...prev,
      addNewGroup: false,
      editDetails: false,
      data: null
    }));
    dispatch(resetAddSettingsGroup());
    dispatch(reseteditGroupSettings());
    setSubmitting(false)
    setServerMsg('')
  };

  const handleSubmit = (values)=>{
    if(dialogState.addNewGroup){
      setServerMsg('')
      setSubmitting(true)
      dispatch(addNewSettingsGroup(token,values))
    }else if(dialogState.editDetails){
      setServerMsg('')
      setSubmitting(true)
      console.log("values in edit",values)
      dispatch(handleEditGroupSettings(token,values,dialogState?.data?.group_id))
    }
  }

  useEffect(() => {
    if (addSettingsGroupDetails.data) {
      handleClose();
      setSubmitting(false);
      dispatch(resetAddSettingsGroup());
      dispatch(fetchSettingsGroup(token)); 
    }else if(addSettingsGroupDetails.error){
        setServerMsg('Failed to create competitor');
        setSubmitting(false);
    }
}, [addSettingsGroupDetails]) //eslint-disable-line

// for edit group section
  useEffect(() => {
    if (editGroupSettingsDetails.data) {
      handleClose();
      setSubmitting(false);
      dispatch(reseteditGroupSettings());
      dispatch(fetchSettingsGroup(token)); 
    }else if(editGroupSettingsDetails.error){
        setServerMsg('Failed to create competitor');
        setSubmitting(false);
    }
}, [editGroupSettingsDetails]) //eslint-disable-line

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid #ced4da',
        fontSize: 11,
        width: '100%',
        padding: '9px 12px',
        textTransform: 'capitalize',
        color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
        transition: theme.transitions?.create([
            'border-color',
            'background-color',
        ]),
        fontFamily: 'Inter',
        '&:focus': {
            borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
        },
    }
  }));
  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' };

  return (
    <Dialog
      open={dialogState.addNewGroup || dialogState.editDetails}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white',width:'300px'
        },
      }}
    >
      <DialogContent sx={fontColor}>
      {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Submitting...</Typography> :
        <Formik enableReinitialize initialValues={initialValues}
          validationSchema={AddSubuserSchema} onSubmit={(values) => handleSubmit(values)}>
          {({ errors, setFieldValue, values }) => (
            <Form>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {dialogState.addNewGroup ? "Create new group" : "Edit Group" }
                  </Typography>
                  <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
              </Grid>
              <Typography sx={{ fontSize: '12px', mt: 2 }}>Group Name</Typography>
              <Field
                component={BootstrapInput}
                sx={{ width: '100%', mt: 1 }}
                id='group_name'
                value={values.group_name}
                onChange={(e) => setFieldValue('group_name', e.target.value)}
                placeholder='Enter group name'
                autoComplete='off'
                inputProps={{
                    style: { textTransform: 'none' }
                }}
              />
              <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.group_name}</Typography>
              <Typography sx={{ fontSize: '12px', mt: 2 }}>Group Description</Typography>
              <Field
                component={BootstrapInput}
                sx={{ width: '100%', mt: 1 }}
                id='group_desc'
                value={values.group_desc}
                onChange={(e) => setFieldValue('group_desc', e.target.value)}
                placeholder='Enter group description'
                autoComplete='off'
                inputProps={{
                    style: { textTransform: 'none' }
                }}
              />
              <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.group_desc}</Typography>
              <Grid>
              <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
              </Grid>
           <Grid sx={{textAlign:"end"}} >
            <Button size='small' disabled={Boolean(Object.values(errors).find((value) => value !== '' && value !== null))}
            type='submit' variant='contained' sx={{ mt: 2}}>
              {dialogState.addNewGroup ? "Create" : "Update" }
            </Button>
            </Grid>
            </Form>)}
        </Formik>
      }
      </DialogContent>
    </Dialog>
  );
};

export default GroupActionDialog;
