import { FETCH_BRANDS, FETCH_BRANDS_FAILED, FETCH_BRANDS_SUCCESSFULL } from "../constants/getBrands.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const brandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BRANDS:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_BRANDS_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case FETCH_BRANDS_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        default:
            return state;
    }
};

export default brandsReducer;