import { FETCH_TIME_ZONES, FETCH_TIME_ZONES_FAILED, FETCH_TIME_ZONES_SUCCESSFULL } from "../constants/timeZones.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const timeZonesReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case FETCH_TIME_ZONES:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_TIME_ZONES_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case FETCH_TIME_ZONES_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        default:
            return state;
    }
};

export default timeZonesReducer;