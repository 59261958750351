import { ADD_BULK_PRODUCTS, ADD_BULK_PRODUCTS_FAILED, ADD_BULK_PRODUCTS_SUCCESSFULL, RESET_ADD_BULK_PRODUCTS } from "../constants/addBulkProducts.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const addBulkProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BULK_PRODUCTS:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case ADD_BULK_PRODUCTS_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case ADD_BULK_PRODUCTS_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        case RESET_ADD_BULK_PRODUCTS:
            return {
                ...initialState
            }

        default:
            return state;
    }
}

export default addBulkProductReducer;