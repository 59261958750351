import { PRODUCT_LOCATIONS_SUCCESSFULL } from "../constants/productLocations.constants";
import { APPLY_FILTER_OPTIONS, CHANGE_LOCATION, CHANGE_PAGE_NUMBER, CHANGE_PAGE_SIZE, CHANGE_SEARCH_KEYWORD, CLEAR_SPECIFIC_FILTER } from "../constants/productString.constants";

const storedProductString = sessionStorage.getItem('productString');
const storedInitialState = JSON.parse(storedProductString);

const initialState = storedInitialState || {
  page: 1,
  page_size: 10,
  prd_mp: '',
  category_id__in: '',
  price: '',
  prd_brand__in: '',
  dept_id__in: '',
  group_id__in: '',
  prd_loc_id: '',
  comp_loc_id: '',
  search: ''
}

const productStringReducer = (state = initialState, action) => {
  switch (action.type) {

    case PRODUCT_LOCATIONS_SUCCESSFULL:
      const defaultLocation = action.payload.find(loc => loc.loc_is_default)?.loc_id;
      return {
        ...state,
        prd_loc_id: defaultLocation,
        comp_loc_id: defaultLocation
      }

    case APPLY_FILTER_OPTIONS:
      const groupString = action.payload?.group.join(',');
      const brandString = action.payload?.brand.join(',');
      const categoryString = action.payload?.category.join(',');
      const departmentString = action.payload?.department.join(',');
      return {
        ...state,
        page: 1,
        group_id__in: groupString,
        prd_brand__in: brandString,
        category_id__in: categoryString,
        dept_id__in: departmentString,
        prd_loc_id: action.payload?.prd_loc,
        comp_loc_id: action.payload?.comp_loc,
        price: action.payload?.price,
      };

    case CHANGE_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };

    case CHANGE_PAGE_SIZE:
      return {
        ...state,
        page_size: action.payload,
        page: 1
      }

    case CHANGE_SEARCH_KEYWORD:
      return {
        ...state,
        search: action.payload,
        page: 1
      }

    case CHANGE_LOCATION:
      return {
        ...state,
        page: 1,
        prd_loc_id: action.payload
      }

    case CLEAR_SPECIFIC_FILTER:
      return {
        ...state,
        page: 1,
        [action.payload]: ''
      }

    default:
      return state;
  }
};

export default productStringReducer;