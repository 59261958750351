import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, Grid, InputBase, MenuItem, Select, Typography } from '@mui/material'
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import AddmarketDialog from '../AddMarketDialog';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';
import { addNewCompetitor, resetAddCompetitor } from '../../redux/actions/addCompetitor.actions';
import { fetchMarketplaces } from '../../redux/actions/marketPlaces.actions';

const AddCompetitorDialog = ({dialogState, setDialogState}) => {

    const [submitting, setSubmitting] = useState(false);
    const [serverMsg, setServerMsg] = useState('');
    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const addCompData = useSelector(state => state.addCompetitorDetails);

    const params = useParams()
    const { product_id } = params;
    const dispatch = useDispatch()

    const marketPlaces = useSelector(state => state.marketPlaces);

    const initialValues = {
        prd_id:Number(product_id),
        comp_prd_url: '',
        market_place_id:'',
        loc_id:'',
        comp_prd_score: '100'
    }
    const AddCompetitorSchema = Yup.object().shape({
        comp_prd_url: Yup.string().required('Required').url('Must be a valid URL'),
        market_place_id: Yup.string().required('Required'),
        loc_id: Yup.string().required('Required'),
        comp_prd_score: Yup.string().required('Required')
    })

    const percentages = Array.from({ length: 41 }, (_, i) => 60 + i);

    const handleClose = ()=>{
        setDialogState((prevState)=>({
            ...prevState,
            addComp: false
        }))
        dispatch(resetAddCompetitor());
        setSubmitting(false)
        setServerMsg('')
    }

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } 

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            border: '1px solid #ced4da',
            fontSize: 11,
            width: '100%',
            padding: '9px 12px',
            textTransform: 'capitalize',
            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            transition: theme.transitions?.create([
                'border-color',
                'background-color',
            ]),
            fontFamily: 'Inter',
            '&:focus': {
                borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            },
        }
    }));
    const selectStyle = {
        width: '100%', mt: 1, border: '1px solid #ced4da',color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
        '& .MuiSelect-icon': fontColor
    }

const [open, setOpen] = useState(false);

const [isFocused, setIsFocused] = useState(false);

// Function to handle input focus
const handleFocus = () => {
  setIsFocused(true);
};

// Function to handle input blur
const handleBlur = () => {
  setIsFocused(false);
};

const handleSubmit = (values) => {
    const convertedValues = {
        ...values,
        comp_prd_score: Number(values.comp_prd_score)
    };
    if(convertedValues){
        setServerMsg('')
        setSubmitting(true)
        dispatch(addNewCompetitor(token,convertedValues))
    }
}

useEffect(() => {
    if (addCompData.data) {
        handleClose();
        setSubmitting(false);
        dispatch(resetAddCompetitor());
        dispatch(fetchProductDetails(token, product_id));
    }else if(addCompData.error){
        setServerMsg('Failed to create competitor');
        setSubmitting(false);
    }
}, [addCompData]) //eslint-disable-line

useEffect(()=> {
    dispatch(fetchMarketplaces(token));
},[]) //eslint-disable-line

  return (
    <Dialog
    open={dialogState.addComp} 
    onClose={handleClose}
    PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}>
        <DialogContent sx={fontColor} >
        {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Submitting...</Typography> :
        <Formik
        initialValues={initialValues}
        validationSchema={AddCompetitorSchema}
        onSubmit={(values) => handleSubmit(values)}
        >
            {({ errors, setFieldValue, values }) => (
            <Form>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                    Add Competitor
                </Typography>
                <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Grid>
            <Grid>
            <Typography sx={{ fontSize: '11px', mt: 2 }}>URL*</Typography>
            <Field 
            component={BootstrapInput} sx={{ width: '100%', mt: 1 }}
            id='comp_prd_url' placeholder='Enter URL' autoComplete='off'
            value={values.comp_prd_url} onChange={(e) => setFieldValue('comp_prd_url', e.target.value)}
            inputProps={{
                style: { textTransform: 'none' }
            }}
            />
            <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.comp_prd_url}</Typography>
            </Grid>
            <Grid>
            { open && <AddmarketDialog open={open} setOpen={setOpen} /> }
            <Typography sx={{ fontSize: '11px',mt: 2 }}>Market Place*</Typography>
            <Select
            size='small' variant='outlined' name='market_place_id' id='market_place_id'
            sx={selectStyle} fullWidth
            MenuProps={{
                PaperProps: {
                    sx: {
                        ...fontColor,
                        bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
                    }
                }
            }}
            value={values.market_place_id}
            onChange={(e) => setFieldValue('market_place_id', e.target.value)}
            >
            {marketPlaces?.data?.map((mktplace) => {
                return (
                    <MenuItem key={mktplace.market_place_id} value={mktplace.market_place_id} >
                        <Typography sx={{ fontSize: '11px' }}>{mktplace.market_place_name}</Typography>
                    </MenuItem>
                )
            })}
            <MenuItem value='addmarket'>
                <Typography sx={{ fontSize: '11px' }} >Add Market</Typography>
            </MenuItem>
            </Select>
            <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.market_place_id}</Typography>
            </Grid>
            <Grid>
            <Typography sx={{ fontSize: '11px',mt: 2 }}>Location*</Typography>
            <Select size='small' variant='outlined' name='loc_id' id='loc_id'
            fullWidth sx={selectStyle}
            MenuProps={{
                PaperProps: {
                    sx: {
                        ...fontColor,
                        bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
                    }
                }
            }}
            value={values.loc_id}
            onChange={(e) => setFieldValue('loc_id', e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            >
                {(marketPlaces?.data?.find(place => place.market_place_id === values.market_place_id)?.locations || []).map(location => (
                    <MenuItem key={location.loc_id} value={location.loc_id}>
                      <Typography sx={{ fontSize: '11px' }}>{location.loc_name}</Typography>
                    </MenuItem>
                ))}
            </Select>
            {
                isFocused && !values.market_place_id ? <Typography sx={{ color: 'red', fontSize: '11px' }}>
                    Select marketplace first
                </Typography> : <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.loc_id}</Typography> 
            }
            </Grid>
            <Grid>
            <Typography sx={{ fontSize: '11px',mt: 2 }}>Match Rate*</Typography>
            <Select size='small' variant='outlined' name='loc_id' id='loc_id'
            fullWidth sx={selectStyle}
            MenuProps={{
                PaperProps: {
                    sx: {
                        ...fontColor,
                        bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
                    }
                }
            }}
            value={values.comp_prd_score}
            onChange={(e) => setFieldValue('comp_prd_score', e.target.value)}
            >
                {percentages.map((percent, index) => (
          <MenuItem key={index} value={percent}>
            <Typography sx={{ fontSize: '11px' }}>{percent}%</Typography>
          </MenuItem>
        ))}
            </Select>
            <Typography sx={{ color: 'red', fontSize: '11px' }}>{errors.comp_prd_score}</Typography>
            </Grid>
            <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
            <Grid>
                <Button type='submit' variant='contained' sx={{ mt: 2, width: '100%' }}
                disabled={Boolean(Object.values(errors).find((value) => value !== '' && value !== null))}
                >Add</Button>
            </Grid>
            </Form>
        )}
        </Formik>}
        </DialogContent>
    </Dialog>
  )
}

export default AddCompetitorDialog