import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Navbar from '../../components/Navbar';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import Footer from '../../components/Footer';
import GeneralSettings from '../../components/GeneralSettings';
import NotificationSettings from '../../components/NotificationSettings';
import ManageRules from '../../components/ManageRules';
import ManageUsers from '../../components/ManageUsers';
import ManageGroups from '../../components/ManageGroups';

const Settings = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const userRole = useSelector(state => state.auth.role);
  const [ selected, setSelected ] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelected(newValue);
  }

  const tabColor = {
    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
    '&.Mui-selected': { color: '#1976d2' }, textTransform: 'none', fontWeight: 'bold'
  }

  return (
    <>
      <Navbar selected='' />
      <Grid px={20} py={3} className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Typography sx={{ fontSize: '30px', fontWeight: 'bold' }}>Settings</Typography>
        <Box>
          <Tabs value={selected} onChange={handleChangeTab}>
            <Tab label='General' sx={tabColor}/>
            <Tab label='Notifications' sx={tabColor}/>
            <Tab label='Manage Rules' sx={tabColor}/>
            <Tab label='Manage Users' sx={tabColor}/>
            <Tab label='Manage Groups' sx={tabColor}/>
          </Tabs>
        </Box>
        {selected === 0 ? <GeneralSettings />:
        selected === 1 && (userRole === 3 || userRole === 4) ? <NotificationSettings />:
        selected === 2 && (userRole === 3 || userRole === 4) ? <ManageRules />:
        selected === 3 && (userRole === 3 || userRole === 4) ? <ManageUsers />:
        selected === 4 && (userRole === 3 || userRole === 4) ? <ManageGroups />:
        <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '100px'}}>
          <Typography>You don't have permission for this option, contact admin</Typography>
          </Grid>}
      </Grid>
      <Footer />
    </>
  )
}

export default Settings