import React, { useEffect, useState } from 'react'
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { fetchSettingsGroup } from '../../redux/actions/getSettingsGroup.actions';
import ViewGroupDetails from '../ViewGroupDetails';
import GroupActionDialog from '../GroupActionDialog';

const ManageGroups = () => {

    const dispatch = useDispatch();
    const themeMode = useSelector(state => state.theme.mode);
    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    const settingsGroupDetails = useSelector(state=> state.settingsGroupDetails);
    const token = useSelector(state=> state.auth.token);
    const [ dialogState, setDialogState ] = useState({
        addNewGroup:false,
        viewDetails:false,
        editDetails:false,
        data: null
    })

    const openDialog = (type, data) => {
        const tempState = { ...dialogState, [type]: true, data: data }
        setDialogState(tempState);
    }

    useEffect(()=> {
        dispatch(fetchSettingsGroup(token));
    },[]) //eslint-disable-line

  return (
    <>
    { (dialogState.addNewGroup || dialogState.editDetails  )&& <GroupActionDialog dialogState={dialogState} setDialogState={setDialogState} /> }
    { dialogState.viewDetails && <ViewGroupDetails dialogState={dialogState} setDialogState={setDialogState} /> }
    <Grid p={2} mt={4} sx={{ backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', borderRadius: '10px' }}>
            <Grid item container sx={{display:"flex",justifyContent:"space-between"}} >
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', ml: 1 }}>Product Groups</Typography>
            <Button variant='contained' sx={{ textTransform: 'none', mx: 2 }} 
            onClick={() => openDialog('addNewGroup')}
            >+ Create new group</Button>
            </Grid>
            {settingsGroupDetails.loading ? <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Fetching the groups, please wait...</Typography>:
            settingsGroupDetails.data ? <TableContainer sx={{ mt: 3 }}>
                <Table sx={{ '& .MuiTableCell-root': fontColor }}>
                    <TableHead sx={{ backgroundColor: themeMode === 'dark' ? '#1A222D' : '#FDF8E5' }}>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Group name</TableCell>
                            <TableCell>No of products count</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {settingsGroupDetails.data?.map((grp) => {
                            return (
                                <TableRow key={grp?.group_id}>
                                    <TableCell>{grp?.group_id}</TableCell>
                                    <TableCell>{grp?.group_name}</TableCell>
                                    <TableCell sx={{pl:10}}>{grp?.product_count}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => openDialog('editDetails',grp)} >
                                          <EditNoteIcon style={fontColor} />
                                        </IconButton>
                                        <IconButton onClick={() => openDialog('viewDetails',grp)} >
                                          <RemoveRedEyeIcon style={fontColor} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>:
            <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Failed to fetch the groups data, please contact admin for more support</Typography>}
        </Grid>
    </>
  )
}

export default ManageGroups