import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, InputBase, FormControl, InputLabel, Box, Autocomplete, Paper, TextField, Dialog, DialogContent } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries } from '../../redux/actions/countries.actions';
import { addNewLocation, resetAddLocation } from '../../redux/actions/addLocation.actions';
import { fetchLocations } from '../../redux/actions/locations.actions';
import CloseIcon from '@mui/icons-material/Close';

const AddLocationDialog = ({ handleClose, locDialog, dropDownStyle, inputBoxStyle }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state=> state.auth.token);
  const countries = useSelector(state=> state.countries.data);
  const response = useSelector(state=> state.addLocation);
  const [ submitting, setSubmitting ] = useState(false)
  const [ serverError, setServereError] = useState('')
  const dispatch = useDispatch();


  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '9px 12px',
      textTransform: 'capitalize',
      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      transition: theme.transitions?.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    }
  }));

  const locationSchema = Yup.object().shape({
    country_name: Yup.string().required('Country is required'),
    state_name: Yup.string().required('State is required'),
    loc_name: Yup.string().required('Location name is required'),
    loc_zip: Yup.string().matches(/^[0-9-]*$/, 'Invalid zip code format').required('Zip code is required'),
  });

  const handleSubmit = (values) => {
    setSubmitting(true);
    dispatch(addNewLocation(values, token));
  }

  useEffect(()=> {
    if(submitting && response.success){
      handleClose();
      dispatch(fetchLocations(token));
      setSubmitting(false);
    }else if(response.error){
      setServereError('Failed to add new location')
      setSubmitting(false);
    }
  },[response]) //eslint-disable-line

  useEffect(()=> {
    dispatch(fetchCountries(token));
    dispatch(resetAddLocation());
  },[]) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

  return (
    <div>
      <Dialog
        open={locDialog}
        onClose={handleClose}
        PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white' } }}
      >
        <DialogContent sx={fontColor} >
        <Box>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6' component='h2' style={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '19px' }}>
            Add Location
          </Typography>
            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Grid>
          {submitting ? <Typography sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', mt: 4 }}>Adding a new location...</Typography>:
          <Grid item sx={{ width: '100%' }}>
            <Formik
              initialValues={{
                country_name: '', country_short_name: '', state_short_name: '', state_name: '',
                loc_zip: '', loc_name: ''
              }}
              validationSchema={locationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, setFieldValue, values }) => (
                <Form className='loginform' style={{ width: '100%' }}>
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid item xs={12} md={12} xl={12} sx={{ mr: 1 }}>
                      <Typography sx={{ fontSize: '11px', mb: '5px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }}>Country</Typography>
                      <Autocomplete
                      id='country_name'
                      size='small'
                      options={countries ? countries : []}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                          setFieldValue('country_name', value ? value.name : '');
                      }}
                      PaperComponent={({ children }) => (
                        <Paper style={{ fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D' }}>{children}</Paper>
                      )}
                      sx={dropDownStyle}
                      renderInput={(params) =>
                        <TextField {...params} variant='outlined' placeholder='Select Country' />}
                    />
                    <Typography sx={{ fontSize: '11px', mt: '14px', mb: '5px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }}>State</Typography>
                      <Autocomplete
                      id='state_name'
                      size='small'
                      options={countries && values.country_name ? (countries.find(country => country.name === values.country_name)?.states || []) : [] }
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                          setFieldValue('state_name', value ? value.name : '');
                      }}
                      PaperComponent={({ children }) => (
                        <Paper style={{ fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D' }}>{children}</Paper>
                      )}
                      sx={dropDownStyle}
                      renderInput={(params) =>
                        <TextField {...params} variant='outlined' placeholder='Select State' />}
                    />
                      <FormControl variant='standard' fullWidth >
                      <InputLabel shrink htmlFor='loc_name'
                      sx={inputBoxStyle}
                      >
                        Location name
                      </InputLabel>
                      <Field
                        component={BootstrapInput}
                        id='loc_name'
                        value={values.loc_name}
                        onChange={(e) => setFieldValue('loc_name', e.target.value)}
                        placeholder='Enter location name'
                      />
                    </FormControl>
                    <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                      <InputLabel shrink htmlFor='loc_zip'
                      sx={inputBoxStyle}
                      >
                        Zip Code
                      </InputLabel>
                      <Field
                        component={BootstrapInput}
                        id='loc_zip'
                        value={values.loc_zip}
                        onChange={(e) => setFieldValue('loc_zip', e.target.value)}
                        placeholder='Enter zip code'
                      />
                    </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl sx={{ m: 0, mt: 3, mb: 2, width: '100%', alignItems: 'center' }}>
                    <Button type='submit' sx={{ width: '70%', backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000' }}
                      >Add</Button>
                    {(errors.country_name || errors.state_name || errors.loc_name || errors.loc_zip) && (!values.country_name || !values.state_name || !values.loc_name || !values.loc_zip) &&
                      <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>All fields are required</Typography>
                    }
                    <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{serverError}</Typography>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </Grid>}
        </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddLocationDialog;
