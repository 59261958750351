import React from 'react';
import Navbar from '../../components/Navbar';
import { useSelector } from 'react-redux';
import { Grid, Paper, Typography } from '@mui/material';
import SummaryDetails from '../../components/SummaryDetails';
import LocationDetails from '../../components/LocationDetails';
import LinkedProducts from '../../components/LinkedProducts';
import PricePositionChart from '../../components/Charts/PricePositionChart';
import AssortmentChart from '../../components/Charts/AssortmentChart';
import MatchRateBar from '../../components/Charts/MatchRateBar';
import PricevarianceChart from '../../components/Charts/PricevarianceChart';
import MarketPlace from '../../components/MarketPlace';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';

const Dashboard = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const dashboard = useSelector(state => state.dashboard);

  return (
    <>
      <Navbar selected='dashboard' />
      <Grid container className={`${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        {dashboard.data ? <Grid container m={3} mb={0}>
          <Grid item xs={12} container>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={6} sx={{ display: 'flex', gap: 3, marginBottom: 3}} >
              <SummaryDetails title='Categories' color='#ff6767' count={dashboard.data?.summary?.categoryCount || 0} />
              <SummaryDetails title='Brands' color='#dac934' count={dashboard.data?.summary?.brandCount || 0} />
              <SummaryDetails title='Products' color='#559eff' count={dashboard.data?.summary?.productCount || 0} />
              <SummaryDetails title='Competitors' color='#297462' count={dashboard.data?.summary?.competitorCount || 0} />
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={6} pl={1}>
              <LocationDetails />
            </Grid>
          </Grid>
          <Grid item xs={12} container mb={3}>
            <Grid item xs={6} >
              <Paper style={{ height: '245px', display: 'flex', alignItems: 'center',
                justifyContent: 'center', background: themeMode === 'dark' ? '#232D3B' : 'white',
                color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none'}}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px' }} >
                  <Typography style={{ fontSize: '14px', textAlign: 'center' }}>
                    Linked Products
                  </Typography>
                  <LinkedProducts totalCount={dashboard.data?.summary?.productCount}
                    count={dashboard.data?.summary?.productCount} title='Total Products' color='#559eff'/>
                  {dashboard.data?.linkedProducts?.map((item, index) => {
                    const linkedTitle = Object.keys(item)[0];
                    return (
                    <LinkedProducts key={index} totalCount={dashboard.data?.summary?.productCount}
                      count={item[linkedTitle]} title={linkedTitle} color='#559eff'
                    />
                  )})}
                </Grid>
              </Paper>
            </Grid>
            <Grid item container xs={6} columnSpacing={3} pl={3}>
              <Grid item xs={6}>
                <Paper sx={{ height: '245px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none' }} >
                  <PricePositionChart />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ height: '245px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none' }} >
                  <AssortmentChart />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container mb={3}>
            <Grid item xs={6} >
              <Paper sx={{ height: '355px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none' }} >
                <MatchRateBar />
              </Paper>
            </Grid>
            <Grid item xs={6} pl={3}>
              <Paper sx={{ height: '355px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none' }} >
                <PricevarianceChart />
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} container >
            {Array.isArray(dashboard.data?.marketPlace_wise_productStats) && dashboard.data?.marketPlace_wise_productStats.map((obj, index) => {
              const tempName = Object.keys(obj)[0];
              let marketPlaceData = obj[tempName][0];
              marketPlaceData = {...marketPlaceData, name: tempName}
              return (
                <MarketPlace key={index} marketPlaceData={marketPlaceData} />
              )})}
          </Grid>
        </Grid> : <Loading height='80vh' />}
        <Footer />
      </Grid>
    </>
  )
}

export default Dashboard;
