import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_CATEGORY, ADD_CATEGORY_FAILED, ADD_CATEGORY_SUCCESSFULL, RESET_ADD_CATEGORY } from "../constants/addCategory.constants";

const addCategory = (data) => ({
    type: ADD_CATEGORY,
    payload: data
});

const addCategorySuccess = (data) => ({
    type: ADD_CATEGORY_SUCCESSFULL,
    payload: data
});

const addCategoryFailure = (error) => ({
    type: ADD_CATEGORY_FAILED,
    payload: error
});

export const resetAddActegory = () => ({
    type: RESET_ADD_CATEGORY
})

export const addNewCategory = (data, token) => async (dispatch) => {
    try {
      dispatch(addCategory(data));
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.categories}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(addCategorySuccess(response.data));
    } catch (error) {
      dispatch(addCategoryFailure(error));
    }
};