import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { resetUpdateProduct, updateProduct } from '../../redux/actions/updateProduct.actions';
import { useParams } from 'react-router-dom';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';

const EnableMatchDialog = ({dialogState, setDialogState,productData,setSwitchStatus}) => {

    const dispatch = useDispatch()
    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const updateProductDetails = useSelector(state => state.updateProductDetails);

    const params = useParams()
    const { product_id } = params;

    const [submitting, setSubmitting] = useState(false);
    const [serverMsg, setServerMsg] = useState('');

    const handleClose = ()=>{
      dispatch(resetUpdateProduct())
        setDialogState((prevState) => ({
          ...prevState,
          enableMatch: false,
        }));
        setSwitchStatus({
          monitor: { status: false, error: false },
          notification: { status: false, error: false }
        })
        setPrdArray([])
        setSubmitting(false)
        setServerMsg('')
    }
      
    const [prdMatchEnabled, setPrdMatchEnabled] = useState({});
    const [prdArray, setPrdArray] = useState([]);  
    
    useEffect(() => {
        setPrdMatchEnabled(JSON.parse(productData?.prd_is_match_enabled));
      }, [productData]);

    useEffect(() => {
      let newArray = [];
      for (let key in prdMatchEnabled) {
        newArray.push({ key: key, value: prdMatchEnabled[key] });
      }
      setPrdArray(newArray); // Update prdArray state
      // console.log(newArray);
    }, [prdMatchEnabled]);

    useEffect(() => {
        if (updateProductDetails.data) {
          handleClose();
          setSubmitting(false);
          dispatch(resetUpdateProduct());
          dispatch(fetchProductDetails(token, product_id));
        } else if (updateProductDetails.error) {
          setServerMsg('Failed to Enable Match');
          setSubmitting(false);
        }
    }, [updateProductDetails]) //eslint-disable-line

    const handleCheckboxChange = (key) => {
      // Update the prdMatchEnabled state when a checkbox is changed
      const updatedPrdMatchEnabled = { ...prdMatchEnabled };
      updatedPrdMatchEnabled[key] = !updatedPrdMatchEnabled[key];
      setPrdMatchEnabled(updatedPrdMatchEnabled);
    };

    const handleUpdate = () => {
    if (productData) {
      const selected = prdArray;
      if (selected) {
        const updatedArray = selected.reduce((acc, current) => {
          acc[current.key] = current.value;
          return acc;
        }, {});
        let newValues = {
          prd_id: productData?.prd_id,
          modified_date: productData?.modified_date || "",
          loc_id: productData?.locations?.[0].loc_id,
          prd_sales_price: productData?.locations?.[0].prd_sales_price,
          prd_mrp: productData?.locations?.[0].prd_mrp,
          prd_no_of_pieces: productData?.prd_no_of_pieces,
          prd_offer: productData?.locations?.[0]?.prd_offer || "",
          seller_id: productData?.locations?.[0]?.seller_id,
          dept_id: productData?.dept.dept_id,
          category_id: productData?.category_id,
          prd_name: productData?.prd_name || "",
          prd_desc: productData?.prd_desc || "",
          prd_size: productData?.prd_size,
          prd_uom: productData?.prd_uom || "",
          prd_url: productData?.prd_url || "",
          prd_upc: productData?.prd_upc || "",
          prd_asin: productData?.prd_asin || "",
          prd_brand: productData?.prd_brand || "",
          prd_medium_image: productData?.prd_medium_image || "",
          prd_small_image: productData?.prd_small_image || "",
          prd_variants: productData?.prd_variants || "",
          keyword: productData?.keyword || "",
          prd_is_active: productData?.prd_is_active,
          prd_spec: productData?.prd_spec || "",
          prd_is_crawl_enabled: productData?.prd_is_crawl_enabled,
          prd_rating: productData?.prd_rating || 0,
          prd_tot_reviews: productData?.prd_tot_reviews,
          prd_is_match_enabled: JSON.stringify(updatedArray),
        };
        setSubmitting(true);
        dispatch(updateProduct(token,newValues));
      }
    }
    };

  return (
    <Dialog
    open={dialogState.enableMatch}
      onClose={handleClose}
      PaperProps={{
        sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white' },
      }}
    >
        {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Enabling...</Typography> : 
      <DialogContent sx={{ width: '230px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            Enable Match
          </Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Grid>
        <Grid sx={{ mt: 2 }}>
        <FormGroup>
      {
        prdArray && prdArray?.length > 0 ? (
            prdArray?.map((item,index)=>(
                <FormControlLabel 
            key={index} value={item?.market_place_id}
            control={<Checkbox size='small' sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}
            onChange={() => handleCheckboxChange(item.key)}
            checked={prdMatchEnabled[item.key]}
            />} 
            label={<span style={{ fontSize: '13px' }}> {item.key}</span>}
            />
            ))
        ) : (
            <Typography variant='body2' sx={{p:2}} >No marketplaces available</Typography>
        )
      }
        </FormGroup>
        </Grid>
        <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
        <Grid sx={{ textAlign: 'end' }}>
            <Button size='small' variant='contained' onClick={handleUpdate}>
              Update
            </Button>
        </Grid>
      </DialogContent>
      }
    </Dialog>
  );
}

export default EnableMatchDialog