import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, InputBase, FormControl, InputLabel, Box, Dialog, DialogContent } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCategory, resetAddActegory } from '../../redux/actions/addCategory.actions';
import { fetchCategories } from '../../redux/actions/categories.actions';
import CloseIcon from '@mui/icons-material/Close';

const AddCategoryDialog = ({ handleClose, cateDialog }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state=> state.auth.token);
  const response = useSelector(state=> state.addCategory);
  const [ submitting, setSubmitting ] = useState(false)
  const [ serverError, setServereError] = useState('')
  const dispatch = useDispatch();

  const marketSchema = Yup.object().shape({
    category_name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    category_desc: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  });


  const formStyle = { width: '100%' };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    },
  }));

  const handleSubmit = (values) => {
    setSubmitting(true);
    dispatch(addNewCategory(values, token));
  }

  useEffect(()=> {
    if(submitting && response.success){
      handleClose();
      dispatch(fetchCategories(token));
      setSubmitting(false);
    }else if(response.error){
      setServereError('Failed to add new category')
      setSubmitting(false);
    }
  },[response]) //eslint-disable-line

  useEffect(()=> {
    dispatch(resetAddActegory());
  },[]) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

  return (
    <div>
      <Dialog
        open={cateDialog}
        onClose={handleClose}
        PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white' } }}
      >
        <DialogContent sx={fontColor} >
          <Box>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h6' component='h2' style={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',fontSize:"19px" }}>
                      Add Category
              </Typography>
            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Grid>
          {submitting ? <Typography sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', mt: 4 }}>Adding a new category...</Typography>:
          <Grid item>
            <Formik
              initialValues={{ category_name: '', category_desc: '', category_is_active: true }}
              validationSchema={marketSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, setFieldValue, values }) => (
                <Form className='loginform' style={{ width: '100%' }}>
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid item xs={12} md={12} xl={12} sx={{ mr: 1 }}>
                      <FormControl variant='standard' style={formStyle}>
                        <InputLabel shrink htmlFor='marketname'
                          sx={{
                            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            fontSize: '13px',
                            '&.Mui-focused': {
                              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            },
                          }}
                        >
                          Category Name
                        </InputLabel>
                        <BootstrapInput
                          value={values.category_name}
                          placeholder='Enter category name'
                          id='marketname'
                          onChange={e => setFieldValue('category_name', e.target.value)}
                        />
                        {errors.category_name && <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{errors.category_name}</Typography>}
                      </FormControl>

                      <FormControl variant='standard' sx={{ mt: 2 }} style={formStyle}>
                        <InputLabel shrink htmlFor='category_desc'
                          sx={{
                            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            fontSize: '13px',
                            '&.Mui-focused': {
                              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                            },
                          }}
                        >
                          Category Description
                        </InputLabel>
                        <BootstrapInput
                          value={values.category_desc}
                          placeholder='Enter category description'
                          id='category_desc'
                          onChange={e => setFieldValue('category_desc', e.target.value)}
                        />
                        {errors.category_desc && <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{errors.category_desc}</Typography>}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl sx={{ m: 0, mt: 3, mb: 2, width: '100%', alignItems: 'center' }}>
                    <Button sx={{ width: '70%', backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000' }}
                      type='submit'>
                      Add
                    </Button>
                    {(errors.category_name || errors.category_desc) && (!values.category_name || !values.category_desc) &&
                      <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>All fields are required</Typography>
                    }
                    <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{serverError}</Typography>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </Grid>}
        </Box>
        </DialogContent>
        
      </Dialog>
    </div>
  )
}

export default AddCategoryDialog;
