import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_BRANDS, FETCH_BRANDS_FAILED, FETCH_BRANDS_SUCCESSFULL } from "../constants/getBrands.constants";

const getBrands = () => ({
    type: FETCH_BRANDS
});

const getBrandsSuccess = (data) => ({
    type: FETCH_BRANDS_SUCCESSFULL,
    payload: data
});

const getBrandsFailure = (error) => ({
    type: FETCH_BRANDS_FAILED,
    payload: error
});

export const fetchBrands = (token) => async (dispatch) => {
    try {
      dispatch(getBrands());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.brands}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getBrandsSuccess(response.data));
    } catch (error) {
      dispatch(getBrandsFailure(error));
    }
};