import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { refreshSingleCompetitor, resetRefreshCompetitor } from '../../redux/actions/refreshCompetitor.actions';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';

const RefreshCompetitorDialog = ({dialogState, setDialogState}) => {

    const dispatch = useDispatch()
    const themeMode = useSelector(state => state.theme.mode);
    const marketPlaces = useSelector(state => state.marketPlaces);
    const token = useSelector(state => state.auth.token);
    const user_id = useSelector(state => state.auth.user_id);
    const refreshCompData = useSelector(state => state.refreshCompetitorDetails);

    const params = useParams()
    const { product_id,location_id } = params;

    const [submitting, setSubmitting] = useState(false);
    const [serverMsg, setServerMsg] = useState('');

    const handleClose = ()=>{
      setDialogState((prevState) => ({
        ...prevState,
        refresh: false,
      }));
      setSelectedValue('')
      dispatch(resetRefreshCompetitor())
      setSubmitting(false)
      setServerMsg('')
    }
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
    

    const handleRefresh = () => {
      if (selectedValue?.length > 0) {
        let queryString = `?prd_id=${product_id}&loc_id=${location_id}&marketplace_id=${selectedValue}&cuttoff_score=${100}&user_id=${user_id}`
        if (queryString) {
          setServerMsg('');
          setSubmitting(true);
          dispatch(refreshSingleCompetitor(token,queryString))
        }
      } 
    };

    useEffect(() => {
      if (refreshCompData.data) {
        handleClose();
        setSubmitting(false);
        dispatch(resetRefreshCompetitor());
        dispatch(fetchProductDetails(token, product_id)); 
      } else if (refreshCompData.error) {
        setServerMsg('Failed to refesh');
        setSubmitting(false);
      }
  }, [refreshCompData]) //eslint-disable-line

  return (
    <Dialog
      open={dialogState.refresh}
      onClose={handleClose}
      PaperProps={{
        sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white' },
      }}
    >
      {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Refreshing...</Typography> :
      <DialogContent sx={{ width: '230px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Select Market
        </Typography>
        <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
      </Grid>
      <Grid sx={{ mt: 2 }}>
        <RadioGroup 
          aria-labelledby='demo-radio-buttons-group-label' name='radio-buttons-group'
          value={selectedValue}
          onChange={handleChange}
        >
          {marketPlaces && marketPlaces?.data?.length > 0 ? (
            marketPlaces?.data?.map((item, index) => (
              <FormControlLabel key={index} value={item?.market_place_id}
                control={ <Radio size='small' sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}/> }
                label={<span style={{ fontSize: '13px' }}> {item.market_place_name} </span>}
              />
            ))
          ) : (
            <Typography variant='body2'>No marketplaces available</Typography>
          )}
        </RadioGroup>
      </Grid>
      <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
      <Grid sx={{ textAlign: 'end' }}>
        <Button size='small' variant='contained' disabled={selectedValue === ''} onClick={handleRefresh} >
          Confirm
        </Button>
      </Grid>
    </DialogContent>
      } 
    </Dialog>
  );
}

export default RefreshCompetitorDialog