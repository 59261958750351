import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_SETTINGS_GROUP,FETCH_SETTINGS_GROUP_FAILED,FETCH_SETTINGS_GROUP_SUCCESSFULL } from "../constants/getSettingsGroup.constants";

const getSettingsGroup = () => ({
    type: FETCH_SETTINGS_GROUP
});

const getSettingsGroupSuccess = (data) => ({
    type: FETCH_SETTINGS_GROUP_SUCCESSFULL,
    payload: data
});

const getSettingsGroupFailure = (error) => ({
    type: FETCH_SETTINGS_GROUP_FAILED,
    payload: error
});

export const fetchSettingsGroup = (token) => async (dispatch) => {
    try {
      dispatch(getSettingsGroup());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.settingsGroup}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getSettingsGroupSuccess(response.data));
    } catch (error) {
      dispatch(getSettingsGroupFailure(error));
    }
};