import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

const PriceVariationChart = ( ) => {

    const themeMode = useSelector(state => state.theme.mode);

    const dummylineData = {
      labels: ['1', '2', '3', '4', '5', '6', '7'],
      datasets: [
        {
          label: 'Our Product',
          data: [250, 320, 280, 290, 320, 310, 300],
          borderColor: '#355FBE',
          backgroundColor: '#355FBE',
        },
        {
          label: 'Competitor variance 1',
          data: [220, 300, 270, 280, 300, 290, 280],
          borderColor: '#4F8EEC',
          backgroundColor: '#4F8EEC',
        },
        {
          label: 'Competitor variance 2',
          data: [200, 280, 250, 260, 280, 270, 260],
          borderColor: '#A8CBFF',
          backgroundColor: '#A8CBFF',
        },
      ],
    };

  const options = {
    responsive: false,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        borderWidth: 2,
        
        scales: {
            y:{
                title: {
                    display: true,
                    text: 'Sales',
                    color: themeMode === 'light'? '#272727' : 'lightGrey',
                    font: {
                      weight: 'bold',
                      size: 16,
                    },
                  },
              border:{
                display:true,
                text: 'Price',
                color:'#515151'
              },
              ticks:{
                  display: true,
                    color: themeMode === 'light'? '#272727' : '#E4E3E3',
                    maxRotation: 0,
                    autoSkip: false,
              },
              grid: {
                display: true,
              },
              display: true
            },
            x: {
              stacked: true,
              title: {
                display: true,
                text: 'Timeline',
                color: themeMode === 'light'? '#272727' : 'lightGrey',
                font: {
                  weight: 'bold',
                  size: 16,
                },
              },
              border:{
                display:true,
                color:'#515151'
              },
              ticks:{
                  display: true,
                  color: themeMode === 'light'? '#272727' : '#E4E3E3',
                  maxRotation: 0,
                  autoSkip: false,
              },
              grid:{
                drawOnChartArea:false
              }
            },
            yAxes: {
                display:false,
                border:{
                  display:false,
                },
                labels:{
                  display: false
                },
                title:{display:false},
                ticks: {
                    display: false
                }
            },
            xAxes: {
                border:{
                  display:false,
                },
                ticks: {
                    display: false
                }
            }
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
        <Line
      options={options}
      data={dummylineData}
      width={870}
      height={430}
      style={{paddingTop:'50px'}}
    />
    </div>
  );
};

export default PriceVariationChart;
