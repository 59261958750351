import axios from 'axios';
import { paths } from '../../Api/paths';
import { PRODUCT_LOCATIONS_FAILED, PRODUCT_LOCATIONS_GET, PRODUCT_LOCATIONS_SUCCESSFULL } from "../constants/productLocations.constants";

const getProductLocations = () => ({
    type: PRODUCT_LOCATIONS_GET,
});

const getProductLocationsSuccessfull = (data) => ({
    type: PRODUCT_LOCATIONS_SUCCESSFULL,
    payload: data
});

const getProductLocationsFailed = (error) => ({
    type: PRODUCT_LOCATIONS_FAILED,
    payload: error
});

export const getAllProductLocations = (token) => async (dispatch) => {
    try {
        dispatch(getProductLocations());
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.productLocations}`,
            { headers: { Authorization: `bearer ${token}` } })
        dispatch(getProductLocationsSuccessfull(response.data));
    } catch (error) {
        dispatch(getProductLocationsFailed(error));
    }
};