import axios from "axios";
import { paths } from '../../Api/paths';
import { DELETE_SINGLE_PRODUCT, DELETE_SINGLE_PRODUCT_FAILED, DELETE_SINGLE_PRODUCT_SUCCESSFULL, RESET_DELETE_SINGLE_PRODUCT } from "../constants/deleteSingleProduct.constants";

const deleteSingleProduct = () => ({
    type: DELETE_SINGLE_PRODUCT
});

const deleteSingleProductSuccess = (data) => ({
    type: DELETE_SINGLE_PRODUCT_SUCCESSFULL,
    payload: data
});

const deleteSingleProductFailure = (error) => ({
    type: DELETE_SINGLE_PRODUCT_FAILED,
    payload: error
});

export const resetDeleteSingleProduct = () => ({
    type: RESET_DELETE_SINGLE_PRODUCT
})

export const deleteExistingProduct = (token, prdId) => async (dispatch) => {
    try {
      dispatch(deleteSingleProduct());
      const response = await axios.delete(`${process.env.REACT_APP_API_LINK}${paths.myProducts}${prdId}`,
      { headers: { Authorization: `bearer ${token}` }});
      dispatch(deleteSingleProductSuccess(response.data));
    } catch (error) {
      dispatch(deleteSingleProductFailure(error));
    }
};