import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../components/Navbar';
import { useSelector } from 'react-redux';
import { Button, Grid, Box } from '@mui/material';
import Footer from '../../components/Footer';

const Report = () => {
  const themeMode = useSelector(state => state.theme.mode);
  const tableauVizRef = useRef(null);
  const [tableauUrl, setTableauUrl] = useState('https://public.tableau.com/views/Assortmentandoffers/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link');
  const [selectedButton, setSelectedButton] = useState('assortment');

  useEffect(() => {
    const url = tableauUrl;
    const options = {
      hideTabs: true,
    };

    let viz;

    const initTableau = () => {
      if (window.tableau.Viz) {
        viz = new window.tableau.Viz(tableauVizRef.current, url, options);
      } else {
        setTimeout(initTableau, 100);
      }
    };

    initTableau();
    
    return () => {
      if (viz) {
        viz.dispose();
      }
    };
  }, [tableauUrl]);

  const handleAssortment = () => {
    setTableauUrl('https://public.tableau.com/views/Assortmentandoffers/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link');
    setSelectedButton('assortment');
  };

  const handlePrivateLabel = () => {
    setTableauUrl('https://public.tableau.com/views/Dashboard_final_packaged/LIGHT?:language=en-US&:display_count=n&:origin=viz_share_link');
    setSelectedButton('privateLabel');
  };

  return (
    <>
      <Navbar selected='report' />
      <Grid container className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Grid p={4} item xs={12}>
          <Button onClick={handleAssortment} 
          sx={{
            fontSize: '12px',
            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            textTransform: 'capitalize',
            '&::first-letter': { textTransform: 'uppercase' },
            border: selectedButton === 'assortment' ? `1px solid ${themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'}` : 'none',
            padding:'2px',
            marginRight:'2px'
          }}
          >Assortment</Button>
          <span style={{ fontSize:'12px' }}>|</span>
          <Button onClick={handlePrivateLabel} sx={{
            fontSize: '12px',
            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            textTransform: 'capitalize',
            '&::first-letter': { textTransform: 'uppercase' },
            border: selectedButton === 'privateLabel' ? `1px solid ${themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'}` : 'none',
            padding:'2px',
            marginLeft:'2px'
          }}>Private Label</Button>
        </Grid>
        <Grid px={4} sx={{ display: 'flex' }} item xs={12} >
          <Box
            ref={tableauVizRef}
            sx={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              overflowX: 'auto',
              p: 'auto'
            }}
          ></Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Report;
