import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import { useDispatch, useSelector } from 'react-redux';
import { Card, Chip, Grid, Typography, Select, MenuItem, TextField, InputAdornment, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Rating, Divider, Pagination, Popover, Switch, Box, IconButton } from '@mui/material';
import Footer from '../../components/Footer';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import ProductFilterDailog from '../../components/ProductFilterDailog';
import { fetchProducts } from '../../redux/actions/getProducts.actions';
import { fetchMarketplaces } from '../../redux/actions/marketPlaces.actions';
import Loading from '../../components/Loading';
import { changePageNumber, changePageSize, changeSearchInput, clearSpecificFilter } from '../../redux/actions/productString.actions';
import EditProductDialog from '../../components/EditProductDialog';
import { toggleKeyValue } from '../../redux/actions/toggleKeyValue.actions';
import { useNavigate } from 'react-router-dom';
import DeleteDialog from '../../components/DeleteDialog';
import { fetchLocations } from '../../redux/actions/locations.actions';
import { fetchGroups } from '../../redux/actions/groups.actions';
import { modifyGroupProducts, resetModifyGroupProducts } from '../../redux/actions/modifyGroupProducts.actions';

const MyProducts = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const currSymb = useSelector(state => state.auth.currency[0]?.currency_symbol);
  const productString = useSelector(state => state.productString);
  const products = useSelector(state => state.products);
  const locations = useSelector(state => state.locations);
  const groups = useSelector(state => state.groups);
  const marketPlaces = useSelector(state => state.marketPlaces);
  const deleteSingleProduct = useSelector(state => state.deleteSingleProduct);
  const editProduct = useSelector(state => state.editProduct);
  const keyValue = useSelector(state => state.keyValue);
  const groupActions = useSelector(state => state.groupActions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogState, setDialogState] = useState({ applyFilter: false, deleteSingleProduct: false, editProduct: false, data: null })
  const [searchInput, setSearchInput] = useState('');
  const [selProducts, setSelProducts] = useState([]);
  const [grpActnMsg, setGrpActnMsg] = useState('');
  const [actionRow, setActionRow] = useState(null);

  const handleRemoveFilter = (filter) => {
    dispatch(clearSpecificFilter(filter));
  }

  const openDialog = (type) => {
    const tempState = { ...dialogState, [type]: true, data: actionRow }
    setDialogState(tempState)
  }

  const totalPages = Math.ceil(products.data?.count / limit);

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setCurrentPage(1);
    dispatch(changePageSize(event.target.value));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    dispatch(changePageNumber(value));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      dispatch(changePageNumber(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      dispatch(changePageNumber(currentPage + 1))
    }
  };

  const [popUp, setPopup] = useState({ openActionPopup: null, openGroupPopup: null })

  const handleChangeSearch = (e) => {
    setSearchInput(e.target.value);
  }

  const filterSearch = () => {
    dispatch(changeSearchInput(searchInput));
  }

  const addProductsToGroup = (grp) => {
    const payload = {
      action: 'create',
      group_id: grp,
      prd_id: selProducts
    }
    dispatch(modifyGroupProducts(token, payload));
    setGrpActnMsg('Adding products to the group...')
  }

  const removeProductsFromGroup = (grp) => {
    const payload = {
      action: 'delete',
      group_id: grp,
      prd_id: selProducts
    }
    dispatch(modifyGroupProducts(token, payload));
    setGrpActnMsg('Removing products from the group...')
  }

  const downloadXls = () => {
    console.log(productString);
  }

  const goToDetailsPage = (prd) => {
    navigate(`/myproduct/${prd.prd_id}/${productString.prd_loc_id}`)
  }

  const handleSelectAll = () => {
    let tempState = [];
    if (selProducts.length < products.data?.results?.length) {
      products.data?.results?.forEach(item => tempState.push(item.prd_id));
    }
    setSelProducts(tempState);
  }

  const handleCheckedPrds = (prdId) => {
    if (selProducts.includes(prdId)) {
      const tempState = selProducts.filter(item => item !== prdId);
      setSelProducts(tempState);
    } else {
      const tempState = [...selProducts, prdId];
      setSelProducts(tempState);
    }
  }

  const goToProductPage = (e, link) => {
    e.stopPropagation();
    window.open(link, '_blank')
  }

  const handleKeyValue = () => {
    dispatch(toggleKeyValue(token, actionRow.prd_id));
    handlePopupClose();
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handlePopupOpen = (popupType, event, prd) => {
    setPopup({ ...popUp, [popupType]: event.currentTarget });
    setActionRow(prd)
  };

  const handlePopupClose = () => {
    setPopup({ openActionPopup: null, openGroupPopup: null });
    if(groupActions.data || groupActions.error){
      dispatch(resetModifyGroupProducts());
    }
  }
  const actionPopup = Boolean(popUp.openActionPopup);
  const groupPopup = Boolean(popUp.openGroupPopup);
  const actionId = actionPopup ? 'product_actions' : undefined;
  const groupId = groupPopup ? 'group_modify' : undefined;

  useEffect(() => {
    setSelProducts([]);
    setLimit(productString.page_size);
    setCurrentPage(productString.page);
    setSearchInput(productString.search);
    sessionStorage.setItem('productString', JSON.stringify(productString));
    let filterString = '';
    for (let key in productString) {
      if (key === 'page') {
        filterString = '?' + key + '=' + productString[key];
      } else if (productString[key]) {
        filterString = filterString + '&' + key + '=' + productString[key];
      }
    }
    dispatch(fetchProducts(token, filterString))
  }, [productString, deleteSingleProduct, editProduct, keyValue]) //eslint-disable-line

  useEffect(()=> {
    if(groupActions.error){
      setGrpActnMsg('Failed to modify group')
    } else if(groupActions.success) {
      if(groupActions?.data?.message === 'Removed Successfully'){
        handlePopupClose();
        setCurrentPage(1);
        dispatch(changePageNumber(1));
      } else {
        setGrpActnMsg('Products added successfully')
      }
    } else {
      setGrpActnMsg('');
    }
  },[groupActions]) //eslint-disable-line

  useEffect(() => {
    dispatch(fetchMarketplaces(token));
    dispatch(fetchLocations(token));
    dispatch(fetchGroups(token, 'filter'));
  }, []) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const popupStyle = { ...fontColor, backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', width: '200px', py: 1 }
  const tableHeader = { ...fontColor, background: themeMode === 'light' ? '#FDF8E5' : '#3F526B', pb: '5px', pt: '5px' }
  const errorBox = { ...fontColor, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35vh' }
  const chipStyle = { ...fontColor, bgcolor: themeMode === 'light' ? '#FDF8E5' : '#3F526B', borderRadius: 1 }
  const selectStyle = { ...fontColor, bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D' }
  const prdActnStyle = { cursor: 'pointer', '&:hover': { backgroundColor: themeMode === 'light' ? '#f4d35e' : '#3F526B' } }
  const pageChangeStyle = { ...fontColor, borderColor: '#d0d5dd', textTransform: 'none', fontWeight: '400', height: '32px' }

  const ResultsPerPageControl = () => {
    return (
      <>
        <Typography sx={{ ...fontColor, fontSize: '13px' }} >Show</Typography>
        <Select
          inputProps={{ name: 'limitSelect' }} id='limitSelect' size='small'
          MenuProps={{
            PaperProps: {
              sx: selectStyle
            }
          }}
          value={limit} onChange={handleChangeLimit}
          displayEmpty
          sx={{
            ...fontColor, border: '1px solid #ced4da', fontSize: 14,
            '& .MuiSelect-icon': { fill: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
          }}>
          <MenuItem sx={{ fontSize: 14 }} value={10}>10</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={25}>25</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={100}>100</MenuItem>
        </Select>
        <Typography sx={{ ...fontColor, fontSize: '13px' }} >Products in a page</Typography>
      </>
    )
  }

  return (
    <>
      <Navbar selected='myProducts' />
      {dialogState.applyFilter && <ProductFilterDailog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.editProduct && <EditProductDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.deleteSingleProduct && <DeleteDialog dialogState={dialogState} setDialogState={setDialogState} dialogFor={'deleteSingleProduct'} />}
      <Grid container sx={{ p: '40px' }} className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Card style={{ width: '100%', borderRadius: '10px', backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff' }} >
          <Grid item container xs={12} p={2} >
            <Grid xs={9} item sx={{ display: 'flex', alignItems: 'center', gap: '12px' }} >
              <Typography sx={{ ...fontColor, fontSize: '15px' }} >My products</Typography>
              {products.data && <Chip label={`${products.data?.count || 0} SKU`}
                sx={{ background: '#f0f9ff', color: '#026aa2' }} />}
              {productString.prd_loc_id && <Box sx={{
                display: 'flex', alignItems: 'center', backgroundColor: '#f4d35e',
                border: '0.5px solid #808080', px: 1, borderRadius: 5, height: '100%'
              }}>
                <FmdGoodOutlinedIcon style={{ color: '#5A5A5A', fontSize: 18 }} />
                <Typography sx={{ color: '#5A5A5A', fontSize: '14px' }} >
                  {locations.data?.find(loc => loc.loc_id === Number(productString?.prd_loc_id))?.loc_name || ''}
                </Typography>
              </Box>}
              {productString.group_id__in && <Chip sx={chipStyle} label={`Groups: ${productString.group_id__in.split(',').length}`}
              deleteIcon={<CloseIcon style={fontColor} />} onDelete={()=> handleRemoveFilter('group_id__in')}/>}
              {productString.prd_brand__in && <Chip sx={chipStyle} label={`Brands: ${productString.prd_brand__in.split(',').length}`}
              deleteIcon={<CloseIcon style={fontColor} />} onDelete={()=> handleRemoveFilter('prd_brand__in')} />}
              {productString.category_id__in && <Chip sx={chipStyle} label={`Categories: ${productString.category_id__in.split(',').length}`}
              deleteIcon={<CloseIcon style={fontColor} />} onDelete={()=> handleRemoveFilter('category_id__in')} />}
              {productString.dept_id__in && <Chip sx={chipStyle} label={`Departments: ${productString.dept_id__in.split(',').length}`}
              deleteIcon={<CloseIcon style={fontColor} />} onDelete={()=> handleRemoveFilter('dept_id__in')} />}
              {productString.price && <Chip sx={chipStyle} label={`Price: ${capitalizeFirstLetter(productString.price)}`}
              deleteIcon={<CloseIcon style={fontColor} />} onDelete={()=> handleRemoveFilter('price')} />}
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }} >
              <Button size='small' variant='outlined' sx={{
                ...fontColor, textTransform: 'none',
                height: '32px', borderColor: '#d0d5dd', '&:hover': { borderColor: '#d0d5dd' }
              }}
                onClick={downloadXls} > <img src={'assets/excel-icon.png'} alt='excel icon'
                  style={{ marginRight: '8px', height: '20px', objectFit: 'contain' }} /> Download XLS</Button>
              <Button size='small' variant='outlined' startIcon={<FilterListIcon />} pr={1} sx={{
                ...fontColor, textTransform: 'capitalize', height: '32px', borderColor: '#d0d5dd',
                '&:hover': { borderColor: '#d0d5dd' }
              }} onClick={() => openDialog('applyFilter')} >Filters</Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} pl={2} >
            <Grid item xs={9} sx={{ display: 'flex', gap: '12px' }} >
              <TextField size='small' placeholder='Search' sx={{
                '& input': fontColor,
                border: '1px solid #d0d5dd',
                borderRadius: '5px', '& input::placeholder': fontColor
              }}
                variant='outlined' InputProps={{
                  startAdornment: (<InputAdornment position='start'><SearchIcon sx={fontColor} />
                  </InputAdornment>)
                }}
                onKeyUp={(e) => { if (e.key === 'Enter') filterSearch() }}
                value={searchInput} onChange={handleChangeSearch} />
              <Button onClick={filterSearch} variant='contained'
                sx={{ backgroundColor: '#0086c9', borderRadius: '5px', textTransform: 'none' }}
              >Search</Button>
              {selProducts.length > 0 && <Button size='small' variant='outlined' sx={{
                ...fontColor, textTransform: 'none', borderColor: '#d0d5dd',
                '&:hover': { borderColor: '#d0d5dd' }}} onClick={(e)=> handlePopupOpen('openGroupPopup', e)}>
                <DriveFileMoveIcon style={{ marginRight: '8px', height: '20px', objectFit: 'contain' }} /> Groups
              </Button>}
              <Popover
              sx={{ '& .MuiPaper-root': { border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
              borderRadius: 1, boxShadow: 'none' }, mt: '15px', ml: '-50px' }}
              id={groupId} open={groupPopup} anchorEl={popUp.openGroupPopup}
              onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Grid sx={popupStyle}>
                  {grpActnMsg ? <Typography sx={{ fontSize: '11px', m: 1 }}>{grpActnMsg}</Typography>:<>
                  <Typography sx={{ fontSize: '11px', px: 1 }}>Add to</Typography>
                  {groups.data?.filter(grp => !productString.group_id__in.includes(grp.group_id)).map(item => 
                      <Typography key={item.group_id} sx={{ ...prdActnStyle, px: 1, py: 1, fontSize: '15px' }}
                      onClick={() => addProductsToGroup(item.group_id)}>{item.group_name}</Typography>)}
                  {productString.group_id__in && !productString.group_id__in.includes(',') && <>
                  <Divider color='gray' sx={{ my: 1 }}/>
                  <Typography sx={{ fontSize: '11px', px: 1 }}>Remove from</Typography>
                      <Typography sx={{ ...prdActnStyle, px: 1, py: 1, fontSize: '15px' }}
                      onClick={()=> removeProductsFromGroup(productString.group_id__in)}>
                        {groups.data?.find(grp => Number(productString.group_id__in) === grp.group_id)?.group_name}</Typography>
                    </>}
                  </>}
                </Grid>
              </Popover>
              {selProducts.length > 0 && <Grid sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <Typography sx={{ ...fontColor, fontSize: '13px' }} >
                {`${selProducts.length} product${selProducts.length > 1 ? 's' : ''} selected`}
                </Typography></Grid>}
            </Grid>
            <Grid xs={3} pr={2} item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '12px' }} >
              <ResultsPerPageControl />
            </Grid>
          </Grid>
          <Grid sx={{ width: '100%', overflow: 'hidden', mt: 1 }}>
            {(products.loading || marketPlaces.loading) ? <Loading height='50vh' /> :
              products.error ? <Box sx={errorBox}><Typography>Failed to fetch products</Typography></Box> :
                marketPlaces.error ? <Box sx={errorBox}><Typography>Failed to fetch market places</Typography></Box> :
                  products.data?.message ? <Box sx={errorBox}><Typography>{products.data.message}</Typography></Box> :
                    <TableContainer className={`custom-scrollbar-${themeMode}`} sx={{ maxHeight: 440 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell style={tableHeader}>
                              <Checkbox id='selectallcheckbox' sx={fontColor} size='small' checked={selProducts.length === products.data?.results?.length}
                                onChange={handleSelectAll} /></TableCell>
                            <TableCell style={tableHeader}>Product name</TableCell>
                            <TableCell style={tableHeader}>Your price</TableCell>
                            {marketPlaces.data?.map((mkt) => (
                              <TableCell key={mkt.market_place_id} sx={{ textAlign: 'center', ...tableHeader }}>
                                {mkt.market_place_name}</TableCell>
                            ))}
                            <TableCell sx={{ textAlign: 'center', ...tableHeader }}>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {products.data?.results?.map(prd => {
                            const productCurLoc = prd.locations[0];
                            return (
                              prd.prd_qa_approved ? (<TableRow key={prd.prd_id}>
                                <TableCell style={{ verticalAlign: 'top' }} >
                                  <Checkbox id={`select_${prd.prd_id}`} sx={fontColor} size='small'
                                    checked={selProducts.includes(prd.prd_id)} onChange={() => handleCheckedPrds(prd.prd_id)} /></TableCell>
                                <TableCell style={{ verticalAlign: 'top', cursor: 'pointer' }}>
                                  <Grid item xs={12} sx={fontColor} container onClick={() => goToDetailsPage(prd)}>
                                    <Grid item xs={2} sx={{ mr: 1 }} >
                                      <img src={prd.prd_small_image ? prd.prd_small_image : '/assets/no_image.png'}
                                        alt='img' style={{ maxWidth: '100%', height: '70px', objectFit: 'contain' }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Typography sx={{ fontSize: '13px' }} >{prd.prd_name}</Typography>
                                      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', mt: 1 }} >
                                        <Rating name='read-only' sx={{ '& .MuiRating-iconEmpty': fontColor }} precision={0.5} size='small' value={prd.prd_rating} readOnly />
                                        <Typography sx={{ fontSize: '11px', ml: '5px' }} >{prd.prd_tot_reviews}</Typography>
                                      </Box>
                                      <Box sx={{ display: 'flex', gap: '5px', mt: 1 }} >
                                        <Typography sx={{ fontSize: '12px' }} >{prd.prd_brand}</Typography>
                                        <Divider orientation='vertical' flexItem color='gray' />
                                        <Typography sx={{ fontSize: '12px' }} >{prd.category?.category_name}</Typography>
                                        <Divider orientation='vertical' flexItem color='gray' />
                                        <Typography onClick={(e) => goToProductPage(e, prd.prd_url)}
                                          sx={{ fontSize: '12px', color: '#0094ff', textDecoration: 'underline', cursor: 'pointer' }}
                                        >Visit website</Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell style={{ verticalAlign: 'top' }}>
                                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                    {productCurLoc ? <Chip sx={{ color: '#1976d2', backgroundColor: '#E4F6FF', borderRadius: 1 }}
                                      variant='contained' label={`${currSymb} ${productCurLoc.prd_sales_price}`} /> : '-'}
                                    {productCurLoc ? <Typography sx={{ ...fontColor, fontSize: '11px', pt: '4px' }} >
                                      {currSymb} {(productCurLoc.prd_sales_price / prd.prd_no_of_pieces).toFixed(2)} / pc</Typography> : '-'}
                                  </Grid>
                                </TableCell>
                                {marketPlaces.data?.map(mkt => {
                                  const marketCompData = prd.competitors.filter(comp => comp.market_places?.market_place_id === mkt.market_place_id);
                                  const maxScore = Math.max(...marketCompData.map((competitor) => competitor.comp_prd_score));
                                  const highScoreComp = marketCompData.filter((competitor) => competitor.comp_prd_score === maxScore);
                                  const sortedHighScoreComp = highScoreComp.sort((a, b) => a.comp_prd_sales_price - b.comp_prd_sales_price);
                                  return (
                                    <TableCell style={{ verticalAlign: 'top', whiteSpace: 'nowrap' }} key={mkt.market_place_id}>
                                      <Grid item xs={12} container sx={{ ...fontColor, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }} >
                                        {sortedHighScoreComp.length > 0 ? <Chip sx={{
                                          borderRadius: 1, color:
                                            sortedHighScoreComp[0].comp_prd_sales_price > productCurLoc?.prd_sales_price ? '#0c9c59' :
                                              sortedHighScoreComp[0].comp_prd_sales_price < productCurLoc?.prd_sales_price ? '#c11515' : '#1976d2',
                                          backgroundColor:
                                            sortedHighScoreComp[0].comp_prd_sales_price > productCurLoc?.prd_sales_price ? '#e6fef0' :
                                              sortedHighScoreComp[0].comp_prd_sales_price < productCurLoc?.prd_sales_price ? '#fdf2f2' : '#E4F6FF'
                                        }}
                                          variant='contained' label={`${currSymb} ${sortedHighScoreComp[0].comp_prd_sales_price}`} /> : '--'}
                                        {sortedHighScoreComp.length > 0 && <Typography sx={{ ...fontColor, fontSize: '11px', pt: '4px' }} >
                                          {currSymb} {(sortedHighScoreComp[0].comp_prd_sales_price / sortedHighScoreComp[0].comp_prd_no_of_pieces).toFixed(2)} / pc</Typography>}
                                        {sortedHighScoreComp.length > 1 &&
                                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', paddingTop: '5px', whiteSpace: 'nowrap' }}>
                                            <Typography sx={{ fontSize: '12px', }}>
                                              {currSymb} {sortedHighScoreComp[0].comp_prd_sales_price} - {currSymb} {sortedHighScoreComp[sortedHighScoreComp.length - 1].comp_prd_sales_price}</Typography>
                                            <Divider orientation='vertical' sx={{ height: '15px' }} />
                                            <StoreOutlinedIcon sx={{ fontSize: 15 }} />
                                            <Typography sx={{ fontSize: '12px', ml: '4px' }}>{sortedHighScoreComp.length}</Typography>
                                          </Box>}
                                        {sortedHighScoreComp.length > 0 && <Button style={{ display: 'flex', alignItems: 'center', color: '#11486D', backgroundColor: '#F8F8F8', textTransform: 'none', whiteSpace: 'none' }}>
                                          <RefreshOutlinedIcon sx={{ fontSize: 15, mr: '5px' }} />
                                          <Box>
                                            <Typography sx={{ fontSize: '12px' }}>{moment(sortedHighScoreComp[0].modified_date).format('DD/MM/YYYY')}</Typography>
                                            <Typography sx={{ fontSize: '12px' }}>{moment(sortedHighScoreComp[0].modified_date).format('h:mm A')}</Typography>
                                          </Box>
                                        </Button>}
                                      </Grid>
                                    </TableCell>
                                  );
                                })}
                                <TableCell>
                                  <IconButton onClick={(e) => handlePopupOpen('openActionPopup', e, prd)}>
                                    <MoreVertIcon sx={{ ...fontColor, cursor: 'pointer' }} />
                                  </IconButton>
                                  <Popover
                                    id={actionId} open={actionPopup} anchorEl={popUp.openActionPopup}
                                    onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    sx={{
                                      mt: '15px', ml: '-50px', '& .MuiPopover-paper': {
                                        boxShadow: 'none', width: '230px',
                                        border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                                        backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B',
                                        color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3'
                                      }
                                    }}>
                                    <Grid item xs={12} container >
                                      <Grid item container p={1} sx={prdActnStyle}
                                        onClick={() => {
                                          openDialog('editProduct');
                                          handlePopupClose();
                                        }} >
                                        <Grid item xs={3} ><EditOutlinedIcon sx={{ ml: 1, fontSize: 23 }} /></Grid>
                                        <Grid item xs={9} ><Typography sx={{ fontSize: '14px' }} >Edit</Typography> </Grid>
                                      </Grid>
                                      <Grid item container p={1} sx={prdActnStyle}
                                        onClick={() => {
                                          openDialog('deleteSingleProduct');
                                          handlePopupClose();
                                        }} >
                                        <Grid item xs={3} ><DeleteOutlineOutlinedIcon sx={{ ml: 1, fontSize: 23 }} /> </Grid>
                                        <Grid item xs={9} ><Typography sx={{ fontSize: '14px' }} >Delete</Typography> </Grid>
                                      </Grid>
                                      <Grid item container p={1} sx={{ display: 'flex', alignItems: 'center' }} >
                                        <Grid item xs={3} ><NotificationsNoneOutlinedIcon sx={{ ml: 1, fontSize: 23 }} /> </Grid>
                                        <Grid item xs={6} ><Typography sx={{ fontSize: '14px' }} >Notifications</Typography> </Grid>
                                        <Grid item xs={3} ><Switch id={`notifications_${prd.prd_id}`} size='small' sx={{ fontSize: '12px' }} /> </Grid>
                                      </Grid>
                                      <Grid item container p={1} sx={{ display: 'flex', alignItems: 'center' }} >
                                        <Grid item xs={3} ><VpnKeyOutlinedIcon sx={{ ml: 1, fontSize: 20 }} /> </Grid>
                                        <Grid item xs={6} ><Typography sx={{ fontSize: '14px' }} >Key value</Typography> </Grid>
                                        <Grid item xs={3} >
                                          <Switch id={`key_value_${prd.prd_id}`}
                                            size='small' sx={{ fontSize: '13px' }} checked={actionRow ? actionRow.prd_is_key_value : false} onChange={handleKeyValue} />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Popover>
                                </TableCell>
                              </TableRow>) : null)
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>}
            <Grid item xs={12} container p={2} style={{ borderTop: '0.5px solid rgba(224, 224, 224, 1)' }} >
              <Grid item xs={4} sx={{ display: 'flex', gap: '5px', justifyContent: 'flex-start', alignItems: 'center' }} >
                <ResultsPerPageControl />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                <Pagination count={totalPages || 0}
                  shape='rounded'
                  hideNextButton hidePrevButton
                  onChange={handlePageChange}
                  page={currentPage}
                  sx={{
                    '& .MuiPaginationItem-root': fontColor,
                    '& .MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: '#e7f7ff',
                      color: '#0086c9',
                      fontWeight: '600'
                    },
                    '& .MuiPaginationItem-root:hover': {
                      color: '#0086c9',
                      backgroundColor: '#e7f7ff',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} >
                <Button variant='outlined' sx={pageChangeStyle} size='small'
                  onClick={handlePreviousPage} disabled={currentPage === 1}
                >Previous</Button>
                <Button variant='outlined' sx={pageChangeStyle} size='small'
                  onClick={handleNextPage} disabled={currentPage === totalPages}
                >Next</Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Footer />
    </>
  )
}

export default MyProducts