import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_PRODUCTS, FETCH_PRODUCTS_FAILED, FETCH_PRODUCTS_SUCCESSFULL } from "../constants/getProducts.constants";

const getProducts = () => ({
    type: FETCH_PRODUCTS
});

const getProductsSuccess = (data) => ({
    type: FETCH_PRODUCTS_SUCCESSFULL,
    payload: data
});

const getProductsFailure = (error) => ({
    type: FETCH_PRODUCTS_FAILED,
    payload: error
});

export const fetchProducts = (token, filterString) => async (dispatch) => {
    try {
      dispatch(getProducts());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.myProducts}${filterString}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getProductsSuccess(response.data));
    } catch (error) {
      dispatch(getProductsFailure(error));
    }
};