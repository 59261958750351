import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, IconButton, Checkbox, Dialog, DialogContent, Chip, Radio, Button, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCategories } from '../../redux/actions/categories.actions';
import { fetchDepartments } from '../../redux/actions/departments.actions';
import { fetchBrands } from '../../redux/actions/getBrands.actions';
import { applyFilterOptions } from '../../redux/actions/productString.actions';
import { fetchGroups } from '../../redux/actions/groups.actions';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';
import { fetchCompetitorsLocations } from '../../redux/actions/compLocations.actions';

const ProductFilterDailog = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const groups = useSelector(state => state.groups);
  const brands = useSelector(state => state.brands);
  const categories = useSelector(state => state.categories);
  const departments = useSelector(state => state.departments);
  const prdLocations = useSelector(state => state.productLocations);
  const locations = useSelector(state => state.competitorsLocations);
  const productString = useSelector(state => state.productString);
  const productLocations = useSelector(state => state.productLocations)
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('group');
  const initialState = { group: [], brand: [], category: [], department: [], prd_loc: '', comp_loc: '', price: '' };
  const [selectedItems, setSelectedItems] = useState(initialState);

  const handleClose = () => {
    setDialogState({ ...dialogState, applyFilter: false })
  }

  const filterLists = ['group', 'brand', 'category', 'department', 'location', 'price']
  const priceOptions = ['higher', 'equal', 'lower']

  const handleTabs = (tab) => {
    if(tab === 'brand' && !brands.data){
      dispatch(fetchBrands(token));
    } else if (tab === 'category' && !categories.data){
      dispatch(fetchCategories(token, 'filter'));
    } else if (tab === 'department' && !departments.data){
      dispatch(fetchDepartments(token, 'filter'));
    } else if (tab === 'location' && !locations.data){
      dispatch(fetchCompetitorsLocations(token));
    }
    setSelectedTab(tab);
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleGroups = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.group.includes(item.group_id)) {
      const tempArray = tempItems.group.filter(ele => ele !== item.group_id)
      tempItems.group = tempArray;
    } else {
      tempItems.group.push(item.group_id)
    }
    setSelectedItems(tempItems);
  }

  const handleBrands = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.brand.includes(item)) {
      const tempArray = tempItems.brand.filter(ele => ele !== item)
      tempItems.brand = tempArray;
    } else {
      tempItems.brand.push(item)
    }
    setSelectedItems(tempItems);
  }

  const handleCategories = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.category.includes(item.category_id)) {
      const tempArray = tempItems.category.filter(ele => ele !== item.category_id)
      tempItems.category = tempArray;
    } else {
      tempItems.category.push(item.category_id)
    }
    setSelectedItems(tempItems);
  }

  const handleDepartments = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.department.includes(item.dept_id)) {
      const tempArray = tempItems.department.filter(ele => ele !== item.dept_id)
      tempItems.department = tempArray;
    } else {
      tempItems.department.push(item.dept_id)
    }
    setSelectedItems(tempItems);
  }

  const handlePrdLocation = (item) => {
    const tempItems = { ...selectedItems }
    tempItems.prd_loc = item.loc_id
    setSelectedItems(tempItems);
  }

  const handleCompLocation = (item) => {
    const tempItems = { ...selectedItems }
    tempItems.comp_loc = item.loc_id
    setSelectedItems(tempItems);
  }

  const handlePrice = (item) => {
    const tempItems = { ...selectedItems }
    tempItems.price = item
    setSelectedItems(tempItems);
  }

  const clearFilter = () => {
    const tempState = {
      ...initialState,
      prd_loc: productLocations.data?.find(loc => loc.loc_is_default)?.loc_id
    }
    setSelectedItems(tempState);
    dispatch(applyFilterOptions(tempState));
  }

  const applyFilter = () => {
    handleClose();
    dispatch(applyFilterOptions(selectedItems));
  }

  useEffect(() => {
    dispatch(fetchGroups(token));
    if(!productLocations.data){
      dispatch(getAllProductLocations(token));
    }
    setSelectedItems({
      group: productString.group_id__in?.includes(',') ? productString.group_id__in.split(',').map(Number) : productString.group_id__in !== '' ? [ Number(productString.group_id__in) ] : [],
      brand: productString.prd_brand__in?.includes(',') ? productString.prd_brand__in.split(',') : productString.prd_brand__in !== '' ? [ productString.prd_brand__in ] : [],
      category: productString.category_id__in?.includes(',') ? productString.category_id__in?.split(',').map(Number) : productString.category_id__in !== '' ? [ Number(productString.category_id__in) ] : [],
      department: productString.dept_id__in?.includes(',') ? productString.dept_id__in?.split(',').map(Number) : productString.dept_id__in !== '' ? [ Number(productString.dept_id__in) ] : [],
      prd_loc: Number(productString.prd_loc_id) || '',
      comp_loc: Number(productString.comp_loc_id) || '',
      price: productString.price || ''
    })
  }, []) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const filterList = { maxHeight: '350px', overflowY: 'scroll', width: '100%' }
  const filterLocList = { maxHeight: '350px', overflowY: 'scroll', width: '50%' }
  const optionTitle = { display: 'flex', alignItems: 'center' }
  const activeTab = { ...optionTitle, height: '30px', borderRadius: 1, my: 2, pl: 1, cursor: 'pointer', mr: 1, color: '#0D82BC', backgroundColor: '#DBF3FF' }
  const inactiveTab = { ...optionTitle, height: '30px', borderRadius: 1, my: 2, pl: 1, cursor: 'pointer' }

  return (
    <div>
      <Dialog
        open={dialogState.applyFilter}
        maxWidth='md'
        onClose={handleClose}
        PaperProps={{ sx: { ...fontColor, width: '100%',
          backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff' }}}
      >
        <DialogContent>
          <Grid container sx={{ ...optionTitle, justifyContent: 'space-between', borderBottom: '0.5px solid #eaecf0' }} >
            <Typography sx={{ fontWeight: 'bold' }}>Filters</Typography>
            <IconButton onClick={handleClose} ><CloseIcon style={fontColor} /></IconButton>
          </Grid>
          <Grid sx={{ display: 'flex', borderBottom: '0.5px solid #eaecf0' }}>
            <Grid sx={{ borderRight: '0.5px solid #eaecf0', height: '350px', width: '200px' }} >
              {filterLists.map((filterName, index) => <Box key={index} onClick={() => handleTabs(filterName)} sx={selectedTab === filterName ? activeTab : inactiveTab}>
                <Typography>{capitalizeFirstLetter(filterName)}</Typography>
                {selectedTab === filterName && selectedTab !== 'location' && selectedTab !== 'price' &&
                <Chip label={selectedItems[filterName]?.length || 0}
                color='primary' sx={{ borderRadius: 1, ml: 1 }} size='small' />}
              </Box>)}
            </Grid>
            {selectedTab === 'group' ?
                <Grid className='custom-scrollbar-light' sx={filterList}>
                  {groups.loading ?
                    <Typography sx={{ mt: 12, ml: 8 }}>Fetching the data, please wait...</Typography>
                    : groups.data ? groups.data?.map((group, index) =>
                      <Box key={index} sx={optionTitle}>
                        <Checkbox name='group' size='small' sx={fontColor}
                          checked={selectedItems.group.includes(group.group_id)}
                          onChange={() => handleGroups(group)} />
                        <Typography>{group.group_name}</Typography>
                      </Box>)
                      : groups.error ? <Typography sx={{ m: 12 }}>Failed to load the data</Typography>
                        : <Typography sx={{ m: 12 }}>There is no data</Typography>}
                </Grid>
            : selectedTab === 'brand' ?
              <Grid className='custom-scrollbar-light' sx={filterList}>
                {brands.loading ?
                  <Typography sx={{ mt: 12, ml: 8 }}>Fetching the data, please wait...</Typography>
                  : brands.data ? brands.data?.brands?.map((brand, index) =>
                    <Box key={index} sx={optionTitle}>
                      <Checkbox name='brand' size='small' sx={fontColor}
                        checked={selectedItems.brand.includes(brand)}
                        onChange={() => handleBrands(brand)} />
                      <Typography>{brand}</Typography>
                    </Box>)
                    : brands.error ? <Typography sx={{ m: 12 }}>Failed to load the data</Typography>
                      : <Typography sx={{ m: 12 }}>There is no data</Typography>}
              </Grid>
              : selectedTab === 'category' ?
                <Grid className='custom-scrollbar-light' sx={filterList}>
                  {categories.loading ?
                    <Typography sx={{ mt: 12, ml: 8 }}>Fetching the data, please wait...</Typography>
                    : categories.data ? categories.data?.map((category, index) =>
                      <Box key={index} sx={optionTitle}>
                        <Checkbox name='category' size='small' sx={fontColor}
                          checked={selectedItems.category.includes(category.category_id)}
                          onChange={() => handleCategories(category)} />
                        <Typography>{category.category_name}</Typography>
                      </Box>)
                      : categories.error ? <Typography sx={{ m: 12 }}>Failed to load the data</Typography>
                        : <Typography sx={{ m: 12 }}>There is no data</Typography>}
                </Grid>
                : selectedTab === 'department' ?
                  <Grid className='custom-scrollbar-light' sx={filterList}>
                    {departments.loading ?
                      <Typography sx={{ mt: 12, ml: 8 }}>Fetching the data, please wait...</Typography>
                      : departments.data ? departments.data?.map(department =>
                        <Box key={department.dept_id} sx={optionTitle}>
                          <Checkbox name='department' size='small' sx={fontColor}
                            checked={selectedItems.department.includes(department.dept_id)}
                            onChange={() => handleDepartments(department)} />
                          <Typography>{department.dept_name}</Typography>
                        </Box>)
                        : departments.error ? <Typography sx={{ m: 12 }}>Failed to load the data</Typography>
                          : <Typography sx={{ m: 12 }}>There is no data</Typography>}
                  </Grid>
                  : selectedTab === 'location' ? 
                    <Grid sx={{ display: 'flex', width: '100%' }}>
                    <Grid className='custom-scrollbar-light' sx={filterLocList}>
                      <Typography sx={{ ml: 4, my: 1 }}>Product Location</Typography>
                      <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                      {prdLocations.loading ?
                        <Typography sx={{ mt: 12, ml: 8 }}>Fetching the data, please wait...</Typography>
                        : prdLocations.data ? prdLocations.data?.map(location =>
                          <Box key={location.loc_id} sx={optionTitle}>
                            <Radio name='location' size='small' sx={fontColor}
                              checked={selectedItems.prd_loc === location.loc_id}
                              onChange={() => handlePrdLocation(location)} />
                            <Typography>{location.loc_name}</Typography>
                          </Box>)
                          : locations.error ? <Typography sx={{ m: 12 }}>Failed to load the data</Typography>
                            : <Typography sx={{ m: 12 }}>There is no data</Typography>}
                    </Grid>
                    <Grid className='custom-scrollbar-light' sx={filterLocList}>
                    <Typography sx={{ ml: 4, my: 1 }}>Competitors Location</Typography>
                      <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                      {locations.loading ?
                        <Typography sx={{ mt: 12, ml: 8 }}>Fetching the data, please wait...</Typography>
                        : locations.data ? locations.data?.map(location =>
                          <Box key={location.loc_id} sx={optionTitle}>
                            <Radio name='location' size='small' sx={fontColor}
                              checked={selectedItems.comp_loc === location.loc_id}
                              onChange={() => handleCompLocation(location)} />
                            <Typography>{location.loc_name}</Typography>
                          </Box>)
                          : locations.error ? <Typography sx={{ m: 12 }}>Failed to load the data</Typography>
                            : <Typography sx={{ m: 12 }}>There is no data</Typography>}
                    </Grid>
                    </Grid>
                    : selectedTab === 'price' ?
                    <Grid sx={{ maxHeight: '350px', width: '100%' }}>
                      {priceOptions.map((val, index) =>
                        <Box key={index} sx={optionTitle}>
                          <Radio name='price' size='small' sx={fontColor}
                          checked={selectedItems.price === val}
                          onClick={()=> handlePrice(val)} />
                          <Typography>{capitalizeFirstLetter(val)}</Typography>
                        </Box>)}
                    </Grid> : null}
          </Grid>
          <Grid sx={{ display: 'flex', mt: 2 }}>
            <Button onClick={clearFilter} sx={{ ml: 'auto', mr: 2, textTransform: 'none' }}>Clear filters</Button>
            <Button onClick={applyFilter} variant='contained' sx={{ textTransform: 'none' }}>Apply filters</Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ProductFilterDailog