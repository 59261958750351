import { Dialog, DialogContent, Typography,} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const AddmarketDialog = ({ open, setOpen }) => {
  const themeMode = useSelector((state) => state.theme.mode);
  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } 

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}
    >
      <DialogContent sx={fontColor} >
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }} >Add Market</Typography>
        <Typography sx={{ fontSize: '18px',mt:2 }}>
          Please contact <a style={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} rel='noreferrer' href={'https://www.orbiosolutions.com/contact-us/'} target='_blank' >Orbio Solutions</a> for adding market.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AddmarketDialog;
