import { TOGGLE_KEY_VALUE, TOGGLE_KEY_VALUE_FAILED, TOGGLE_KEY_VALUE_SUCCESSFULL } from "../constants/toggleKeyValue.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const toggleKeyValueReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_KEY_VALUE:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case TOGGLE_KEY_VALUE_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case TOGGLE_KEY_VALUE_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        default:
            return state;
    }
};

export default toggleKeyValueReducer;