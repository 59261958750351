import { MODIFY_GROUP_PRODUCTS, MODIFY_GROUP_PRODUCTS_FAILED, MODIFY_GROUP_PRODUCTS_SUCCESSFULL, RESET_MODIFY_GROUP_PRODUCTS } from "../constants/modifyGroupProducts.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const modifyGroupProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODIFY_GROUP_PRODUCTS:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case MODIFY_GROUP_PRODUCTS_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case MODIFY_GROUP_PRODUCTS_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_MODIFY_GROUP_PRODUCTS:
            return {
                ...initialState
            }

        default:
            return state;
    }
};

export default modifyGroupProductsReducer;