import axios from "axios";
import { paths } from "../../Api/paths";
import {
  ADD_COMPETITOR,
  ADD_COMPETITOR_SUCCESSFULL,
  ADD_COMPETITOR_FAILED,
  RESET_ADD_COMPETITOR,
} from "../constants/addCompetitor.constants";

const addCompetitor = (data) => ({
  type: ADD_COMPETITOR,
  payload: data,
});

const addCompetitoSuccess = (data) => ({
  type: ADD_COMPETITOR_SUCCESSFULL,
  payload: data,
});

const addCompetitorFailure = (error) => ({
  type: ADD_COMPETITOR_FAILED,
  payload: error,
});

export const resetAddCompetitor = () => ({
  type: RESET_ADD_COMPETITOR,
});

export const addNewCompetitor = (token,data) => async (dispatch) => {
  try {
    dispatch(addCompetitor(data));
    const response = await axios.post(
      `${process.env.REACT_APP_API_LINK}${paths.competitors}`,
      data,
      { headers: { Authorization: `bearer ${token}` } }
    );
    if (response.data?.Message) {
      dispatch(addCompetitorFailure(response.data.Message));
    } else {
      dispatch(addCompetitoSuccess(response.data));
    }
  } catch (error) {
    dispatch(addCompetitorFailure(error));
  }
};
