import axios from 'axios';
import { paths } from '../../Api/paths';
import { COMPETITORS_LOCATIONS_FAILED, COMPETITORS_LOCATIONS_SUCCESSFULL, FETCH_COMPETITORS_LOCATIONS } from '../constants/compLocations.constants';

const getCompetitorsLocations = () => ({
    type: FETCH_COMPETITORS_LOCATIONS,
});

const getCompetitorsLocationsSuccessfull = (data) => ({
    type: COMPETITORS_LOCATIONS_SUCCESSFULL,
    payload: data
});

const getCompetitorsLocationsFailed = (error) => ({
    type: COMPETITORS_LOCATIONS_FAILED,
    payload: error
});

export const fetchCompetitorsLocations = (token) => async (dispatch) => {
    try {
        dispatch(getCompetitorsLocations());
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.competitorsLocations}`,
        { headers: { Authorization: `bearer ${token}` } })
        dispatch(getCompetitorsLocationsSuccessfull(response.data));
    } catch (error) {
        dispatch(getCompetitorsLocationsFailed(error));
    }
};