import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_MARKETPLACES, FETCH_MARKETPLACES_FAILED, FETCH_MARKETPLACES_SUCCESSFULL } from "../constants/marketPlaces.constants";

const getMarketplaces = () => ({
    type: FETCH_MARKETPLACES
});

const getMarketplacesSuccess = (data) => ({
    type: FETCH_MARKETPLACES_SUCCESSFULL,
    payload: data
});

const getMarketplacesFailure = (error) => ({
    type: FETCH_MARKETPLACES_FAILED,
    payload: error
});

export const fetchMarketplaces = (token) => async (dispatch) => {
    try {
      dispatch(getMarketplaces());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.marketPlaces}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getMarketplacesSuccess(response.data));
    } catch (error) {
      dispatch(getMarketplacesFailure(error));
    }
};