import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_CATEGORIES, FETCH_CATEGORIES_FAILED, FETCH_CATEGORIES_SUCCESSFULL } from "../constants/categories.constants";

const getCategories = () => ({
    type: FETCH_CATEGORIES
});

const getCategoriesSuccess = (data) => ({
    type: FETCH_CATEGORIES_SUCCESSFULL,
    payload: data
});

const getCategoriesFailure = (error) => ({
    type: FETCH_CATEGORIES_FAILED,
    payload: error
});

export const fetchCategories = (token, keyword) => async (dispatch) => {
    try {
      dispatch(getCategories());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.categories}${keyword}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(getCategoriesFailure(error));
    }
};