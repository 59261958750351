import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_PRODUCT_DETAILS, FETCH_PRODUCT_DETAILS_FAILED, FETCH_PRODUCT_DETAILS_SUCCESSFULL } from "../constants/productDetails.constants";

export const getProductDetails = () => ({
    type: FETCH_PRODUCT_DETAILS
});

export const getProductDetailsSuccess = (data) => ({
    type: FETCH_PRODUCT_DETAILS_SUCCESSFULL,
    payload: data
});

export const getProductDetailsFailure = (error) => ({
    type: FETCH_PRODUCT_DETAILS_FAILED,
    payload: error
});

export const fetchProductDetails = (token, prdId) => async (dispatch) => {
    try {
      dispatch(getProductDetails());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.myProducts}${prdId}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getProductDetailsSuccess(response.data));
    } catch (error) {
      dispatch(getProductDetailsFailure(error));
    }
};