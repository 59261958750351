import { ADD_SINGLE_PRODUCT, ADD_SINGLE_PRODUCT_FAILED, ADD_SINGLE_PRODUCT_SUCCESSFULL, RESET_ADD_SINGLE_PRODUCT } from "../constants/addSingleProduct.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const addSingleProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SINGLE_PRODUCT:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case ADD_SINGLE_PRODUCT_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case ADD_SINGLE_PRODUCT_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_ADD_SINGLE_PRODUCT:
            return {
                ...initialState
            }

        default:
            return state;
    }
}

export default addSingleProductReducer;