import axios from "axios";
import { paths } from '../../Api/paths';
import { MODIFY_GROUP_PRODUCTS, MODIFY_GROUP_PRODUCTS_FAILED, MODIFY_GROUP_PRODUCTS_SUCCESSFULL, RESET_MODIFY_GROUP_PRODUCTS } from "../constants/modifyGroupProducts.constants";

const handleModifyGroupProducts = () => ({
    type: MODIFY_GROUP_PRODUCTS
});

const modifyGroupProductsSuccess = (data) => ({
    type: MODIFY_GROUP_PRODUCTS_SUCCESSFULL,
    payload: data
});

const modifyGroupProductsFailure = (error) => ({
    type: MODIFY_GROUP_PRODUCTS_FAILED,
    payload: error
});

export const resetModifyGroupProducts = () => ({
    type: RESET_MODIFY_GROUP_PRODUCTS
})

export const modifyGroupProducts = (token, data) => async (dispatch) => {
    try {
      dispatch(handleModifyGroupProducts());
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.groupActions}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(modifyGroupProductsSuccess(response.data));
    } catch (error) {
      dispatch(modifyGroupProductsFailure(error));
    }
};