import React, { useState } from 'react'
import { Checkbox, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux';

const PriceVariationChartFilter = ({selectedMarketChips,selectedChartDaysChip}) => {
    
    const themeMode = useSelector(state => state.theme.mode);

    console.log('selectedMarketChips ==>',selectedMarketChips)
    console.log('selectedMarketChips ==>',selectedChartDaysChip)

    const marketplaceProducts = [
        {title:'Amazon',items:[
            {id:'1',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'100%',seller:'MK Retail Shops Private Limited'},
            {id:'2',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'99%',seller:'MK Retail Shops Private Limited'},
            {id:'3',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'98%',seller:'MK Retail Shops Private Limited'},
            {id:'4',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'90%',seller:'MK Retail Shops Private Limited'},
            {id:'5',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'85%',seller:'MK Retail Shops Private Limited'},
            {id:'6',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'87%',seller:'MK Retail Shops Private Limited'},
            {id:'7',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'85%',seller:'MK Retail Shops Private Limited'},
            {id:'8',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'80%',seller:'MK Retail Shops Private Limited'},
            {id:'9',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'76%',seller:'MK Retail Shops Private Limited'},
            {id:'10',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'72%',seller:'MK Retail Shops Private Limited'},
            {id:'11',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'67%',seller:'MK Retail Shops Private Limited'},
            {id:'12',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'65%',seller:'MK Retail Shops Private Limited'},
        ]},
        {title:'Carrefour',items:[
            {id:'1',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'100%',seller:'MK Retail Shops Private Limited'},
            {id:'2',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'99%',seller:'MK Retail Shops Private Limited'},
            {id:'3',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'98%',seller:'MK Retail Shops Private Limited'},
            {id:'4',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'90%',seller:'MK Retail Shops Private Limited'},
            {id:'5',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'85%',seller:'MK Retail Shops Private Limited'},
            {id:'6',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'87%',seller:'MK Retail Shops Private Limited'},
            {id:'7',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'85%',seller:'MK Retail Shops Private Limited'},
            {id:'8',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'80%',seller:'MK Retail Shops Private Limited'},
            {id:'9',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'76%',seller:'MK Retail Shops Private Limited'},
            {id:'10',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'72%',seller:'MK Retail Shops Private Limited'},
            {id:'11',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'67%',seller:'MK Retail Shops Private Limited'},
            {id:'12',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'65%',seller:'MK Retail Shops Private Limited'},
        ]},
        {title:'Noon',items:[
            {id:'1',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'100%',seller:'MK Retail Shops Private Limited'},
            {id:'2',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'99%',seller:'MK Retail Shops Private Limited'},
            {id:'3',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'98%',seller:'MK Retail Shops Private Limited'},
            {id:'4',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'90%',seller:'MK Retail Shops Private Limited'},
            {id:'5',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'85%',seller:'MK Retail Shops Private Limited'},
            {id:'6',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'87%',seller:'MK Retail Shops Private Limited'},
            {id:'7',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'85%',seller:'MK Retail Shops Private Limited'},
            {id:'8',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'80%',seller:'MK Retail Shops Private Limited'},
            {id:'9',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'76%',seller:'MK Retail Shops Private Limited'},
            {id:'10',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'72%',seller:'MK Retail Shops Private Limited'},
            {id:'11',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'67%',seller:'MK Retail Shops Private Limited'},
            {id:'12',title:'Beats Studio 3 Wireless Over - Ear HEadphone Matt Black',matchRate:'65%',seller:'MK Retail Shops Private Limited'},
        ]}
    ]

    const displayedItems = 10;
    const [expandedMarketplaces, setExpandedMarketplaces] = useState([]);

    const handleSeeMoreClick = (index) => {
        setExpandedMarketplaces((prev) => [...prev, index]);
      };
    
      const handleSeeLessClick = (index) => {
        setExpandedMarketplaces((prev) => prev.filter((expandedIndex) => expandedIndex !== index));
      };

      return (
        <Grid item xs={12} container rowSpacing={3} sx={{ padding: '18px' }}>
          {marketplaceProducts.map((marketplace, index) => (
            <Grid key={index} item xs={6} sx={{ paddingLeft: 2 }}>
              <div
                style={{
                  border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1', width: '95%', borderRadius: '11px 11px 0px 0px',
                }}>
                <div style={{ background: themeMode === 'dark' ? '#E4E3E3' : '#f8f8f8', borderRadius: '10px 10px 0px 0px', padding: '10px',outline: 'none !important',
                color:themeMode === 'dark' ? '#5A5A5A' : '#757574',borderBottom: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1' }}>
                  <Typography sx={{textTransform:'uppercase',}} >{marketplace.title}</Typography>
                </div>
                {marketplace.items.slice(0, expandedMarketplaces.includes(index) ? marketplace.items.length : displayedItems).map((item, i) => (
                  <Grid key={i} item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                    <Grid item xs={1}>
                      <Checkbox sx={{color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'}} id={`checkbox_${index}_${i}`} size='small'
                      />
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography sx={{ fontSize: '14px',color:themeMode === 'dark' ? 'white' : '#000000',fontWeight:"500",lineHeight: '16.94px' }}>{item.title}</Typography>
                      <Typography sx={{ fontSize: '12px', lineHeight: '14.52px',color:themeMode === 'dark' ? '#ada9a9' : '#5A5A5A', }}>{item.seller}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: '13px', textAlign: 'end',lineHeight:"16.94px",weight:"500" }}>{item.matchRate}</Typography>
                    </Grid>
                  </Grid>
                ))}
                {marketplace.items.length > displayedItems && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {expandedMarketplaces.includes(index) ? (
                      <Typography onClick={() => handleSeeLessClick(index)} sx={{ padding: '8px', fontSize: '14px',textDecoration:'underline',color:'#4a89dd',cursor:'pointer' }}>
                        See Less
                      </Typography>
                    ) : (
                      <Typography onClick={() => handleSeeMoreClick(index)} sx={{ padding: '8px', fontSize: '14px',textDecoration:'underline',color:'#4a89dd',cursor:'pointer' }}>
                        See More
                      </Typography>
                    )}
                    <Typography sx={{ padding: '8px', fontSize: '14px' }}>
                      Showing {expandedMarketplaces.includes(index) ? marketplace.items.length : displayedItems} results
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      );
    };
    
    export default PriceVariationChartFilter;