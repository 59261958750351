import axios from "axios";
import { paths } from '../../Api/paths';
import { RESET_UPDATE_SCORE, UPDATE_SCORE, UPDATE_SCORE_FAILED, UPDATE_SCORE_SUCCESSFULL } from "../constants/updateScore.constants";

const handleUpdateScore = () => ({
    type: UPDATE_SCORE
});

const updateScoreSuccess = (data) => ({
    type: UPDATE_SCORE_SUCCESSFULL,
    payload: data
});

const updateScoreFailure = (error) => ({
    type: UPDATE_SCORE_FAILED,
    payload: error
});

export const resetUpdateScore = () => ({
    type: RESET_UPDATE_SCORE
})

export const updateScore = (token, queryString) => async (dispatch) => {
    try {
      dispatch(handleUpdateScore());
      const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.updateScore}${queryString}`,
      {}, { headers: { Authorization: `bearer ${token}`}})
      dispatch(updateScoreSuccess(response.data));
    } catch (error) {
      dispatch(updateScoreFailure(error));
    }
};