import { Card, Grid } from '@mui/material';
import React from 'react';
import './locationDetails.css'
import { useSelector } from 'react-redux';

const LocationDetails = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const data = useSelector(state => state.dashboard.data?.summary);

  return (
    <Card sx={{
      boxShadow: 'none', height: '180px', background: themeMode === 'dark' ? '#232D3B' : 'white',
      color: themeMode === 'dark' ? 'white' : 'black'
    }}>
      <Grid container direction='column' justifyContent='space-between' style={{ height: '100%' }}>
        <Grid item style={{ height: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ fontSize: '14px', textAlign: 'center' }}>Locations</p>
        </Grid>
        <Grid item container style={{ height: '70%' }}>
          <Grid item xs={3} className='containerLocation'>
            <div className='fontlocation'>
              <p>Total Number of</p>
              <p>Products</p>
              <p>{data?.productCount || 0}</p>
            </div>
          </Grid>
          <Grid item xs={3} className='containerLocation'>
            <div className='fontlocation'>
              <p>Total Number of</p>
              <p>Categories</p>
              <p>{data?.categoryCount || 0}</p>
            </div>
          </Grid>
          <Grid item xs={3} className='containerLocation' >
            <div className='fontlocation'>
              <p>Total Number of</p>
              <p>Departments</p>
              <p>{data?.departmentCount || 0}</p>
            </div>
          </Grid>
          <Grid item xs={3} className='containerLocation'>
            <div className='fontlocation'>
              <p>Total Brands</p>
              <p>{data?.brandCount || 0}</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default LocationDetails;
